import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AlertDialog from "components/Custom/Dialog";
import Dropzone from "components/Custom/Dropzone";
import DynamicAutocomplete from "components/Custom/Autocomplete";
import MDSnackbar from "components/MDSnackbar";
import Header from "components/Custom/Header";
import FormFieldTitle from "components/Custom/FormFieldTitle";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// MUI components
import {
  Card,
  Grid,
  TextField,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from "@mui/material";

// Redux-store
import { uploadVideos } from "slices/Videos/services";
import { fetchVideos } from "slices/Videos/services";

// Data
import VideosDataTable from "./data/VideosDataTable";
import { uploadGuidanceVideos } from "slices/Videos/services";

// Loading page
import LoadingPage from "../loading";

const Videos = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const videos = useSelector((state) => state.videos.items);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");

  const [mainTopicOptions, setMainTopicOptions] = useState([]);
  const [subTopicOptions, setSubTopicOptions] = useState([]);

  const [selectedMainTopic, setSelectedMainTopic] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [guidanceMainTopic, setGuidanceMainTopic] = useState("");

  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  const disabled =
    (tabIndex === 0 &&
      (!files ||
        files?.length === 0 ||
        !selectedMainTopic ||
        !selectedSubTopic)) ||
    (tabIndex === 1 &&
      (!files ||
        files?.length === 0 ||
        !guidanceMainTopic ||
        !description ||
        description.length === 0));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setSelectedSubTopic("");
    setSelectedMainTopic("");
    setGuidanceMainTopic("");
    setDescription("");
  };

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
  };

  const handleSubmit = useCallback(async () => {
    handleClose();

    const formData = new FormData();

    files.forEach((file) => {
      formData.append("videos", file);
    });

    const singleItems = [
      { key: "mainTopic", value: selectedMainTopic },
      { key: "subTopic", value: selectedSubTopic },
      { key: "description", value: description },
      { key: "videoType", value: "lesson" },
      { key: "isAccessible", value: false },
    ];

    singleItems.forEach((item) => {
      formData.append(item.key, item.value);
    });

    try {
      const res = await dispatch(uploadVideos(formData));

      if (res.type === "videos/upload/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Video yüklenirken hata oluştu
          </MDTypography>
        );
      } else if (res.type === "videos/upload/fulfilled") {
        openSB(
          "info",
          "notifications",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Video yükleniyor...
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  }, [dispatch, files, selectedMainTopic, selectedSubTopic, description]);

  const handleGuidanceSubmit = useCallback(async () => {
    handleClose();
    openSB(
      "info",
      "notifications",
      "Bildirim",
      <MDTypography variant="button" color="white">
        Rehberlik videosu yükleniyor...
      </MDTypography>
    );

    const formData = new FormData();

    files.forEach((file) => {
      formData.append("guidanceVideos", file);
    });

    const singleItems = [
      { key: "guidanceMainTopic", value: guidanceMainTopic },
      { key: "description", value: description },
      { key: "videoType", value: "guidance" },
      { key: "isAccessible", value: false },
    ];

    singleItems.forEach((item) => {
      formData.append(item.key, item.value);
    });

    try {
      const res = await dispatch(uploadGuidanceVideos(formData));

      if (res.type === "videos/uploadGuidance/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Rehberlik videosu yüklenirken hata oluştu
          </MDTypography>
        );
      } else if (res.type === "videos/uploadGuidance/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Rehberlik videosu başarıyla yüklendi
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  }, [dispatch, files, guidanceMainTopic, description]);

  const handleFilesAdded = (files) => {
    setFiles(files);
  };

  const handleFilesDelete = (index) => {
    setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    // Filter the videos to get only the lesson type
    const lessonVideos = videos.filter((video) => video.videoType === "lesson");

    // Extract mainTopics and subTopics from the filtered lesson videos
    const mainTopics = lessonVideos.map((video) => video?.mainTopic);
    const subTopics = lessonVideos.map((video) => video?.subTopic);

    // Get unique values from the extracted mainTopics and subTopics
    const uniqueMainTopics = Array.from(new Set(mainTopics));
    const uniqueSubTopics = Array.from(new Set(subTopics));

    // Set options for main and sub topics
    setMainTopicOptions(
      uniqueMainTopics.map((topic) => ({ value: topic, label: topic }))
    );
    setSubTopicOptions(
      uniqueSubTopics.map((topic) => ({ value: topic, label: topic }))
    );
  }, [videos]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchVideos());
    }, 5000); // Fetch videos every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchVideos())
      .then((response) => {
        if (response.type === "videos/fetchVideos/fulfilled") {
          setTimeout(() => setLoading(false), 500); 
        } else if (response.type === "videos/fetchVideos/rejected") {
          console.error("Error fetching videos:", response.error.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Unexpected error:", error);
        setLoading(false);
      });
  }, [dispatch]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Videolar" buttonText="Video Yükle" action={handleOpen} />
      <Card
        display="flex"
        width="100%"
        justifyContent="center"
        sx={{ marginBottom: 2 }}
      >
        <Tabs
          value={tabIndex}
          onChange={(event, newValue) => setTabIndex(newValue)}
        >
          <Tab
            label={
              <MDTypography
                variant={isXsScreen ? "button" : "body2"}
                fontWeight="bold"
              >
                Ders Videoları
              </MDTypography>
            }
          />
          <Tab
            label={
              <MDTypography
                variant={isXsScreen ? "button" : "body2"}
                fontWeight="bold"
              >
                Rehberlik Videoları
              </MDTypography>
            }
          />
        </Tabs>
      </Card>
      <MDBox display="flex" width="100%" justifyContent="center">
        <Grid container>
          <Grid item xs={12}>
            {tabIndex === 0 ? (
              <VideosDataTable
                openSB={openSB}
                closeSB={closeSB}
                videoType="lesson"
              />
            ) : (
              <VideosDataTable
                openSB={openSB}
                closeSB={closeSB}
                videoType="guidance"
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
      <AlertDialog
        width="lg"
        open={open}
        onClose={handleClose}
        onSubmit={tabIndex === 0 ? handleSubmit : handleGuidanceSubmit}
        title={tabIndex === 0 ? "Video Yükle" : "Rehberlik Videosu Yükle"}
        disabled={disabled}
        fullScreen={isXsScreen}
      >
        <Tabs
          value={tabIndex}
          onChange={(event, newValue) => setTabIndex(newValue)}
        >
          <Tab label="Ders Videosu" />
          <Tab label="Rehberlik Videosu" />
        </Tabs>
        {tabIndex === 0 && (
          <>
            <MDBox mt={1} mb={3}>
              <FormFieldTitle>Ana Konu</FormFieldTitle>
              <DynamicAutocomplete
                placeholder="Seçiniz..."
                options={mainTopicOptions}
                value={selectedMainTopic}
                onChange={(newValue) => {
                  setSelectedMainTopic(newValue?.value || newValue);
                }}
              />
            </MDBox>
            <MDBox mb={3}>
              <FormFieldTitle>Alt Konu</FormFieldTitle>
              <DynamicAutocomplete
                placeholder="Seçiniz..."
                options={subTopicOptions}
                value={selectedSubTopic}
                onChange={(newValue) => {
                  setSelectedSubTopic(newValue?.value || newValue);
                }}
              />
            </MDBox>
            <MDBox mb={3}>
              <FormFieldTitle>Açıklama</FormFieldTitle>
              <TextField
                fullWidth
                rows={4}
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </MDBox>
            <MDBox>
              <FormFieldTitle>Video</FormFieldTitle>
              <Dropzone
                onFilesAdded={handleFilesAdded}
                onDelete={handleFilesDelete}
                multiple={false}
                accept={{
                  "video/mp4": [".mp4", ".MP4"],
                }}
              />
            </MDBox>
          </>
        )}
        {tabIndex === 1 && (
          <>
            <MDBox mt={1} mb={3}>
              <FormFieldTitle>Ana Konu</FormFieldTitle>
              <TextField
                fullWidth
                value={guidanceMainTopic}
                onChange={(e) => setGuidanceMainTopic(e.target.value)}
              />
            </MDBox>
            <MDBox mb={3}>
              <FormFieldTitle>Açıklama</FormFieldTitle>
              <TextField
                fullWidth
                rows={4}
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </MDBox>
            <MDBox>
              <FormFieldTitle>Rehberlik Videosu</FormFieldTitle>
              <Dropzone
                onFilesAdded={handleFilesAdded}
                onDelete={handleFilesDelete}
                multiple={false}
                accept={{
                  "video/mp4": [".mp4", ".MP4"],
                }}
              />
            </MDBox>
          </>
        )}
      </AlertDialog>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
      />
    </DashboardLayout>
  );
};

export default Videos;
