import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const uploadSchedule = createAsyncThunk(
  "schedule/uploadSchedule",
  async (fileData, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", fileData);
      const response = await axios.post("/schedule/upload-schedule", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Verileri backend'den alma için async thunk
export const fetchSchedules = createAsyncThunk(
  "schedule/fetchSchedules",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/schedule");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
