import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const saveItemOrder = createAsyncThunk(
  "common/saveItemOrder",
  async (order, { rejectWithValue }) => {
    try {
      const response = await axios.post("/common/update-order", { order });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
