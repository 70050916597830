// React essentials
import { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Redux actions
import { fetchCompletedItems } from "slices/Statistics/services";
import { resetCompletedItems } from "slices/Statistics";

// Helper functions
import { formatDuration } from "helpers/index";
import { findUserId } from "helpers";

function QuizzesSituation({ quizGroups, title, icon }) {
  const dispatch = useDispatch();
  const { userId: paramUserId } = useParams();
  const location = useLocation();

  const completedTests = useSelector(
    (state) => state.statistics.completedTests || []
  );

  useEffect(() => {
    dispatch(resetCompletedItems());
    let userId;
    if (location.pathname.startsWith("/user-management")) {
      userId = paramUserId;
    } else {
      userId = findUserId();
    }

    dispatch(fetchCompletedItems(userId));
  }, [dispatch, location.pathname, paramUserId]);

  const organizedItems = useMemo(() => {
    return quizGroups?.map((quizGroup) => {
      const completedTest = completedTests.find(
        (test) => test.testId === quizGroup._id
      );

      const totalQuestions = quizGroup?.questions.length || 0;
      const expectedDuration = totalQuestions * 2; // minutes
      const elapsedTime = completedTest ? completedTest.elapsedTime / 60 : 0; // seconds to minutes

      const correctAnswersCount = completedTest
        ? completedTest.answers.filter(
            (answer, index) =>
              answer === quizGroup.questions[index].correctAnswer
          ).length
        : 0;

      const wrongAnswersCount = completedTest
        ? completedTest.answers.filter(
            (answer, index) =>
              answer !== null &&
              answer !== quizGroup.questions[index].correctAnswer
          ).length
        : 0;

      const emptyAnswersCount = completedTest
        ? completedTest.answers.filter((answer) => answer === null).length
        : 0;

      const completionPercentage = completedTest ? 100 : 0;

      return {
        quizName: quizGroup.mainTopic,
        totalQuestions,
        expectedDuration: formatDuration(expectedDuration * 60),
        elapsedTime: completedTest
          ? formatDuration(elapsedTime * 60)
          : "Süre bilgisi bulunamadı",
        answers: completedTest
          ? completedTest.answers.join(", ")
          : "Cevaplanmadı",
        correctAnswersCount,
        wrongAnswersCount,
        emptyAnswersCount,
        completion: (
          <MDTypography color="text" variant="button" fontWeight="regular">
            {completionPercentage === 100 ? "Tamamlandı" : "Başlanmadı"}
          </MDTypography>
        ),
      };
    });
  }, [quizGroups, completedTests]);

  const columns = [
    { Header: "Test Adı", accessor: "quizName", align: "left" },
    { Header: "Toplam Soru", accessor: "totalQuestions", align: "left" },
    { Header: "Beklenen Süre", accessor: "expectedDuration", align: "left" },
    { Header: "Harcanan Süre", accessor: "elapsedTime", align: "left" },
    { Header: "Verilen Cevaplar", accessor: "answers", align: "left" },
    { Header: "Doğru Sayısı", accessor: "correctAnswersCount", align: "left" },
    { Header: "Yanlış Sayısı", accessor: "wrongAnswersCount", align: "left" },
    { Header: "Boş Sayısı", accessor: "emptyAnswersCount", align: "left" },
    { Header: "Tamamlanma Oranı", accessor: "completion", align: "center" },
  ];

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox display="flex" alignItems="center">
          {icon}
          <MDTypography variant="h6" gutterBottom ml={1}>
            {title}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mb={3}>
        <DataTable
          table={{ columns, rows: organizedItems }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default QuizzesSituation;
