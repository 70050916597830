import React from "react";
import Lottie from "lottie-react";

import animationData from "assets/animations/math-operations.json"; // Animasyon dosyanızın yolu

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useMediaQuery, useTheme } from "@mui/material";

export default function LoadingPage() {
  const muiTheme = useTheme();
  const isXsScreen = useMediaQuery(muiTheme.breakpoints.down("lg"));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={isXsScreen ? "70vh" : "80vh"} // Sayfanın büyük bir kısmını kaplaması için
      >
        <Lottie
          animationData={animationData}
          style={{
            width: 100,
            height: 100,
          }}
        />
        {/* <MDTypography
          variant="overline"
          color="text"
          sx={{ fontSize: isXsScreen ? "12px" : "14px" }}
          mt={2}
        >
          Yükleniyor
        </MDTypography> */}
      </MDBox>
    </DashboardLayout>
  );
}
