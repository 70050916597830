// Material Dashboard 2 React Base Styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

const { transparent } = colors;
const { borderRadius } = borders;

const textField = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
    },
  },
};

export default textField;
