// React
import React from "react";

// Material UI components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

// Material UI icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HomePageLayout from "examples/LayoutContainers/HomepageLayout";

// Assets
import image1 from "assets/images/illustrations/help-1.png";

// Prop Types
import PropTypes from "prop-types";

// Functions and utilities
import linearGradient from "assets/theme/functions/linearGradient";
import { useNavigate } from "react-router-dom";
import { accordionData } from "./content";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const steps = [
  "Bilgi Edinin ve İletişime Geçin",
  "Hesap Oluşturun",
  "Ödeme Yapın",
  "Ders Başlangıcına Kadar Bekleyin",
  "Eğitim Sürecine Başlayın",
];

const AccordionItem = ({ title, text, isXsScreen, index, total }) => {
  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        maxWidth: "1920px",
        backgroundImage: linearGradient("#57A6A1", "#028391"),
        paddingY: 1.5,
        boxShadow: "0px 0px 3px 0px white",
        marginX: 2,
        width: "100%", // Genişliği %100 olarak ayarlayalım
        borderTopLeftRadius: index === 0 ? "1rem" : 0,
        borderTopRightRadius: index === 0 ? "1rem" : 0,
        borderBottomLeftRadius: index === total - 1 ? "1rem" : 0,
        borderBottomRightRadius: index === total - 1 ? "1rem" : 0,
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #fff", // Çizgi rengi buradan değiştirilebilir
        },
      }}
    >
      <Accordion
        sx={{
          backgroundImage: linearGradient("#57A6A1", "#028391"),
          paddingY: 1,
          width: "100%", // Genişliği %100 olarak ayarlayalım
          boxShadow: "0px 0px 3px 0px gray",
          paddingX: isXsScreen ? 1.5 : 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          border: "none",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="white" />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
          sx={{
            width: "100%", // Genişliği %100 olarak ayarlayalım
          }}
        >
          <MDTypography variant={isXsScreen ? "body2" : "h6"} color="white">
            {title}
          </MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <MDTypography variant={isXsScreen ? "button" : "body2"} color="light">
            {text}
          </MDTypography>
        </AccordionDetails>
      </Accordion>
    </MDBox>
  );
};

export default function Help() {
  const navigate = useNavigate();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const fontSize = isXsScreen ? "medium" : "large";
    const icons = {
      1: <LooksOneIcon fontSize={fontSize} />,
      2: <LooksTwoIcon fontSize={fontSize} />,
      3: <Looks3Icon fontSize={fontSize} />,
      4: <Looks4Icon fontSize={fontSize} />,
      5: <Looks5Icon fontSize={fontSize} />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: isXsScreen ? 50 : 100,
    height: isXsScreen ? 50 : 100,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage: linearGradient("#FF5F00", "#ff8a00"),
    }),
  }));

  return (
    <HomePageLayout>
      <MDBox
        mb={isXsScreen ? -3 : 5}
        mt={isXsScreen ? 10 : 25}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
        mx={3}
      >
        <Grid
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          height="100%"
          pb={3}
          alignItems="center"
          maxWidth={"1920px"}
        >
          <Grid item xs={12} lg={5} display="flex" justifyContent="center">
            <MDBox
              sx={{
                overflow: "hidden",
                height: "100%",
              }}
            >
              <img
                src={image1}
                alt="About Us"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  maxWidth: "30rem",
                }}
              />
            </MDBox>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5.5}
            sx={{ margin: 3 }}
            display="flex"
            flexDirection="column"
            height="100%"
            textAlign={isXsScreen ? "center" : "start"}
          >
            <MDTypography
              color="dark"
              variant={isXsScreen ? "h3" : "h2"}
              mb={2}
            >
              Sıkça Sorulan Sorular
            </MDTypography>
            <MDTypography
              sx={{ color: "#6c757d" }}
              variant={isXsScreen ? "body2" : "h6"}
              mb={3}
            >
              Burada sizler tarafından en çok değer verilen yanıtların yanı sıra
              adım adım talimatlara ve desteğe erişebilirsiniz.
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>

      {accordionData.map((item, index) => (
        <MDBox width="100%" display="flex" justifyContent="center">
          <AccordionItem
            key={index}
            index={index}
            title={item.title}
            text={item.text}
            isXsScreen={isXsScreen}
            total={accordionData?.length}
          />
        </MDBox>
      ))}
      <MDBox
        mt={isXsScreen ? 3 : 10}
        mb={isXsScreen ? -3 : 10}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={3}
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          height="100%"
          pb={3}
          alignItems="center"
          maxWidth={"1920px"}
        >
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            height="100%"
            textAlign="center"
            sx={{ margin: 3 }}
          >
            <MDTypography color="dark" variant="h2" mb={1}>
              5 Adımda Dijital Derslik
            </MDTypography>
            <MDTypography sx={{ color: "#6c757d" }} variant="h6" mb={3}>
              Aşağıda, sistemimizi kullanırken izlemeniz gereken temel adımları
              bulabilirsiniz.
            </MDTypography>
            <Grid container >
              {steps.map((label, index) => (
                <Grid item xs={4} md={4} lg={2.4} key={index}>
                  <Stepper
                    alternativeLabel
                    activeStep={steps.length}
                    connector={<ColorlibConnector />}
                    sx={{
                      boxShadow: "none",
                    }}
                  >
                    <Step>
                      <StepLabel
                        StepIconComponent={ColorlibStepIcon}
                        icon={index + 1}
                      >
                        <MDTypography
                          variant={isXsScreen ? "caption" : "h6"}
                          mt={3}
                        >
                          {label}
                        </MDTypography>
                      </StepLabel>
                    </Step>
                  </Stepper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox
        mb={5}
        mt={3}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={2}
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          height="100%"
          pb={3}
          alignItems="center"
          maxWidth={"1920px"}
          sx={{
            backgroundImage: linearGradient("#57A6A1", "#028391"),
            borderRadius: "1rem",
            boxShadow: "0px 0px 3px 0px gray",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ margin: isXsScreen ? 3 : 5, marginX: isXsScreen ? 3 : 8 }}
            display="flex"
            flexDirection="column"
            textAlign={isXsScreen ? "center" : "start"}
            height="100%"
          >
            <MDTypography
              color="white"
              variant={isXsScreen ? "h3" : "h2"}
              mb={1}
            >
              Blog Sayfamızı Keşfedin
            </MDTypography>
            <MDTypography color="light" variant={isXsScreen ? "body2" : "h6"}>
              En güncel içeriklerimizi, eğitim makalelerimizi ve uzman
              görüşlerimizi takip etmek için{" "}
              <span
                style={{
                  color: "white",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/blog");
                }}
              >
                blog
              </span>{" "}
              sayfasına göz atın.
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </HomePageLayout>
  );
}
