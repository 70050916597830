import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Header from "components/Custom/Header";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MasterCard from "examples/Cards/MasterCard";

// Billing page components
import PaymentMethod from "layouts/pages/payment/components/PaymentMethod";

// MUI
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import PackageCard from "components/Custom/PackageCard";
import PaymentInfoCard from "components/Custom/PaymentInfoCard";
import OverlayCard from "components/Custom/OverlayCard";

// Loading page
import LoadingPage from "../loading";

// assets
import image1 from "assets/images/illustrations/checkout.png"

export default function Payment() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    cardHolder: "",
    expiry: "",
    cardType: null,
    cvv: "",
  });
  const [selectedPackage, setSelectedPackage] = useState("full");
  const [loading, setLoading] = useState(true);

  const handleCardDetailsChange = (details) => {
    setCardDetails(details);
  };

  const handlePackageChange = (packageType) => {
    setSelectedPackage(packageType);
  };

  const packagePrices = {
    full: 19.999,
    video: 5.999,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // 500 ms bekleme süresi

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Ödeme Ekranı" />
      <MDBox mb={3} width="100%" display="flex" justifyContent="center">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{ maxWidth: !isSmallScreen && "1280px", margin: "auto" }}
          >
            <Grid container spacing={3} alignItems="stretch">
              {/* <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  xl={5}
                  style={{ width: "600px", flexShrink: 0 }}
                >
                  <MasterCard
                    number={cardDetails.cardNumber.replace(/\s/g, "")}
                    holder={cardDetails.cardHolder}
                    expires={cardDetails.expiry}
                    cardType={cardDetails.cardType || "mastercard"}
                  />
                </Grid> */}
              <Grid item xs={12} md={4}>
                <PackageCard
                  title="Canlı Dersler + Video Konu Anlatımları + Testler"
                  description="1 Yıllık Ücret"
                  value={packagePrices.full + "\u20BA"}
                  isSelected={selectedPackage === "full"}
                  onSelect={() => handlePackageChange("full")}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <PackageCard
                  title="Video Konu Anlatımları + Testler"
                  description="1 Yıllık Ücret"
                  value={packagePrices.video + "\u20BA"}
                  isSelected={selectedPackage === "video"}
                  onSelect={() => handlePackageChange("video")}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <OverlayCard image={image1} />
              </Grid>
              {/* <Grid item xs={12}>
                  <PaymentMethod
                    onCardDetailsChange={handleCardDetailsChange}
                    packageType={selectedPackage}
                    packagePrices={packagePrices}
                  />
                </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PaymentInfoCard isSmallScreen={isSmallScreen} />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
