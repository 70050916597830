import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import fileDownload from "js-file-download";

export const uploadVideos = createAsyncThunk(
  "videos/upload",
  async (formData, { rejectWithValue }) => {
    try {
      await axios.post("/videos", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const response = await axios.get("/videos");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadGuidanceVideos = createAsyncThunk(
  "videos/uploadGuidance",
  async (formData, { rejectWithValue }) => {
    try {
      await axios.post("/videos/guidance", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const response = await axios.get("/videos");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchVideos = createAsyncThunk(
  "videos/fetchVideos",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/videos");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteVideo = createAsyncThunk(
  "videos/deleteVideo",
  async ({ videoId, vimeoId }, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`/videos/video/delete`, {
        data: { videoId, vimeoId },
      });

      if (res.status !== 200) {
        throw new Error("Silme işlemi başarısız oldu.");
      }
      const response = await axios.get("/videos");
      return response.data;
    } catch (error) {
      console.error("Silme işlemi sırasında hata oluştu:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateAccessibility = createAsyncThunk(
  "videos/updateAccessibility",
  async ({ id, isAccessible }, { rejectWithValue }) => {
    try {
      const res = await axios.patch(
        `/videos/video/update-accessibility/${id}`,
        {
          isAccessible,
        }
      );

      if (res.status !== 200) {
        throw new Error("Erişilebilirlik durumu güncelleme başarısız oldu.");
      }
      const response = await axios.get("/videos");
      return response.data;
    } catch (error) {
      console.error(
        "Erişilebilirlik güncelleme işlemi sırasında hata oluştu:",
        error
      );
      return rejectWithValue(error.message);
    }
  }
);

export const fetchVideoStream = createAsyncThunk(
  "video/fetchVideoStream",
  async (videoId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/videos/video/stream/${videoId}`);
      return response;
    } catch (error) {
      console.error("Fetching video stream URLs failed:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const saveVideoOrder = createAsyncThunk(
  "videos/saveOrder",
  async (videoIds, { rejectWithValue }) => {
    try {
      // Ensure videoIds is correctly mapped from a list, if necessary
      const response = await axios.patch("/videos/video/order", {
        order: videoIds, // Assuming videoIds is already the array of IDs
      });

      if (response.status === 200) {
        return response;
      } else {
        throw new Error("Failed to update order");
      }
    } catch (error) {
      console.error("Failed to update order:", error);
      return rejectWithValue(error.message); // Use rejectWithValue to pass errors to the Redux state
    }
  }
);
