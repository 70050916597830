import { createSlice } from "@reduxjs/toolkit";
import { saveItemOrder } from "./services";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    saveItemOrder: {
      status: "idle",
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveItemOrder.pending, (state) => {
        state.saveItemOrder.status = "loading";
      })
      .addCase(saveItemOrder.fulfilled, (state, action) => {
        state.saveItemOrder.status = "succeeded";
        state.items = action.payload; // Update items with the new order
      })
      .addCase(saveItemOrder.rejected, (state, action) => {
        state.saveItemOrder.status = "failed";
        state.saveItemOrder.error = action.payload;
      });
  },
});

export default commonSlice.reducer;
