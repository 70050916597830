import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { useMediaQuery, useTheme, Card, AppBar, Toolbar } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import WhatsAppMessagePopup from "components/Custom/WhatsappPopup";
import MDTypography from "components/MDTypography";
import PhonePopup from "components/Custom/PhonePopup";

// Material Dashboard 2 React example components
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Container } from "react-bootstrap";
import Header from "components/Header";

function BasicLayout({ image, children, flip }) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <PageLayout>
      <Grid container spacing={0} style={{ height: "100vh" }}>
        {!isXsScreen && (
          <Grid item xs={12} md={6} xl={8}>
            <MDBox
              width="105%"
              sx={{
                backgroundImage: ({
                  functions: { linearGradient, rgba },
                  palette: { gradients },
                }) =>
                  image &&
                  `${linearGradient(
                    rgba("#212121", 0.2),
                    rgba("#212121", 0.1)
                  )}, url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "100vh",
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} xl={4} style={{ height: "100%" }}>
          <AppBar sx={{ width: "100%", zIndex: 9999 }}>
            <Container
              fluid
              style={{
                backgroundColor: true ? "#212529" : "rgba(0,0,0,.4)",
                transition:
                  "background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                height: "3.5rem",
              }}
            >
              <Toolbar disableGutters>
                <MDBox>
                  <Header shouldHighlighted={true} />
                </MDBox>
              </Toolbar>
            </Container>
          </AppBar>
          <Card
            sx={{
              marginTop: "3.5rem",
              height: "calc(100vh - 3.5rem)",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 5px 15px rgba(0,0,0,0.5)", // Gölgelendirme ekleyerek derinlik kazandır
              borderRadius: 0,
              borderTopLeftRadius: !isXsScreen && "24px",
              borderBottomLeftRadius: !isXsScreen && "24px",
              overflow: "auto", // Change to "hidden"
            }}
          >
            <MDBox
              sx={{
                textAlign: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <MDBox mx="auto" width="100%" px={3}>
                {children}
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      {!isXsScreen &&
        !isMdScreen &&(
          <>
            {/* <PhonePopup /> */}
            <WhatsAppMessagePopup />
          </>
        )}
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
