import React from "react";
import { Link, useLocation } from "react-router-dom";
import MDTypography from "components/MDTypography";

export default function LinkItem({
  text,
  target,
  shouldHighlighted,
  isActive,
}) {
  const location = useLocation();
  const isAuthPage = location.pathname.startsWith("/auth");

  return (
    <Link
      to={target}
      onMouseEnter={(e) => {
        e.target.style.color =
          shouldHighlighted && isAuthPage ? "#fff" : "#18181B";
      }}
      onMouseLeave={(e) => {
        e.target.style.color =
          isActive && !isAuthPage
            ? "#18181B"
            : !isActive && isAuthPage
            ? "#e9ecef"
            : "#495057";
      }}
    >
      <MDTypography
        sx={{
          color:
            isActive && !isAuthPage
              ? "#18181B"
              : !isActive && isAuthPage
              ? "#e9ecef"
              : "#495057",
          letterSpacing: "0.03em",
        }}
        variant="h6"
      >
        {text}
      </MDTypography>
    </Link>
  );
}
