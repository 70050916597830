import React, { useEffect } from "react";
import Vara from "vara"; // Vara.js'i import et
import MDBox from "components/MDBox";
import Cookies from "js-cookie";

const VaraText = () => {
  useEffect(() => {
    // Daha önce animasyon oynatılmış mı kontrol edelim
    const hasPlayed = Cookies.get("varaAnimationPlayed");

    if (!hasPlayed) {
      // Animasyon ilk kez oynatılacak
      const vara = new Vara(
        "#myVaraText", // id olarak tanımlandı
        "https://raw.githubusercontent.com/akzhy/Vara/master/fonts/Satisfy/SatisfySL.json",
        [
          {
            text: "Dijital Derslik'e hos geldiniz!",
            from: { x: 0, y: 50 },
            duration: 4000,
            delay: 500,
            color: "#2c3c58", // Yazının rengi
            strokeWidth: 2,
          },
        ]
      );

      // Animasyon tamamlandığında bu olay tetiklenecek
      vara.ready(() => {
        // Animasyon tamamlandığında cookie'de bilgiyi güncelle
        Cookies.set("varaAnimationPlayed", "true");
      });
    } else {
      const vara = new Vara(
        "#myVaraText", // id olarak tanımlandı
        "https://raw.githubusercontent.com/akzhy/Vara/master/fonts/Satisfy/SatisfySL.json",
        [
          {
            text: "Dijital Derslik'e hos geldiniz!",
            from: { x: 0, y: 50 },
            duration: 0, // Animasyonu devre dışı bırakıyoruz
            delay: 0, // Gecikme yok
            color: "#2c3c58", // Yazının rengi
            strokeWidth: 2,
          },
        ]
      );
    }
  }, []);

  return (
    <MDBox display="flex" justifyContent="flex-start" ml={5} mt={5}>
      {/* Vara.js animasyonunun gerçekleşeceği div */}
      <div
        id="myVaraText"
        style={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}
      ></div>
    </MDBox>
  );
};

export default VaraText;
