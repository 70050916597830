import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const videoComplete = createAsyncThunk(
  "statistics/videoComplete",
  async (videoData, { rejectWithValue }) => {
    try {
      await axios.post(
        `/statistics/video-complete`,
        videoData
      );
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const testComplete = createAsyncThunk(
  "statistics/testComplete",
  async (testData, { rejectWithValue }) => {
    try {
      await axios.post(`/statistics/test-complete`, testData);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Completed Videos and Tests
export const fetchCompletedItems = createAsyncThunk(
  "statistics/fetchCompletedItems",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/statistics/completed-items/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);