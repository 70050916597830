import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // Material UI WhatsApp icon
import { IconButton, Button } from "@mui/material";

import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

import image from "assets/images/other/person.png";
import wpBg from "assets/images/other/wp-wallpaper.jpg";

import MDTypography from "components/MDTypography";

function WhatsAppMessagePopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
    if (!isOpen) setShowContent(true);
  };

  const animation = useSpring({
    transform: isOpen ? `translateY(0)` : `translateY(100%)`,
    opacity: isOpen ? 1 : 0,
    onRest: () => {
      // Animasyon tamamlandığında ve eğer isOpen false ise içeriği kaldır
      if (!isOpen) setShowContent(false);
    },
    config: {
      mass: 1,
      tension: 210,
      friction: 20,
    },
  });

  return (
    <MDBox
      sx={{
        position: "fixed",
        bottom: 24,
        right: 24,
        borderRadius: "100%",
        zIndex: 999,
      }}
    >
      <MDBox
        bgColor="light"
        sx={{
          opacity: 0.8,
          position: "fixed",
          bottom: 24,
          right: 24,
          borderRadius: "100%",
          boxShadow: "0px 0px 8px 0px black",
        }}
      >
        <IconButton
          onClick={togglePopup}
          color="success"
          size="large"
          sx={{ fontSize: "2.5rem" }}
        >
          <WhatsAppIcon style={{ fontSize: "3rem" }} />
        </IconButton>
      </MDBox>

      <animated.div style={animation}>
        {showContent && (
          <MDBox
            sx={{
              position: "absolute",
              bottom: 80,
              right: 0,
              width: 325,
              height: 375,
              backgroundColor: "#fff",
              borderRadius: "1rem",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <MDBox
              sx={{
                backgroundColor: "#095E54",
                borderRadius: "1rem 1rem 0 0",
                height: "100px",
              }}
            >
              <MDBox p={3} display="flex">
                <MDAvatar variant="circular" bgColor="grey[100]" size="lg">
                  <img
                    src={image}
                    width="60"
                    height="60"
                    style={{ marginTop: "1rem" }}
                  />
                </MDAvatar>
                <MDBox display="flex" flexDirection="column">
                  <MDTypography
                    px={1}
                    pt={0.5}
                    mx={0.5}
                    color="white"
                    variant="body2"
                    fontWeight="bold"
                    fontStyle="italic"
                    sx={{ marginBottom: 0.5, letterSpacing: ".1em" }}
                  >
                    0554 345 35 44
                  </MDTypography>
                  <MDTypography
                    mx={1.5}
                    color="white"
                    variant="caption"
                    fontWeight="bold"
                    sx={{ marginBottom: 1, letterSpacing: ".05em" }}
                  >
                    Müşteri Hizmetleri
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>

            <MDBox
              p={3}
              sx={{
                height: "200px",
                backgroundSize: "cover", //
                backgroundPosition: "center", // resmi kutunun ortasına yerleştirir
                backgroundRepeat: "no-repeat", // resmin tekrarlanmasını önler
                backgroundImage: ({
                  functions: { linearGradient, rgba },
                  palette: { gradients },
                }) =>
                  `${linearGradient(
                    rgba(gradients.secondary.main, 0.2),
                    rgba(gradients.secondary.state, 0.2)
                  )}, url(${wpBg})`,
              }}
            >
              <MDBox
                mb={-2}
                height="16px"
                ml={-1}
                width="30%"
                sx={{
                  backgroundColor: "#fff",
                }}
                borderRadius="0rem 1rem"
              />
              <MDBox
                sx={{
                  height: "140px",
                  backgroundColor: "#fff",
                  width: "75%",
                  borderRadius: "0rem 1rem 1rem 1rem",
                }}
              >
                <MDBox display="flex" flexDirection="column">
                  <MDTypography
                    color="text"
                    variant="caption"
                    fontWeight="bold"
                    mx={2}
                    mt={2}
                  >
                    Müşteri Hizmetleri
                  </MDTypography>
                  <MDTypography
                    color="#dee2e6"
                    variant="button"
                    fontWeight="bold"
                    m={2}
                    mb={2.5}
                  >
                    Merhaba &#x1F44B;
                  </MDTypography>
                  <MDTypography
                    color="#dee2e6"
                    variant="button"
                    fontWeight="bold"
                    mx={2}
                  >
                    Size nasıl yardımcı olabilirim?
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" justifyContent="center" p={1}>
              <Button
                sx={{
                  marginTop: 1,
                  marginX: 2,
                  backgroundColor: "#14C656",
                  color: "#14C656",
                  borderRadius: "2rem",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#14C656", // Hover durumunda rengi aynı kalacak
                    opacity: 0.9, // Hafif opaklık
                  },
                }}
                onClick={() => {
                  const phoneNumber = "905543453544"; // Hedef telefon numarasını girin
                  const message = encodeURIComponent(
                    "Merhaba, bilgi almak istiyorum"
                  ); // URL olarak kodlanacak mesaj
                  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
                  window.open(whatsappUrl, "_blank");
                }}
              >
                <MDTypography color="white" variant="button">
                  Mesaj Gönder
                </MDTypography>
              </Button>
            </MDBox>
          </MDBox>
        )}
      </animated.div>
    </MDBox>
  );
}

export default WhatsAppMessagePopup;
