import React from "react";
import Chart from "react-apexcharts";
import { formatDuration } from "helpers/index";

const DonutChart = ({ data }) => {
  const chartOptions = {
    series: data.map((item) => item.duration),
    options: {
      labels: data.map((item) => item.mainTopic),
      chart: {
        type: "donut",
        width: "100%",
      },
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2,
          opacity: 0.5,
        },
      },
      legend: {
        position: "bottom",
        itemMargin: {
          vertical: 5, // Legend etiketleri arasında dikey padding
        },
        labels: {
          colors: "#888", // Etiket renklerini gri yap
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formatDuration(val); // Süreyi formatlayarak göster
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {},
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <Chart
      options={chartOptions.options}
      series={chartOptions.series}
      type="donut"
      key={Date.now()} // Her veri güncellemesinde komponentin yeniden oluşturulmasını sağlar.
    />
  );
};

export default DonutChart;
