// React essentials
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material-UI components
import { Grid } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExploreIcon from "@mui/icons-material/Explore";
import { Article } from "@mui/icons-material";

// Redux actions
import { fetchCompletedItems } from "slices/Statistics/services";
import { fetchVideos } from "slices/Videos/services";
import { fetchQuizGroups } from "slices/Quizzes/services";

// Helper functions
import { findUserId } from "helpers/index";

// Custom components
import GeneralSituation from "components/Custom/GeneralSituation";
import Header from "components/Custom/Header";
import MDBox from "components/MDBox";
import TestPerformance from "components/Custom/TestPerformance";
import TimeSpent from "components/Custom/TimeSpent";
import QuizzesSituation from "components/Custom/QuizzesSituation";
import OverlayCard from "components/Custom/OverlayCard";

// Layout containers
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Loading page
import LoadingPage from "../loading";

// assets
import image from "assets/images/illustrations/statistics-2.png"

export default function ProgressTracking() {
  const dispatch = useDispatch();
  const userId = findUserId();

  const videos = useSelector((state) => state.videos.items);
  const quizGroups = useSelector((state) => state.quizzes.groups || []);
  const completedQuizzesObject = useSelector(
    (state) => state.statistics.completedTests || []
  );

  const [loading, setLoading] = useState(true);

  const lessonVideos = videos.filter(
    (video) => video.isAccessible && video.videoType === "lesson"
  );

  const guidanceVideos = videos.filter(
    (video) => video.isAccessible && video.videoType === "guidance"
  );

  const completedVideosObject = useSelector(
    (state) => state.statistics.completedVideos || []
  );

  const completedVideosIds = completedVideosObject.map(
    (video) => video.videoId
  );

  useEffect(() => {
    if (!userId) {
      return <LoadingPage />;
    }
    Promise.all([
      dispatch(fetchCompletedItems(userId)),
      dispatch(fetchVideos()),
      dispatch(fetchQuizGroups()),
    ])
      .then(() => setTimeout(() => setLoading(false), 500)) // Veriler yüklendikten sonra loading'i kapat
      .catch((error) => {
        console.error("Error loading data:", error);
        setLoading(false); // Hata durumunda da loading'i kapat
      });
  }, [dispatch, userId]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="İlerleme Takibi" />
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} lg={6} xl={7.5}>
          <MDBox display="flex" height="100%">
            <GeneralSituation
              icon={<BarChartIcon sx={{ marginBottom: 1 }} />}
              title={"Ders Durumum"}
              videos={lessonVideos}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={6} xl={4.5}>
          <TimeSpent
            title={"Konulara Ayrılan Zaman Dağılımı"}
            items={lessonVideos}
            completedItemsIds={completedVideosIds}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={7.5}>
          <MDBox display="flex" height="100%">
            <QuizzesSituation
              icon={<Article sx={{ marginBottom: 1 }} />}
              title={"Test Durumum"}
              quizGroups={quizGroups}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={6} xl={4.5}>
          <TestPerformance
            title={"Test Başarım Oranları"}
            quizGroups={quizGroups}
            completedTests={completedQuizzesObject}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="stretch" mt={1}>
        <Grid item xs={12} lg={6} xl={7.5}>
          <MDBox display="flex" height="100%">
            <GeneralSituation
              icon={<ExploreIcon sx={{ marginBottom: 1 }} />}
              title={"Rehberlik Durumum"}
              videos={guidanceVideos}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={6} xl={4.5}>
          <OverlayCard image={image} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
