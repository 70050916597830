import { createSlice } from "@reduxjs/toolkit";
import { updateProfilePicture } from "./services";

const meSlice = createSlice({
  name: "me",
  initialState: {
    updateProfilePicture: {
      status: "idle",
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProfilePicture.pending, (state) => {
        state.updateProfilePicture.status = "loading";
      })
      .addCase(updateProfilePicture.fulfilled, (state, action) => {
        state.updateProfilePicture.status = "succeeded";
        state.updateProfilePicture.error = null;
      })
      .addCase(updateProfilePicture.rejected, (state, action) => {
        state.updateProfilePicture.status = "failed";
        state.updateProfilePicture.error = action.payload;
      });
  },
});

export default meSlice.reducer;
