import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Material UI components
import {
  Divider,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  Link,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Material UI icons
import {
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  Phone,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import HomePageLayout from "examples/LayoutContainers/HomepageLayout";

// Assets
import image1 from "assets/images/illustrations/contact-us.png";
import linearGradient from "assets/theme/functions/linearGradient";

// Functions and utilities
import { useNavigate } from "react-router-dom";
import { sendContactForm } from "slices/Contact/services";

const CompanyInfoItem = ({ header, value, isXsScreen }) => {
  return (
    <MDBox
      mb={3}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <MDTypography variant={isXsScreen ? "button" : "body2"} fontWeight="bold">
        {header}
      </MDTypography>
      <MDTypography
        variant={isXsScreen ? "caption" : "button"}
        fontWeight="bold"
        align="end"
      >
        {value}
      </MDTypography>
    </MDBox>
  );
};

const ContactInfoItem = ({ text, icon, onClick, isXsScreen }) => {
  return (
    <MDBox
      display="flex"
      alignItems="center"
      justifyContent={isXsScreen ? "center" : "flex-start"}
      onClick={onClick}
      sx={{ cursor: "pointer" }}
      mb={1}
    >
      {icon}
      <MDTypography
        mx={1}
        fontWeight="bold"
        variant="overline"
        fontSize="16px"
        sx={{
          color: "#e9ecef",
          "&:hover": {
            color: "#f8f9fa",
          },
        }}
      >
        {text}
      </MDTypography>
      <Divider />
    </MDBox>
  );
};

const renderSocials = () => (
  <Grid container spacing={1} justifyContent="center" alignItems="center">
    <Grid item xs={1.5} md={2} sx={{ cursor: "pointer" }}>
      <Link
        href="https://www.facebook.com/profile.php?id=61560670453443"
        target="_blank"
      >
        <Facebook fontSize="medium" color="white" />
      </Link>
    </Grid>
    <Grid item xs={1.5} md={2} sx={{ cursor: "pointer" }}>
      <Link
        href="https://www.instagram.com/dijitalderslikmat?igsh=Nm1yenVsanB6c2Z0"
        target="_blank"
      >
        <Instagram fontSize="medium" color="white" />
      </Link>
    </Grid>
    <Grid item xs={1.5} md={2} sx={{ cursor: "pointer" }}>
      <Link href="https://x.com/dijitalderslik" target="_blank">
        <Twitter fontSize="medium" color="white" />
      </Link>
    </Grid>
    <Grid item xs={1.5} md={2} sx={{ cursor: "pointer" }}>
      <Link
        href="https://www.linkedin.com/company/dijital-derslik/about/?viewAsMember=true"
        target="_blank"
      >
        <LinkedIn fontSize="medium" color="white" />
      </Link>
    </Grid>
  </Grid>
);

export default function ContactUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [status, setStatus] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 3500);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.name || !formData.phone) {
      openSB(
        "error",
        "warning",
        "Hata",
        "Ad & Soyad ve Telefon Numarası alanları gereklidir."
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const res = await dispatch(sendContactForm({ ...formData, status }));
      if (res.type === "contact/sendContactForm/fulfilled") {
        openSB(
          "success",
          "check",
          "Başarılı",
          "İletişim formu başarıyla gönderildi."
        );
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        setStatus("");
      } else if (res.type === "contact/sendContactForm/rejected") {
        openSB(
          "error",
          "warning",
          "Hata",
          error?.message || "İletişim formu gönderilirken bir hata oluştu."
        );
      }
    } catch (error) {
      openSB(
        "error",
        "warning",
        "Hata",
        error?.message || "İletişim formu gönderilirken bir hata oluştu."
      );
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
      }, 3000);
    }
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = "905543453544"; // Hedef telefon numarasını girin
    const message = encodeURIComponent("Merhaba, bilgi almak istiyorum"); // URL olarak kodlanacak mesaj
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+905543453544";
  };

  const handleMailClick = () => {
    window.location.href = "mailto:info@dijitalderslik.net";
  };

  return (
    <HomePageLayout>
      <MDBox
        mb={isXsScreen ? 5 : 10}
        mt={isXsScreen ? 10 : 30}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={2}
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          height="100%"
          py={3}
          alignItems="center"
          sx={{
            backgroundImage: linearGradient("#028391", "#57A6A1"),
            borderRadius: "1rem",
            boxShadow: "0px 0px 3px 0px gray",
          }}
          maxWidth={"1920px"}
        >
          <Grid
            item
            xs={12}
            lg={5.5}
            sx={{ margin: isXsScreen ? 3 : 5, marginX: isXsScreen ? 3 : 8 }}
            display="flex"
            flexDirection="column"
            textAlign={isXsScreen ? "center" : "start"}
            height="100%"
          >
            <MDTypography
              color="white"
              variant={isXsScreen ? "h3" : "h2"}
              mb={1}
            >
              Ekibimizle Bağlantı Kurun!
            </MDTypography>
            <MDTypography
              color="light"
              variant={isXsScreen ? "body2" : "h6"}
              mb={3}
            >
              Bize haftanın 7 günü 08:00 - 23:59 saatleri arasında
              ulaşabilirsiniz.
            </MDTypography>
            <Grid container maxWidth={isXsScreen ? "100%" : "30rem"}>
              <Grid item xs={12} md={5}>
                <ContactInfoItem
                  icon={<WhatsApp color="light" />}
                  text={"0554 345 35 44"}
                  onClick={handleWhatsAppClick}
                  isXsScreen={isXsScreen}
                />
                <ContactInfoItem
                  icon={<Phone color="light" />}
                  text={"0554 345 35 44"}
                  onClick={handlePhoneClick}
                  isXsScreen={isXsScreen}
                />
                <ContactInfoItem
                  icon={<Mail color="light" />}
                  text={"info@dijitalderslik.net"}
                  onClick={handleMailClick}
                  isXsScreen={isXsScreen}
                />
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                {renderSocials()}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={5} display="flex" justifyContent="center">
            <MDBox
              sx={{
                overflow: "hidden",
                height: "100%",
              }}
            >
              <img
                src={image1}
                alt="About Us"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transform: "scaleX(-1)",
                  maxWidth: "35rem",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox
        mt={3}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={3}
          mb={isXsScreen ? 3 : 5}
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          height="100%"
          pb={3}
          alignItems="center"
          maxWidth={"1920px"}
        >
          <Grid
            item
            xs={12}
            sx={{ marginX: !isXsScreen && 8 }}
            display="flex"
            flexDirection="column"
            height="100%"
            textAlign={isXsScreen ? "center" : "start"}
          >
            <MDTypography color="dark" variant="h2" mb={1}>
              İletişim Formu
            </MDTypography>
            <MDTypography sx={{ color: "#6c757d" }} variant="h6" mb={3}>
              Sizinle iletişime geçmemiz için formu doldurun.
            </MDTypography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                      <MDInput
                        type="text"
                        label="Ad & Soyad"
                        sx={{ width: "100%" }}
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDInput
                        type="email"
                        label="E-posta Adresi"
                        sx={{ width: "100%" }}
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDInput
                        type="tel"
                        label="Telefon Numarası"
                        sx={{ width: "100%" }}
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Veli / Öğrenci
                        </InputLabel>
                        <Select
                          sx={{ height: "2.75rem" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          label="Veli / Öğrenci"
                          onChange={handleChange}
                        >
                          <MenuItem value={"custodian"}>Veli</MenuItem>
                          <MenuItem value={"student"}>Öğrenci</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDInput
                    label="Mesajınız"
                    type="text"
                    multiline
                    rows={5}
                    sx={{ width: "100%" }}
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <MDBox
                mt={3}
                width="100%"
                display="flex"
                justifyContent={isXsScreen ? "center" : "flex-end"}
              >
                <MDButton
                  size={isXsScreen ? "medium" : "large"}
                  color="orange"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Formu Gönder
                </MDButton>
              </MDBox>
            </form>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox
        mb={isXsScreen ? 5 : 10}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={2}
          container
          display="flex"
          justifyContent="center"
          height="100%"
          alignItems="center"
          maxWidth={"1920px"}
          sx={{
            backgroundColor: "#dee2e6",
            borderRadius: ".5rem",
            boxShadow: "0px 0px 3px 0px gray",
          }}
        >
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            textAlign="justify"
            height="100%"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.967993957659!2d28.34467669800696!3d37.91995459587578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b8b92980af82e1%3A0xdd008cb361f52607!2zWWXFn2lsLCAxODY5LiBTay4sIDA5ODAwIE5hemlsbGkvQXlkxLFu!5e0!3m2!1sen!2str!4v1718326970059!5m2!1sen!2str"
              width="100%"
              height={isXsScreen ? "300" : "450"}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              tabindex="-1"
              style={{
                borderRadius: ".5rem",
                border: "none",
                outline: "none",
              }}
            ></iframe>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox
        mb={isXsScreen ? 5 : 10}
        mt={3}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={5}
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          height="100%"
          pb={3}
          alignItems="center"
          maxWidth={"1920px"}
        >
          <Grid
            item
            xs={12}
            lg={5}
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <MDBox display="flex" textAlign={isXsScreen ? "center" : "start"}>
              <MDTypography color="dark" variant="h2" mb={3}>
                Şirket Bilgileri
              </MDTypography>
            </MDBox>
            <CompanyInfoItem
              header={"Tescilli Marka Adı"}
              value={"Dijital Derslik"}
              isXsScreen={isXsScreen}
            />
            <CompanyInfoItem
              header={"İşletme Adı"}
              value={"Dijital Derslik"}
              isXsScreen={isXsScreen}
            />
            <CompanyInfoItem
              header={"MERSİS Numarası"}
              value={"2651-5197-4000-0001"}
              isXsScreen={isXsScreen}
            />
            <CompanyInfoItem
              header={"Ticaret Unvanı"}
              value={"ŞABAN EFEKAN KARABURUN DİJİTAL DERSLİK"}
              isXsScreen={isXsScreen}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={{ marginTop: isXsScreen ? -1 : 8, marginX: !isXsScreen && 8 }}
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <CompanyInfoItem
              header={"Adres"}
              value={
                "Yeşil Mah. 1869 Sk. GÜL Apt. No:1 İç Kapı No:12 Nazilli/Aydın"
              }
              isXsScreen={isXsScreen}
            />
            <CompanyInfoItem
              header={"KEP Adresi"}
              value={"sabanefekan.karaburun@hs01.kep.tr"}
              isXsScreen={isXsScreen}
            />
            <CompanyInfoItem
              header={"Vergi Müdürlüğü"}
              value={"Nazilli Vergi Dairesi"}
              isXsScreen={isXsScreen}
            />
            <CompanyInfoItem
              header={"Ticaret Sicil No"}
              value={"11508-Nazilli"}
              isXsScreen={isXsScreen}
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDBox
        mb={5}
        mt={!isXsScreen && 3}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={2}
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          height="100%"
          pb={3}
          alignItems="center"
          maxWidth={"1920px"}
          sx={{
            backgroundImage: linearGradient("#028391", "#57A6A1"),
            borderRadius: "1rem",
            boxShadow: "0px 0px 3px 0px gray",
          }}
        >
          <Grid
            item
            xs={11}
            sx={{ margin: isXsScreen ? 3 : 5, marginX: isXsScreen ? 3 : 8 }}
            display="flex"
            flexDirection="column"
            height="100%"
            textAlign={isXsScreen ? "center" : "start"}
          >
            <MDTypography
              color="white"
              variant={isXsScreen ? "h3" : "h2"}
              mb={1}
            >
              Yardıma mı ihtiyacınız var?
            </MDTypography>
            <MDTypography color="light" variant={isXsScreen ? "body2" : "h6"}>
              Yanıtları hızlı bir şekilde bulmak ve kolaylıkla yolunuza devam
              etmek için{" "}
              <span
                style={{
                  color: "white",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/help");
                }}
              >
                yardım
              </span>{" "}
              sayfasına göz atın.
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
        bgGreen
      />
    </HomePageLayout>
  );
}
