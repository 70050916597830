import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const sendContactForm = createAsyncThunk(
  "contact/sendContactForm",
  async (contactData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/contact", contactData);

      if (response.status !== 201) {
        throw new Error("Contact form submission failed");
      }

      return response.data;
    } catch (error) {
      console.error("Error submitting contact form:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchContacts = createAsyncThunk(
  "contact/fetchContacts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/contact");
      if (response.status !== 200) {
        throw new Error("Failed to fetch contact forms");
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching contact forms:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteContact = createAsyncThunk(
  "contact/deleteContact",
  async (contactId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/contact/${contactId}`);
      if (response.status !== 200) {
        throw new Error("Failed to delete contact form");
      }
      return response.data;
    } catch (error) {
      console.error("Error deleting contact form:", error);
      return rejectWithValue(error.response.data);
    }
  }
);