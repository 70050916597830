import { createSlice } from "@reduxjs/toolkit";
import {
  fetchLiveCastUrl,
  setLiveCastUrl,
  fetchDailyQuote,
  setDailyQuote,
  fetchAnnouncements,
  addAnnouncement,
  deleteAnnouncement,
} from "./services";

export const adminPanelSlice = createSlice({
  name: "adminPanel",
  initialState: {
    items: [],
    liveCastUrl: "",
    liveCastStartTime: null,
    dailyQuote: "",
    dailyQuoteTimestamp: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Live Cast URL reducers
    builder
      .addCase(fetchLiveCastUrl.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLiveCastUrl.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.liveCastUrl = action.payload.liveCastUrl;
        state.liveCastStartTime = action.payload.liveCastUrlTimestamp;
      })
      .addCase(fetchLiveCastUrl.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(setLiveCastUrl.fulfilled, (state, action) => {
        state.liveCastUrl = action.payload.liveCastUrl;
        state.liveCastStartTime = action.payload.liveCastUrlTimestamp;
      });

    // Daily Quote reducers
    builder
      .addCase(fetchDailyQuote.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDailyQuote.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dailyQuote = action.payload.dailyQuote;
        state.dailyQuoteTimestamp = action.payload.dailyQuoteTimestamp;
      })
      .addCase(fetchDailyQuote.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(setDailyQuote.fulfilled, (state, action) => {
        state.dailyQuote = action.payload.dailyQuote;
        state.dailyQuoteTimestamp = action.payload.dailyQuoteTimestamp;
      });
    // Duyuruları fetch etme

    builder
      .addCase(fetchAnnouncements.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAnnouncements.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload.announcements;
      })
      .addCase(fetchAnnouncements.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Yeni duyuru ekleme
      .addCase(addAnnouncement.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      // Duyuru silme
      .addCase(deleteAnnouncement.fulfilled, (state, action) => {
        state.items = state.items.filter(
          (announcement) => announcement._id !== action.payload._id
        );
      });
  },
});

export const selectLiveCastUrl = (state) => state.adminPanel.liveCastUrl;
export const selectLiveCastStartTime = (state) =>
  state.adminPanel.liveCastStartTime;
export const selectDailyQuote = (state) => state.adminPanel.dailyQuote;
export const selectDailyQuoteTimestamp = (state) =>
  state.adminPanel.dailyQuoteTimestamp;

export default adminPanelSlice.reducer;
