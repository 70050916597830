// React imports
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// @mui material components
import {
  FormControl,
  MenuItem,
  Select,
  Switch,
  Grid,
  RadioGroup,
  styled,
  FormControlLabel,
  Radio,
  useTheme,
  useMediaQuery,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import FormField from "components/Custom/FormField";
import FormFieldTitle from "components/Custom/FormFieldTitle";
import AlertDialog from "components/Custom/Dialog";
import Header from "components/Custom/Header";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Redux-actions
import { fetchUsers } from "slices/Users/services";

// Authentication service
import authService from "services/auth-service";

// Helpers
import { mailFormat } from "helpers/index";
import { phoneFormat } from "helpers/index";

// Data
import ActiveUsersTable from "./data/activeUsers";
import PassiveUsersTable from "./data/passiveUsers";

// assets
import typography from "assets/theme/base/typography";

// Loading Page
import LoadingPage from "../loading";

const { size, fontWeightRegular } = typography;

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiTypography-root": {
    fontWeight: fontWeightRegular,
    color: theme.palette.text.main,
  },
}));

const UserManagement = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [role, setRole] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    name: "",
    username: "",
    email: "",
    phoneNumber: "",
    city: "",
    gender: "",
  });

  const handleChangeForm = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: "" }); // Reset specific field error
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
    setErrors({ ...errors, [event.target.name]: "" });
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    const phoneNumber = value.replace(/\D/g, ""); // Sadece rakamları kabul et
    setForm({ ...form, phoneNumber });
    setErrors({ ...errors, phoneNumber: "" });
  };

  const handleSwitch = (event) => {
    setIsActive(event.target.checked);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setErrors({});
      setForm({
        name: "",
        username: "",
        email: "",
        phoneNumber: "",
        city: "",
      });
      setRole("");
      setIsActive(false);
    }, 200);
  };

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });
    setOpenSnackbar(true);
    setTimeout(() => {
      closeSB();
    }, 3500);
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!form.name) tempErrors.name = "Ad - Soyad boş olamaz";
    if (!form.username) tempErrors.username = "Kullanıcı adı boş olamaz";
    if (!form.email.match(mailFormat))
      tempErrors.email = "Geçerli bir e-posta girin";
    if (!form.phoneNumber.match(phoneFormat))
      tempErrors.phoneNumber =
        "Geçerli bir telefon numarası girin (05XX XXX XXXX)";
    if (!role) tempErrors.role = "Rol seçimi zorunludur";
    if (!form.gender) tempErrors.gender = "Cinsiyet seçimi zorunludur";

    return tempErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const tempErrors = validateForm();
    if (Object.keys(tempErrors).length === 0) {
      const myData = {
        data: {
          type: "users",
          attributes: {
            ...form,
            role,
            isActive,
            password: "Qwerty1!",
            registrationDate: new Date().toISOString(),
          },
          relationships: {
            roles: {
              data: [
                {
                  type: "roles",
                  id: "1",
                },
              ],
            },
          },
        },
      };
      try {
        const response = await authService.register(myData);
        if (response.access_token) {
          handleClose();
          fetchAllUsers();
          openSB(
            "success",
            "check",
            "Başarılı",
            <MDTypography variant="caption" color="white">
              Kullanıcı ekleme işlemi başarılı, kullanıcı şifresi: Qwerty1!{" "}
            </MDTypography>
          );
        }
      } catch (err) {
        openSB(
          "error",
          "warning",
          "Hata",
          <MDTypography variant="caption" color="white">
            {err.hasOwnProperty("message")
              ? err.message
              : "Kullanıcı ekleme işlemi başarısız"}
          </MDTypography>
        );
      }
    } else {
      setErrors(tempErrors);
    }
  };

  const fetchAllUsers = () => {
    dispatch(fetchUsers());
  };

  useEffect(() => {
    dispatch(fetchUsers())
      .then(() => setTimeout(() => setLoading(false), 500)) // Veriler yüklendikten sonra loading'i kapat
      .catch((error) => {
        console.error("Error loading data:", error);
        setLoading(false); // Hata durumunda da loading'i kapat
      });
  }, [dispatch]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header
        title="Kullanıcı Listesi"
        buttonText="Kullanıcı Ekle"
        action={handleOpen}
      />
      <MDBox display="flex" width="100%" justifyContent="center">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ActiveUsersTable openSB={openSB} closeSB={closeSB} />
          </Grid>
          <Grid item xs={12}>
            <PassiveUsersTable openSB={openSB} closeSB={closeSB} />
          </Grid>
        </Grid>
      </MDBox>

      <AlertDialog
        width="md"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title="Kullanıcı Ekle"
        disabled={false}
        fullScreen={isXsScreen}
      >
        <Grid container spacing={isXsScreen ? 1 : 3}>
          <Grid mt={1} item xs={12} md={6} xl={6}>
            <FormField
              label="Ad - Soyad"
              type="name"
              name="name"
              value={form.name}
              onChange={handleChangeForm}
              error={errors.name}
              errorMessage={errors.name}
            />
          </Grid>
          <Grid mt={1} item xs={12} md={6} xl={6}>
            <FormField
              label="Kullanıcı Adı"
              type="text"
              name="username"
              value={form.username}
              onChange={handleChangeForm}
              error={errors.username}
              errorMessage={errors.username}
            />
          </Grid>
          <Grid mt={1} item xs={12} md={6} xl={4}>
            <FormField
              label="E-posta"
              type="email"
              name="email"
              value={form.email}
              onChange={handleChangeForm}
              error={errors.email}
              errorMessage={errors.email}
            />
          </Grid>
          <Grid mt={1} item xs={12} md={6} xl={4}>
            <FormField
              label="Telefon Numarası"
              type="text"
              name="phoneNumber"
              value={form.phoneNumber}
              onChange={handlePhoneNumberChange}
              error={errors.phoneNumber}
              errorMessage={errors.phoneNumber}
            />
          </Grid>
          <Grid mt={1} item xs={12} md={6} xl={4}>
            <FormFieldTitle>Rol</FormFieldTitle>
            <FormControl fullWidth error={!!errors.role}>
              <Select
                name="role"
                sx={{ height: "2.75rem" }}
                value={role}
                onChange={handleChangeRole}
              >
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"teacher"}>Öğretmen</MenuItem>
                <MenuItem value={"student"}>Öğrenci</MenuItem>
              </Select>
              {!!errors.role && (
                <MDTypography
                  variant="caption"
                  color="error"
                  display="block"
                  sx={{ mt: 1, mx: 1 }}
                >
                  {errors.role}
                </MDTypography>
              )}
            </FormControl>
          </Grid>
          <Grid mt={1} item xs={12} md={6} xl={4}>
            <FormField
              label="Şehir"
              type="text"
              name="city"
              value={form.city}
              onChange={handleChangeForm}
            />
          </Grid>
          <Grid mt={3} item xs={12} md={6} xl={5}>
            <MDBox display="flex" flexDirection="column">
              <MDTypography variant="caption" fontWeight="bold" ml={1.5}>
                Cinsiyet Bilgisi
              </MDTypography>
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={form.gender}
                onChange={handleChangeForm}
                row
              >
                <CustomFormControlLabel
                  value="male"
                  control={<Radio sx={{ marginTop: 0.5 }} />}
                  label="Erkek"
                />
                <CustomFormControlLabel
                  value="female"
                  control={<Radio sx={{ marginTop: 0.5 }} />}
                  label="Kadın"
                />
                <CustomFormControlLabel
                  value="other"
                  control={<Radio sx={{ marginTop: 0.5 }} />}
                  label="Diğer"
                />
              </RadioGroup>
              {errors.gender && (
                <MDBox width="100%" display="flex">
                  <MDTypography
                    variant="caption"
                    color="error"
                    fontWeight="light"
                  >
                    Cinsiyet seçiniz
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Grid>
          <Grid mt={1} item xs={12} md={6} xl={3}>
            <FormFieldTitle>Aktif/Pasif</FormFieldTitle>
            <MDBox display="flex" alignItems="center" ml={-1.5}>
              <Switch
                name="isActive"
                checked={isActive}
                onChange={handleSwitch}
              />
              <MDTypography variant="overline">
                {isActive ? "Aktif" : "Pasif"}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </AlertDialog>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
      />
    </DashboardLayout>
  );
};

export default UserManagement;
