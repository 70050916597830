// React
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// MUI
import { Card, Grid, useMediaQuery, useTheme } from "@mui/material";

// Custom components
import ContactCard from "components/Custom/ContactCard";
import Header from "components/Custom/Header";
import OverlayCard from "components/Custom/OverlayCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React layouts
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Redux-store
import { fetchContacts } from "slices/Contact/services";
import { deleteContact } from "slices/Contact/services";
import {
  fetchLiveCastUrl,
  setLiveCastUrl,
  setDailyQuote,
  fetchDailyQuote,
} from "slices/AdminPanel/services";

// Loading Page
import LoadingPage from "../loading";

// assets
import image from "assets/images/illustrations/settings.png";
import AnnouncementManagement from "components/Custom/AnnouncementManagement";

export default function ManagementPanel() {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contact.contacts);

  const liveCastUrl = useSelector((state) => state.adminPanel.liveCastUrl);
  const dailyQuote = useSelector((state) => state.adminPanel.dailyQuote);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [newLiveCastUrl, setNewLiveCastUrl] = useState(liveCastUrl || "");
  const [newDailyQuote, setNewDailyQuote] = useState(dailyQuote || "");
  const [isLiveCastButtonDisabled, setIsLiveCastButtonDisabled] =
    useState(true);
  const [liveCastButtonText, setLiveCastButtonText] = useState("Başlat");
  const [isDailyQuoteButtonDisabled, setIsDailyQuoteButtonDisabled] =
    useState(false);
  const [dailyQuoteButtonText, setDailyQuoteButtonText] = useState("Kaydet");
  const [loading, setLoading] = useState(true);

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
  };

  useEffect(() => {
    Promise.all([
      dispatch(fetchContacts()),
      dispatch(fetchLiveCastUrl()),
      dispatch(fetchDailyQuote()),
    ])
      .then(() => setTimeout(() => setLoading(false), 500)) // Veriler yüklendikten sonra loading'i kapat
      .catch((error) => {
        setLoading(false); // Hata durumunda da loading'i kapat
      });
  }, [dispatch]);

  useEffect(() => {
    if (liveCastUrl && liveCastUrl.length > 1) {
      setNewLiveCastUrl(liveCastUrl);
      setIsLiveCastButtonDisabled(true);
      setLiveCastButtonText("Başlatıldı");
    }
    if (dailyQuote) {
      setNewDailyQuote(dailyQuote);
      setIsDailyQuoteButtonDisabled(true);
      setDailyQuoteButtonText("Kaydedildi");
    }
  }, [liveCastUrl, dailyQuote]);

  const handleLiveCastSubmit = (e) => {
    e.preventDefault();
    const trimmedUrl = newLiveCastUrl.trim();
    dispatch(setLiveCastUrl(trimmedUrl));
    setIsLiveCastButtonDisabled(true);
    setLiveCastButtonText("Başlatıldı");
  };

  const handleDailyQuoteSubmit = (e) => {
    e.preventDefault();
    const trimmedQuote = newDailyQuote.trim();
    dispatch(setDailyQuote(trimmedQuote));
    setIsDailyQuoteButtonDisabled(true);
    setDailyQuoteButtonText("Kaydedildi");
  };

  const handleClear = () => {
    const clearedUrl = " "; // Boş bir string gönder
    setNewLiveCastUrl(clearedUrl);
    setIsLiveCastButtonDisabled(true);
    setLiveCastButtonText("Başlat");
    dispatch(setLiveCastUrl(clearedUrl));
  };

  const handleLiveCastInputChange = (e) => {
    setNewLiveCastUrl(e.target.value);
    setIsLiveCastButtonDisabled(false);
    setLiveCastButtonText("Başlat");
  };

  const handleDailyQuoteInputChange = (e) => {
    setNewDailyQuote(e.target.value);
    setIsDailyQuoteButtonDisabled(false);
    setDailyQuoteButtonText("Kaydet");
  };

  const handleDelete = async (id) => {
    openSB("info", "warning", "Bildirim", "Mesaj siliniyor...");

    try {
      const res = await dispatch(deleteContact(id));
      if (res.type === "contact/deleteContact/fulfilled") {
        openSB(
          "success",
          "check",
          "Başarılı",
          <MDTypography
            variant={isXsScreen ? "caption" : "button"}
            color="white"
          >
            Mesaj başarıyla kaldırıldı.
          </MDTypography>
        );
      } else if (res.type === "contact/deleteContact/rejected") {
        openSB(
          "error",
          "warning",
          "Hata",
          error?.message || "Mesaj silinirken bir hata oluştu."
        );
      }
    } catch (error) {
      openSB(
        "error",
        "warning",
        "Hata",
        <MDTypography variant={isXsScreen ? "caption" : "button"} color="white">
          {error?.hasOwnProperty("message")
            ? error?.message
            : "Mesaj kaldırılırken bir hata meydana geldi."}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3500);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Yönetim Paneli" />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 3, display: "flex", marginBottom: 1 }}>
            <MDBox mb={1} mx={1.5}>
              <MDTypography variant="h6" fontWeight="medium">
                Günün Sözü
              </MDTypography>
            </MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDInput
                  type="text"
                  value={newDailyQuote}
                  onChange={handleDailyQuoteInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                gap={1}
              >
                <MDButton
                  onClick={handleDailyQuoteSubmit}
                  type="submit"
                  color="info"
                  variant="gradient"
                  size={isXsScreen ? "small" : "small"}
                  disabled={isDailyQuoteButtonDisabled}
                >
                  {dailyQuoteButtonText}
                </MDButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 3, display: "flex", marginBottom: 1 }}>
            <MDBox mb={1} mx={1.5}>
              <MDTypography variant="h6" fontWeight="medium">
                Canlı Yayın Linki
              </MDTypography>
            </MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDInput
                  type="text"
                  value={newLiveCastUrl}
                  onChange={handleLiveCastInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                gap={1}
              >
                <MDButton
                  onClick={handleLiveCastSubmit}
                  type="submit"
                  color="info"
                  variant="gradient"
                  size={isXsScreen ? "small" : "small"}
                  disabled={isLiveCastButtonDisabled}
                >
                  {liveCastButtonText}
                </MDButton>
                <MDButton
                  onClick={handleClear}
                  color="error"
                  variant="gradient"
                  size={isXsScreen ? "small" : "small"}
                >
                  Sonlandır
                </MDButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={8} xl={8.5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card
                id="delete-account"
                sx={{
                  maxHeight: "50rem",
                  overflow: "auto",
                }}
              >
                <MDBox pt={3} px={3}>
                  <MDTypography variant="h6" fontWeight="medium" mx={1.5}>
                    İletişim Formu
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  {contacts?.length !== 0 ? (
                    <MDBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                    >
                      {contacts.map((contact) => (
                        <ContactCard
                          key={contact.id}
                          contact={contact}
                          handleDelete={handleDelete}
                        />
                      ))}
                    </MDBox>
                  ) : (
                    <MDBox
                      display="flex"
                      justifyContent="flex-start"
                      p={1}
                      mx={1.5}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        fontWeight="regular"
                      >
                        Veri bulunamadı.
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} mt={1}>
              <AnnouncementManagement/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} xl={3.5} maxHeight="22rem">
          <OverlayCard image={image} />
        </Grid>
      </Grid>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
        bgGreen
      />
    </DashboardLayout>
  );
}
