import React from "react";
import { Card, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import quoteImage from "assets/images/illustrations/dashboard-4.png";
import pxToRem from "assets/theme/functions/pxToRem";

export default function QuoteCard({ title, content }) {
  return (
    <Card
      sx={{
        paddingX: 2,
        overflow: "hidden",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={0}>
        {/* Metin kısmı */}
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <MDBox
            height="100%"
            justifyContent="center"
            alignItems="center"
            sx={{
              flex: 1,
              padding: 2,
              lineHeight: 1.2,
            }}
          >
            <MDTypography variant="h6" mb={1} mt={2} color="dark">
              {title}
            </MDTypography>
            <MDTypography variant="button" color="text">
              {content}
            </MDTypography>
          </MDBox>
        </Grid>

        {/* Resim kısmı */}
        <Grid
          item
          xs={12}
          sm={6}
          display="flex"
          justifyContent={{ xs: "center", md: "end" }}
          alignItems="center" // Resmi dikeyde ortala
        >
          <MDBox
            component="img"
            src={quoteImage}
            alt="rocket"
            sx={{
              maxWidth: "100%", // Resmin genişliğini kutunun genişliğine göre ayarla
              maxHeight: pxToRem(200), // Resmin yüksekliğini sınırlı tutarak taşmasını önle
              objectFit: "contain", // Resmin orantılı şekilde sığmasını sağla
              borderRadius: pxToRem(12),
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
