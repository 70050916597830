// React hooks
import { useState, useEffect } from "react";

// Material Dashboard UI components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Dashboard layout components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Authentication service
import AuthService from "../../../services/auth-service";

// Material-UI components
import {
  Card,
  Divider,
  Grid,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AlarmIcon from "@mui/icons-material/Alarm";
import InfoIcon from "@mui/icons-material/Info";
import UpdateIcon from "@mui/icons-material/Update";

// Static assets
import backgroundImageMale from "assets/images/profile/bg-profile-2.jpeg";
import backgroundImageFemale from "assets/images/profile/bg-profile-3.jpeg";

// Custom UI components
import Header from "components/Custom/Header";
import ProfileCard from "components/Custom/ProfileCard";
import FormField from "components/Custom/FormField";
import AlertDialog from "components/Custom/Dialog";
import TokenCountdown from "components/Custom/TokenCountDown";

// Utility functions
import { formatDateTime } from "helpers/index";
import { mailFormat } from "helpers/index";
import { phoneFormat } from "helpers/index";
import LoadingPage from "../loading";

const UserProfile = () => {
  const theme = useTheme();
  const isUltraWide = useMediaQuery("(min-width:2561px)");
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);
  const [originalUser, setOriginalUser] = useState({});
  const [open, setOpen] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });

  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
    username: "",
    phoneNumber: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    emailError: false,
    usernameError: false,
    phoneNumberError: false,
    newPassError: false,
    confirmPassError: false,
  });

  const isSuperUser = ["superuser"].includes(originalUser?.role);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeSB = (duration) =>
    setTimeout(() => {
      setOpenSnackbar(false);
    }, duration);

  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
  };

  const getUserData = async () => {
    try {
      const response = await AuthService.getProfile();

      setUser((prevUser) => ({
        ...prevUser,
        ...response.data.attributes,
        id: response.data.id,
        newPassword: "",
        confirmPassword: "",
      }));

      setOriginalUser({
        id: response.data.id,
        name: response.data.attributes.name,
        email: response.data.attributes.email,
        username: response.data.attributes.username,
        phoneNumber: response.data.attributes.phoneNumber,
        gender: response.data.attributes.gender,
        isActive: response.data.attributes.isActive,
        city: response.data.attributes.city,
        registrationDate: response.data.attributes.registrationDate,
        score: response.data.attributes.score,
        updatedAt: response.data.attributes.updatedAt,
        role: response.data.attributes.role,
        profileImage: response.data.attributes.profileImage,
      });

      setTimeout(() => setLoading(false), 500);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setLoading(false); // Hata durumunda da loading'i kapat
    }
  };

  const { readableDate, readableTime } = formatDateTime(
    originalUser?.updatedAt
  );

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [`${e.target.name}Error`]: false });
  };

  const isFormChanged = () => {
    return (
      user.name !== originalUser.name ||
      user.email !== originalUser.email ||
      user.username !== originalUser.username ||
      user.phoneNumber !== originalUser.phoneNumber ||
      user.newPassword !== "" // Yeni parola girilmiş mi kontrol et
    );
  };

  const validateForm = () => {
    const newErrors = {
      nameError: !user.name.trim(),
      emailError: !user.email.trim().match(mailFormat),
      usernameError: !user.username.trim(),
      phoneNumberError: !user.phoneNumber.trim().match(phoneFormat),
      newPassError: user.newPassword && user.newPassword.length < 8,
      confirmPassError:
        user.newPassword && user.confirmPassword !== user.newPassword,
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some(Boolean);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      handleClose();
      openSB(
        "warning",
        "warning",
        "Uyarı",
        <MDTypography variant="button" color="white">
          Profil bilgileriniz güncellenemedi. Lütfen formu eksiksiz ve doğru bir
          şekilde doldurunuz.
        </MDTypography>
      );
      closeSB(3000);
      return;
    }

    handleClose();

    let userData = {
      data: {
        type: "profile",
        attributes: {
          name: user.name,
          email: user.email,
          username: user.username,
          phoneNumber: user.phoneNumber,
          profile_image: null,
          ...(user.newPassword && {
            password: user.newPassword,
            newPassword: user.newPassword,
            confirmPassword: user.confirmPassword,
          }),
        },
      },
    };

    try {
      const response = await AuthService.updateProfile(
        JSON.stringify(userData)
      );
      const updatedUserData = response.data;
      setOriginalUser({
        ...user,
        ...updatedUserData.attributes,
        updatedAt: updatedUserData.attributes.updatedAt,
      });
      setUser({ ...user, newPassword: "", confirmPassword: "" });
      setErrors({
        nameError: false,
        emailError: false,
        usernameError: false,
        phoneNumberError: false,
        newPassError: false,
        confirmPassError: false,
      });
      openSB(
        "success",
        "check",
        "Bildirim",
        <MDTypography variant="button" color="white">
          Profil bilgileriniz başarıyla güncellendi
        </MDTypography>
      );
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          Profil bilgileriniz güncellenemedi
        </MDTypography>
      );
    } finally {
      closeSB(3000);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const editProfileContent = (
    <Card sx={{ padding: 3, width: "100%" }}>
      <MDTypography mx={1} variant="body2" fontWeight="bold">
        Profil Bilgilerini Düzenle
      </MDTypography>
      <Divider />
      <MDBox
        component="form"
        role="form"
        onSubmit={submitHandler}
        display="flex"
        flexDirection="column"
      >
        <Grid container spacing={isXsScreen ? 1 : 3}>
          <Grid item xs={12} md={6}>
            <FormField
              label="İsim"
              type="name"
              name="name"
              value={user.name}
              onChange={changeHandler}
              error={errors.nameError}
              errorMessage="İsim boş olamaz"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="E-posta"
              type="email"
              name="email"
              value={user.email}
              onChange={changeHandler}
              error={errors.emailError}
              errorMessage="Geçerli bir e-posta girin"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label="Kullanıcı Adı"
              type="text"
              name="username"
              value={user.username}
              onChange={changeHandler}
              error={errors.usernameError}
              errorMessage="Kullanıcı adı boş olamaz"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormField
              label="Telefon Numarası"
              type="phoneNumber"
              name="phoneNumber"
              value={user.phoneNumber}
              onChange={changeHandler}
              error={errors.phoneNumberError}
              errorMessage="Geçerli bir telefon numarası girin"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormField
              label="Yeni Parola"
              type="password"
              name="newPassword"
              value={user.newPassword}
              onChange={changeHandler}
              error={errors.newPassError}
              errorMessage="Şifre en az 8 karakterden oluşmalıdır"
              placeholder="Yeni Parolanızı Girin"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormField
              label="Parola Onayı"
              type="password"
              name="confirmPassword"
              value={user.confirmPassword}
              onChange={changeHandler}
              error={errors.confirmPassError}
              errorMessage="Şifre onayı yeni şifreyle eşleşmelidir"
              placeholder="Yeni Parolayı Onaylayın"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} md={8} lg={6}>
            <MDBox
              px={3}
              py={0.4}
              sx={{
                backgroundColor: "#dee2e6",
                borderRadius: "12px",
                maxWidth: isXsScreen ? "100%" : "25rem",
              }}
            >
              {!isXsScreen ? (
                <>
                  <UpdateIcon />
                  <MDTypography variant="overline" mx={1}>
                    Son Güncelleme: {readableDate} - {readableTime}
                  </MDTypography>
                </>
              ) : (
                <MDBox display={"flex"} flexDirection={"column"}>
                  <MDBox display={"flex"} alignItems={"center"}>
                    <UpdateIcon />
                    <MDTypography variant="overline" mx={1}>
                      Son Güncelleme:
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="overline" mx={3.5}>
                    {readableDate} - {readableTime}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={6}
            display="flex"
            justifyContent={isXsScreen ? "center" : "flex-end"}
          >
            <Tooltip title={!isFormChanged() ? "Değişiklik yapılmadı" : ""}>
              <span style={{width:"100%"}}>
                <MDButton
                  size="medium"
                  variant="gradient"
                  color="info"
                  onClick={handleOpen}
                  disabled={!isFormChanged()}
                  fullWidth
                >
                  Değişikleri Kaydet
                </MDButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isXsScreen ? (
        <Header title="Kullanıcı Profili" />
      ) : (
        <Header
          title="Kullanıcı Profili"
          image={
            originalUser?.gender === "female"
              ? backgroundImageFemale
              : originalUser?.gender === "male"
              ? backgroundImageMale
              : backgroundImageMale
          }
        />
      )}

      {originalUser?.role && (
        <MDBox display="flex" width="100%" justifyContent="center">
          <Grid container spacing={3}>
            {/* Profile card - only render first on ultrawide screens */}
            <Grid item xs={12} lg={6.5}>
              <ProfileCard
                user={originalUser}
                openSB={openSB}
                closeSB={closeSB}
              />
            </Grid>
            <Grid item xs={12} lg={5.5}>
              <Grid container spacing={isSuperUser ? 0 : 3}>
                <Grid item xs={12}>
                  {/* Editing form - middle on ultrawide, otherwise normal flow */}
                  {originalUser?.role && !isSuperUser && (
                    <Grid item>{editProfileContent}</Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {/* Session time - last on ultrawide, otherwise normal flow */}
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        padding: 3,
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox display="flex" alignItems="center" mb={3}>
                        <AlarmIcon />
                        <MDTypography variant="body2" fontWeight="bold" mx={1}>
                          Oturum Süresi
                        </MDTypography>
                      </MDBox>
                      <TokenCountdown />
                      <MDBox display="flex" alignItems="center" mt={3}>
                        <MDTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                        >
                          <InfoIcon
                            fontSize="small"
                            sx={{ marginBottom: 0.2, marginX: 0.2 }}
                          />{" "}
                          Bu süre sonunda, sistemden otomatik olarak çıkış
                          yapılacaktır.
                        </MDTypography>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}

      <AlertDialog
        width="sm"
        open={open}
        onClose={handleClose}
        onSubmit={submitHandler}
        title={"Değişiklikleri Kaydet"}
      >
        <MDTypography color="text" variant="body2" mx={1}>
          Profil bilgileriniz değiştirilecek, emin misiniz?
        </MDTypography>
      </AlertDialog>
      {openSnackbar && (
        <MDSnackbar
          {...snackbarContent}
          open={openSnackbar}
          onClose={closeSB}
          close={closeSB}
        />
      )}
    </DashboardLayout>
  );
};

export default UserProfile;
