import image1 from "assets/images/blog/time-management.jpg";
import image2 from "assets/images/blog/common-mistakes.jpg";
import image3 from "assets/images/blog/strategies.jpg";
import image4 from "assets/images/blog/exam-anxiety.jpg";
import image5 from "assets/images/blog/optimised-way.jpg";
import image6 from "assets/images/blog/focusing.jpg";
import image7 from "assets/images/blog/exam-preparation.jpg";
import image8 from "assets/images/blog/organized.jpg";

export const blogItemContent = [
  {
    title: "LGS'ye Hazırlanırken Zaman Yönetimi",
    text: `Sınav hazırlığında zaman yönetimi oldukça önemlidir. Öğrencilerin ders çalışma, dinlenme ve sosyal aktiviteler arasında doğru dengeyi bulması gerekir. 
    Bu yazımızda, zaman yönetimini nasıl etkili bir şekilde yapabileceğinizi anlatıyoruz. İlk olarak, günlük çalışma saatlerinizi belirlemekle başlayın. Her gün belirli saatlerde ders çalışmak alışkanlığı, disiplin ve motivasyonu artırır. 
    Örneğin, her sabah 9'da başlayıp öğlen 12'ye kadar çalışabilirsiniz. Bu süre zarfında derslerinizi bölümlere ayırarak çalışmanız verimliliği artıracaktır. Örneğin, 45 dakika çalışıp 15 dakika ara vermek gibi.
    Ayrıca, dinlenmek ve eğlenmek için de zaman ayırın. Dinlenmiş bir zihin, daha hızlı öğrenmeyi sağlar. Gün içinde kısa molalar vermek, zihninizi dinlendirir ve motivasyonunuzu yüksek tutar. 
    Örneğin, her iki saatte bir 10 dakikalık molalar vererek kısa yürüyüşler yapabilirsiniz. Bu molalar sırasında derin nefes alarak ve hafif egzersizler yaparak stresi azaltabilirsiniz.
    Zaman yönetiminin bir diğer önemli noktası ise hedef belirlemektir. Günlük, haftalık ve aylık hedefler koyarak ilerlemenizi takip edebilirsiniz. Bu hedefler, çalışmanızı daha düzenli ve planlı hale getirir. 
    Örneğin, haftalık hedefleriniz arasında belirli bir konuyu tamamlamak veya belli sayıda test çözmek olabilir. Bir çalışma programı oluşturmak da oldukça faydalıdır. 
    Bu programda hangi gün hangi derse çalışacağınızı belirlemek, size yol gösterici olur. Örneğin, Pazartesi ve Çarşamba günleri matematik, Salı ve Perşembe günleri Türkçe çalışabilirsiniz.
    Son olarak, zaman yönetiminde esnek olmayı unutmayın. Planlarınızı gerektiğinde güncelleyerek, değişen koşullara uyum sağlayabilirsiniz. Bu sayede, çalışma programınızı daha verimli hale getirebilir ve sınav hazırlığınızı daha etkili bir şekilde sürdürebilirsiniz.
    Etkili zaman yönetimi ile öğrenciler hedeflerine daha çabuk ulaşabilirler.`,
    image: image1,
  },
  {
    title: "Matematik Sorularında Yapılan Yaygın Hatalar",
    text: `LGS'de matematik sorularını çözmekte zorlanan öğrenciler için yaygın hataları analiz ettik. Bu yazıda, bu hatalardan kaçınmanın yollarını ve doğru çözüm tekniklerini paylaşıyoruz. 
    Birçok öğrenci, soruyu tam okumadan çözmeye çalıştığı için hata yapar. Bu yüzden, soruyu iyice anlamak ve adımları planlamak önemlidir. Ayrıca, konulara hakim olmanın ve bol bol pratik yapmanın önemi büyüktür. 
    Örneğin, bir soruyu çözerken adım adım ilerlemek ve her adımı kontrol etmek hata yapma olasılığınızı azaltır. Sıkça yapılan bir diğer hata ise, işlem hatalarıdır. 
    İşlem hatalarını azaltmak için dikkatli ve yavaş çalışmak, ve çözümlerinizi tekrar kontrol etmek önemlidir. Ayrıca, konuları tam anlamadan soru çözmeye çalışmak da büyük bir hatadır. 
    Bu nedenle, öncelikle konuları tam olarak öğrenmek ve anlamadığınız noktaları öğretmeninizden veya kaynaklardan yardım alarak çözmek önemlidir. 
    Son olarak, zamanı etkili kullanmamak da yaygın bir hatadır. Sınavda her soruya eşit zaman ayırmak yerine, zorlandığınız sorulara daha fazla zaman ayırarak, kolay soruları hızlıca geçmek daha mantıklı olabilir. 
    Bu teknikleri kullanarak yaygın hatalardan kaçınabilir ve matematikte daha iyi bir performans gösterebilirsiniz.`,
    image: image2,
  },
  {
    title: "LGS Sınavında Başarılı Olmak İçin Stratejiler",
    text: `LGS sınavında başarılı olmak için uygulanması gereken stratejiler ve adımlar hakkında ipuçları. Konu tekrarı, pratik yapma ve sınav taktiklerini burada öğrenebilirsiniz. 
    Öncelikle, her konuyu iyice kavrayana kadar tekrar edin. Her konunun püf noktalarını anlayın ve eksiklerinizi belirleyin. Daha sonra, belirli aralıklarla deneme sınavları yapın. 
    Bu denemeler size eksiklerinizi gösterecek ve sınav sürecine alışmanızı sağlayacaktır. Sınav stresini de göz önüne alarak zaman yönetimi ve sakin kalma üzerine çalışın. 
    Bir diğer strateji ise, sınav taktiklerini öğrenmektir. Örneğin, soruları nasıl hızlı ve doğru bir şekilde çözeceğinizi öğrenmek, sınavda size zaman kazandırabilir. 
    Ayrıca, sınav sırasında panik yapmamak ve sakin kalmak da oldukça önemlidir. Bunun için sınavdan önce bol bol pratik yaparak kendinizi sınav atmosferine alıştırabilirsiniz. 
    Bu stratejileri uygulayarak LGS sınavında başarılı olma şansınızı artırabilirsiniz.`,
    image: image3,
  },
  {
    title: "Sınav Stresiyle Başa Çıkmanın Yolları",
    text: `Sınav stresi, öğrencilerin başarılarını etkileyebilecek önemli bir faktördür. Sınav stresini nasıl azaltabileceğinizi ve motivasyonunuzu nasıl koruyabileceğinizi burada bulabilirsiniz. 
    Sınav dönemlerinde rahatlama tekniklerini öğrenmek oldukça önemlidir. Derin nefes alma ve meditasyon gibi teknikler, sakinleşmenize yardımcı olabilir. 
    Ayrıca, sınavdan önce bolca pratik yaparak güveninizi artırabilirsiniz. Kendinize güvenin ve sınavın sonu olmadığını unutmayın. Olumlu düşüncelerle stres seviyenizi azaltabilirsiniz. 
    Bir diğer yöntem ise, sınav sırasında nefes egzersizleri yapmaktır. Sınavdan önce birkaç dakika derin nefes alıp vererek, vücudunuzun ve zihninizin rahatlamasını sağlayabilirsiniz. 
    Ayrıca, sınav sırasında zorlandığınız soruları geçip, kolay sorulara yönelmek de stresi azaltabilir. Bu sayede, sınavın sonuna doğru zor sorulara geri dönerek, daha rahat bir şekilde çözebilirsiniz. 
    Stresi azaltmak için bir diğer önemli nokta ise, iyi bir uyku düzenine sahip olmaktır. Yeterli ve kaliteli uyku, hem zihinsel hem de fiziksel olarak sizi dinç tutar ve sınavda daha iyi performans göstermenizi sağlar. 
    Bu teknikleri kullanarak sınav stresinizi azaltabilir ve sınavda daha başarılı olabilirsiniz.`,
    image: image4,
  },
  {
    title: "Türkçe Sorularını Daha Hızlı Çözmenin Püf Noktaları",
    text: `LGS'de Türkçe sorularını hızla çözmek için kullanabileceğiniz ipuçlarını keşfedin. Okuma stratejileri, anlama teknikleri ve zaman kazandıracak diğer önerileri paylaşıyoruz. 
    Soruyu okurken anahtar kelimeleri belirleyin ve sorunun özünü anlayın. Bu, cevabı bulmanızı kolaylaştıracaktır. Ayrıca, metni bütünüyle anlamaya çalışarak dikkatli okumalar yapın. 
    Pratik yaparak okuma hızınızı artırabilir ve daha kısa sürede doğru cevaplara ulaşabilirsiniz. Bir diğer teknik ise, soruların kökünü ve ne tür bir yanıt istendiğini belirlemektir. 
    Bu sayede, gereksiz ayrıntılara takılmadan, doğru cevaba ulaşabilirsiniz. Ayrıca, sıkça yapılan hataları belirleyerek, bu hatalardan kaçınabilirsiniz. 
    Örneğin, soruyu tam anlamadan cevap vermek, en sık yapılan hatalardan biridir. Bu nedenle, soruyu iyice anlamak ve tüm seçenekleri gözden geçirmek önemlidir. 
    Son olarak, zaman yönetimi de oldukça önemlidir. Sınav sırasında her soruya eşit zaman ayırarak, zorlandığınız sorulara daha fazla zaman ayırabilirsiniz. 
    Bu teknikleri kullanarak, Türkçe sorularını daha hızlı ve doğru bir şekilde çözebilirsiniz.`,
    image: image5,
  },
  {
    title: "LGS Matematik Konularına Odaklanma Rehberi",
    text: `Matematikte hangi konularda eksik olduğunuzu belirlemek için rehber niteliğinde bir içerik. Bu rehberle birlikte konulara nasıl odaklanacağınızı ve hangi alanlarda çalışmanız gerektiğini öğrenebilirsiniz. 
    İlk olarak, her konuyu ayrı ayrı ele alarak bir plan yapın. Zayıf olduğunuz konuları belirleyin ve bu alanlara daha fazla odaklanın. 
    Eksik olduğunuz konuları kapatmak için video dersleri, kitaplar veya online materyalleri kullanın. Düzenli tekrar ve testlerle matematikteki eksiklerinizi kapatın. 
    Bir diğer önemli nokta ise, sıkça yapılan hataları belirlemektir. Örneğin, işlem hataları ve dikkat eksikliği, matematikte en sık yapılan hatalardandır. 
    Bu hataları azaltmak için dikkatli ve yavaş çalışmak, ve çözümlerinizi tekrar kontrol etmek önemlidir. Ayrıca, konuları tam anlamadan soru çözmeye çalışmak da büyük bir hatadır. 
    Bu nedenle, öncelikle konuları tam olarak öğrenmek ve anlamadığınız noktaları öğretmeninizden veya kaynaklardan yardım alarak çözmek önemlidir. 
    Son olarak, zaman yönetimi de oldukça önemlidir. Sınavda her soruya eşit zaman ayırmak yerine, zorlandığınız sorulara daha fazla zaman ayırarak, kolay soruları hızlıca geçmek daha mantıklı olabilir. 
    Bu teknikleri kullanarak yaygın hatalardan kaçınabilir ve matematikte daha iyi bir performans gösterebilirsiniz.`,
    image: image6,
  },
  {
    title: "Deneme Sınavları ile Gerçek Sınava Hazırlık",
    text: `Deneme sınavlarının önemi ve gerçek sınava hazırlık için nasıl kullanılabileceği hakkında detaylı bir rehber. Deneme sınavlarına doğru yaklaşmanın püf noktalarını paylaşıyoruz. 
    Gerçek sınav koşullarında denemeler yapın ve soruları yanıtlamak için zaman sınırı koyun. Her denemeden sonra doğru ve yanlış cevapları analiz edin ve eksiklerinizi belirleyin. 
    Bu eksiklikleri kapatmak için ders çalışma rutininizi güncelleyin. Sınav süresine alıştığınızda, gerçek sınavda daha sakin ve özgüvenli olacaksınız. 
    Bir diğer önemli nokta ise, deneme sınavlarını düzenli olarak yapmaktır. Her hafta veya her ay belirli günlerde deneme sınavları yaparak, ilerlemenizi takip edebilirsiniz. 
    Ayrıca, deneme sınavları sırasında zamanı etkili kullanmayı öğrenmek de önemlidir. Zorlandığınız sorulara daha fazla zaman ayırarak, kolay soruları hızlıca geçmek daha mantıklı olabilir. 
    Bu teknikleri kullanarak deneme sınavlarından maksimum verim alabilir ve gerçek sınava daha iyi hazırlanabilirsiniz.`,
    image: image7,
  },
  {
    title: "LGS'ye Hazırlıkta Düzenli Çalışmanın Faydaları",
    text: `Düzenli ve planlı çalışmanın LGS'deki başarınıza etkilerini burada öğrenin. Nasıl bir plan oluşturmanız gerektiği ve verimli çalışmanın yöntemlerini bu yazıda bulabilirsiniz. 
    Öncelikle, günlük çalışma planınızı oluşturun ve her gün belirli saatlerde çalışın. Zamanında ve düzenli çalışmak, öğrenme sürecini hızlandırır. 
    Planınıza sadık kalın ve her konuyu düzenli olarak gözden geçirin. Düzenli çalışmak, sınavda karşılaşacağınız sorulara daha hazırlıklı olmanızı sağlar. 
    Bir diğer önemli nokta ise, ders çalışma ortamını düzenlemektir. Çalışma ortamınızın sessiz ve düzenli olması, dikkatinizi toplamanızı sağlar. 
    Ayrıca, çalışma süresince sık sık mola vermek de verimliliği artırır. Molalar sırasında hafif egzersizler yaparak veya kısa yürüyüşlere çıkarak zihninizi dinlendirebilirsiniz. 
    Bu sayede, ders çalışırken daha fazla odaklanabilir ve daha verimli çalışabilirsiniz. 
    Düzenli ve planlı çalışmak, LGS sınavında başarılı olmanızı sağlar. Bu nedenle, çalışma programınıza sadık kalın ve her gün belirli saatlerde çalışmayı alışkanlık haline getirin. 
    Bu teknikleri kullanarak LGS sınavına daha iyi hazırlanabilir ve başarılı olabilirsiniz.`,
    image: image8,
  },
];
