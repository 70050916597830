// React essantials
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Bootstrap
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.css";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { AppBar, List, Toolbar, useMediaQuery, useTheme } from "@mui/material";

// @mui material icons
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import PhoneIcon from "@mui/icons-material/Phone";
import ArticleIcon from "@mui/icons-material/Article";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import WhatsAppMessagePopup from "components/Custom/WhatsappPopup";
import Header from "components/Header";
import LinkItem from "components/Custom/LinkItem";
import Item from "components/Custom/Item";
import PhonePopup from "components/Custom/PhonePopup";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import bgImage1 from "assets/images/welcome/welcome-slider-1.jpg";
import bgImage2 from "assets/images/welcome/welcome-slider-2.jpg";
import bgImage3 from "assets/images/welcome/welcome-slider-3.jpg";
import bgImage4 from "assets/images/welcome/welcome-slider-4.jpg";

import sliderFirst from "assets/images/illustrations/slider-first.png";
import sliderSecond from "assets/images/illustrations/slider-second.png";
import sliderThird from "assets/images/illustrations/slider-third.png";
import sliderFourth from "assets/images/illustrations/slider-fourth.png";
import brand from "assets/images/logos/brand-white.png";
import brandDark from "assets/images/logos/brand.png";

// Documents
import { TermsAndConditions } from "documents/termsAndConditions";
import { PrivacyPolicy } from "documents/privacyPolicy";
import { CookiesPolicy } from "documents/CookiesPolicy";
import { DistanceSalesAgreement } from "documents/DistanceSalesAgreement";

function HomePageLayout({ children, isCarousel }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isLgScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const images = [bgImage1, bgImage2, bgImage3, bgImage4];

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const targetPaths = ["/about-us", "/contact-us", "/help", "/blog"];
  const shouldHighlighted =
    scrollPosition > 100 || targetPaths.includes(pathname);

  const renderCaption = (index) => {
    switch (index) {
      case 0:
        return (
          <Carousel.Caption
            style={{
              top: isXsScreen
                ? "-12%"
                : isSmScreen
                ? "-12%"
                : isMdScreen
                ? "-12%"
                : isLgScreen
                ? "8%"
                : "6%",
              bottom: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              spacing={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              maxWidth={"1280px"}
            >
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                flexDirection="column"
                textAlign={isXsScreen ? "center" : "start"}
              >
                <MDBox>
                  <MDTypography
                    variant={isXsScreen ? "h4" : "h1"}
                    mb={3}
                    color="white"
                  >
                    {`LGS Matematiğe Hazır Ol, Başarıyı Yakala!`}
                  </MDTypography>
                  <MDTypography
                    variant={isXsScreen ? "body2" : "body1"}
                    fontWeight="bold"
                    color="white"
                    mb={3}
                  >
                    {`Haftada 4 matematik dersiyle LGS'de tüm konuları detaylıca ele alalım ve matematikteki tüm zorlukları birlikte aşarak zirveye ulaşalım!`}
                  </MDTypography>
                  <MDButton
                    color="teal"
                    variant="contained"
                    sx={{ marginBottom: "3rem" }}
                    size={isXsScreen ? "medium" : "large"}
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                  >
                    İletişime geçin
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDBox sx={{ overflow: "hidden", height: "100%" }}>
                  <img
                    src={sliderFirst}
                    alt="About Us"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      maxWidth: "35rem",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Carousel.Caption>
        );
      case 1:
        return (
          <Carousel.Caption
            style={{
              top: isXsScreen
                ? "-24%"
                : isSmScreen
                ? "-24%"
                : isMdScreen
                ? "-24%"
                : isLgScreen
                ? "0%"
                : "-8%",
              bottom: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              spacing={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              maxWidth={"1280px"}
            >
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                flexDirection="column"
                textAlign={isXsScreen ? "center" : "start"}
              >
                <MDBox>
                  <MDTypography
                    variant={isXsScreen ? "h4" : "h1"}
                    mb={3}
                    mt={10}
                    color="white"
                  >
                    Ücretsiz Deneme Derslerimize Katılmak İster Misiniz?
                  </MDTypography>
                  <MDTypography
                    variant={isXsScreen ? "body2" : "body1"}
                    fontWeight="bold"
                    color="white"
                  >
                    Ücretsiz deneme derslerimize katılarak öğretmenlerimizi
                    tanıyabilir, derslerin işleyişi hakkında fikir sahibi
                    olabilir ve memnun kalmazsanız zorunluluk olmadan
                    vazgeçebilirsiniz.
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={10} lg={6}>
                <MDBox sx={{ overflow: "hidden", height: "100%" }}>
                  <img
                    src={sliderSecond}
                    alt="About Us"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transform: "scaleX(-1)",
                      maxWidth: "35rem",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Carousel.Caption>
        );
      case 2:
        return (
          <Carousel.Caption
            style={{
              top: isXsScreen
                ? "-12%"
                : isSmScreen
                ? "-12%"
                : isMdScreen
                ? "-12%"
                : isLgScreen
                ? "8%"
                : "2%",
              bottom: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              spacing={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              maxWidth={"1280px"}
            >
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                flexDirection="column"
                textAlign={isXsScreen ? "center" : "start"}
              >
                <MDBox>
                  <MDTypography
                    variant={isXsScreen ? "h4" : "h1"}
                    mb={3}
                    color="white"
                  >
                    Canlı Derslerde Tüm Sorularınıza Anında Çözüm!
                  </MDTypography>
                  <MDTypography
                    variant={isXsScreen ? "body2" : "body1"}
                    fontWeight="bold"
                    color="white"
                  >
                    Ders sırasında takıldığınız noktaları anında öğretmenimize
                    sorabilir, canlı ders dışında da Whatsapp üzerinden çözmekte
                    zorlandığınız soruları paylaşabilirsiniz. Öğrencilerimiz her
                    zaman çözüm odaklı bir destek alarak, matematikteki tüm
                    engelleri aşabilir.
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDBox sx={{ overflow: "hidden", height: "100%" }}>
                  <img
                    src={sliderThird}
                    alt="About Us"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      maxWidth: "35rem",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Carousel.Caption>
        );
      case 3:
        return (
          <Carousel.Caption
            style={{
              top: isXsScreen
                ? "-12%"
                : isSmScreen
                ? "-12%"
                : isMdScreen
                ? "-12%"
                : isLgScreen
                ? "10%"
                : "0%",
              bottom: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              spacing={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              maxWidth={"1280px"}
            >
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                flexDirection="column"
                textAlign={isXsScreen ? "center" : "start"}
              >
                <MDBox>
                  <MDTypography
                    variant={isXsScreen ? "h4" : "h1"}
                    mb={3}
                    color="white"
                  >
                    İlerleme Takibi
                  </MDTypography>
                  <MDTypography
                    variant={isXsScreen ? "body2" : "body1"}
                    fontWeight="bold"
                    color="white"
                  >
                    Öğrencinin ilerlemesini, sitemiz üzerindeki grafikler
                    sayesinde hem öğrenci, hem veliler, hem de öğretmenler
                    rahatça takip edebilir. Bu özellik, herkesin gelişimi anlık
                    olarak görmesini ve gerekli önlemleri almasını sağlar.
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDBox sx={{ overflow: "hidden", height: "100%" }}>
                  <img
                    src={sliderFourth}
                    alt="About Us"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transform: "scaleX(-1)",
                      maxWidth: "35rem",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Carousel.Caption>
        );
      default:
        return null;
    }
  };

  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        userSelect: "none",
      }}
    >
      <AppBar sx={{ width: "100%", zIndex: 9999 }}>
        <Container
          fluid
          style={{
            backgroundColor: shouldHighlighted ? "#212529" : "rgba(0,0,0,.4)",
            transition:
              "background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
            height: "3.5rem",
          }}
        >
          <Toolbar disableGutters>
            <MDBox>
              <Header shouldHighlighted={shouldHighlighted} />
            </MDBox>
          </Toolbar>
        </Container>
      </AppBar>
      {!isMdScreen && (
        <AppBar sx={{ width: "100%" }}>
          <Container
            fluid
            style={{
              height: "7rem",
              marginTop: "3.5rem",
              backgroundColor: shouldHighlighted ? "white" : "transparent",
              boxShadow: shouldHighlighted ? "0px 0px 8px 0px gray" : "none",
              transition:
                "background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
            }}
          >
            <Toolbar disableGutters>
              <MDBox
                position={pathname !== "/auth/login" ? "absolute" : "relative"}
                width="100%"
                mb={-6}
              >
                <Container>
                  <MDBox
                    width="100%"
                    display="flex"
                    flexDirection={{ xs: "row", lg: "row" }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDBox
                      display="flex"
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        pathname === "/"
                          ? window.scrollTo(0, 0)
                          : navigate("/");
                      }}
                    >
                      <MDBox
                        component="img"
                        src={shouldHighlighted ? brandDark : brand}
                        alt="Brand"
                        width={shouldHighlighted ? "8rem" : "14rem"}
                        height={shouldHighlighted ? "8rem" : "14rem"}
                        mt={shouldHighlighted ? -1 : 6}
                      />
                    </MDBox>
                    <MDBox display="flex" gap={3} mx={4.5}>
                      <LinkItem
                        text="Ana Sayfa"
                        target="/"
                        shouldHighlighted={shouldHighlighted}
                        isActive={pathname === "/"}
                      />
                      <LinkItem
                        text="Hakkımızda"
                        target="/about-us"
                        shouldHighlighted={shouldHighlighted}
                        isActive={pathname === "/about-us"}
                      />
                      <LinkItem
                        text="Bize Ulaşın"
                        target="/contact-us"
                        shouldHighlighted={shouldHighlighted}
                        isActive={pathname === "/contact-us"}
                      />
                      <LinkItem
                        text="Yardım"
                        target="/help"
                        shouldHighlighted={shouldHighlighted}
                        isActive={pathname === "/help"}
                      />
                      <LinkItem
                        text="Blog"
                        target="/blog"
                        shouldHighlighted={shouldHighlighted}
                        isActive={pathname === "/blog"}
                      />
                    </MDBox>
                  </MDBox>
                </Container>
              </MDBox>
            </Toolbar>
          </Container>
        </AppBar>
      )}
      {isCarousel && (
        <Carousel fade={!isXsScreen} slide={isXsScreen ? false : true}>
          {images.map((image, index) => (
            <Carousel.Item
              key={index}
              interval={3500}
              style={{
                height: isXsScreen ? "45rem" : "55rem",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <img
                className="d-block w-100"
                style={{
                  objectFit: "cover",
                  height: isXsScreen ? "45rem" : "55rem",
                }}
                src={image}
                alt={`Image ${index + 1}`}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage:
                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(25, 255, 255, 0.0))",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "25%",
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {renderCaption(index)}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
      <MDBox px={0} pt={1} flex="1">
        {children}
      </MDBox>
      {!isXsScreen && !isMdScreen && (
        <>
          {/* <PhonePopup /> */}
          <WhatsAppMessagePopup />
        </>
      )}
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        bottom={0}
        sx={{
          flexShrink: 0,
          backgroundColor: "#212529",
          minHeight: isXsScreen ? "16rem" : "24rem",
          userSelect: "none",
        }}
      >
        <Grid item xs={12} lg={10} xl={8}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={6}
              md={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <List>
                <Item
                  icon={<InfoIcon color="white" />}
                  text={"Hakkımızda"}
                  target="/about-us"
                  isXsScreen={isXsScreen}
                />
                <Item
                  icon={<PhoneIcon color="white" />}
                  text={"Bize Ulaşın"}
                  target="/contact-us"
                  isXsScreen={isXsScreen}
                />
                <Item
                  icon={<HelpIcon color="white" />}
                  text={"Yardım"}
                  target="/help"
                  isXsScreen={isXsScreen}
                />
                <Item
                  icon={<StickyNote2Icon color="white" />}
                  text={"Blog"}
                  target="/blog"
                  isXsScreen={isXsScreen}
                />
              </List>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <List>
                <Item
                  icon={<ArticleIcon color="white" />}
                  text={"Şartlar ve Koşullar"}
                  isXsScreen={isXsScreen}
                >
                  <TermsAndConditions />
                </Item>
                <Item
                  icon={<ArticleIcon color="white" />}
                  text={"Gizlilik Politikası"}
                  isXsScreen={isXsScreen}
                >
                  <PrivacyPolicy />
                </Item>
                <Item
                  icon={<ArticleIcon color="white" />}
                  text={"Çerez Politikası"}
                  isXsScreen={isXsScreen}
                >
                  <CookiesPolicy />
                </Item>
                <Item
                  icon={<ArticleIcon color="white" />}
                  text={"Mesafeli Satış Sözleşmesi"}
                  isXsScreen={isXsScreen}
                >
                  <DistanceSalesAgreement />
                </Item>
              </List>
            </Grid>
            {!isXsScreen && !isSmScreen && (
              <Grid
                mt={1}
                item
                xs={12}
                md={3.5}
                mx={1}
                display="flex"
                justifyContent="flex-end"
              >
                <List>
                  <MDTypography variant="overline" color="white">
                    &#xA9; {new Date().getFullYear()} Dijital Derslik
                  </MDTypography>
                </List>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Typechecking props for the BasicLayout
HomePageLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default HomePageLayout;
