import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Chip, Paper } from "@mui/material";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

export default function Dropzone(props) {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUploadedFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (props.onFilesAdded) {
        props.onFilesAdded(acceptedFiles);
      }
    },
    [props]
  );

  const onDelete = (index) => {
    const fileToBeDeleted = uploadedFiles[index];

    setUploadedFiles((currentUploadedFiles) =>
      currentUploadedFiles.filter((_, i) => i !== index)
    );

    URL.revokeObjectURL(fileToBeDeleted.preview);

    if (props.onDelete) {
      props.onDelete(index);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: props.multiple,
    accept: props.accept,
  });

  return (
    <MDBox display="flex" justifyContent="center">
      {uploadedFiles.length === 0 && (
        <Paper
          {...getRootProps()}
          elevation={0}
          sx={{
            width: "99%",
            border: "1px dashed",
            borderRadius: "16px",
            borderColor: isDragActive ? "secondary.main" : "grey.500",
            bgcolor: isDragActive ? "secondary.light" : "primary.secondary",
            p: 6,
            textAlign: "center",
            cursor: "pointer",
            color: isDragActive ? "primary.contrastText" : "grey.800",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <MDBox>
              <MDTypography color="white" variant="button" fontWeight="bold">
                Dosyaları buraya bırakın...
              </MDTypography>
            </MDBox>
          ) : (
            <MDTypography color="text" variant="button" fontWeight="bold">
              Dosyayı buraya sürükleyip bırakın veya dosya seçmek için tıklayın
            </MDTypography>
          )}
        </Paper>
      )}

      {uploadedFiles.length > 0 && (
        <MDBox display="flex" width="100%">
          {/* <MDTypography mx={1} variant="h6" gutterBottom fontWeight="medium">
            Yüklenen Dosyalar
          </MDTypography> */}
          {uploadedFiles.map((file, index) => (
            <Chip
              size="medium"
              key={index}
              label={file.name}
              onDelete={() => onDelete(index, file)}
              color="secondary"
              variant="outlined"
              sx={{ m: 0.5 }}
            />
          ))}
        </MDBox>
      )}
    </MDBox>
  );
}
