import { createSlice } from "@reduxjs/toolkit";
import { deleteContact, fetchContacts, sendContactForm } from "./services";

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contacts: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Send Contact Form
      .addCase(sendContactForm.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendContactForm.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Optional: Handle any response data if needed
      })
      .addCase(sendContactForm.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch Contacts
      .addCase(fetchContacts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contacts = action.payload;
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete Contact
      .addCase(deleteContact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contacts = state.contacts.filter(
          (contact) => contact.id !== action.payload.contact.id
        );
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default contactSlice.reducer;
