// React essantials
import { useEffect, useState } from "react";

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Videos from "layouts/pages/videos";
import Lessons from "layouts/pages/lessons";
import Lesson from "layouts/pages/lesson";
import Guidance from "layouts/pages/guidance";
import UserProfile from "layouts/pages/user-profile";
import UserManagement from "layouts/pages/user-management";
import UserDetail from "layouts/pages/user-detail";
import Payment from "layouts/pages/payment";
import Quizzes from "layouts/pages/quizzes";
import QuizPage from "layouts/pages/quiz";
import Schedule from "layouts/pages/schedule";
import ProgressTracking from "layouts/pages/progress-tracking";
import ErrorPage from "layouts/pages/error-pages";
import GuidanceVideos from "layouts/pages/guidance-videos";
import ManagementPanel from "layouts/pages/management-panel";
import LiveCast from "layouts/pages/live-cast";

// @mui icons
import Icon from "@mui/material/Icon";

// React Router Dom
import { Navigate, useLocation } from "react-router-dom";

// Services
import authService from "services/auth-service";
import TrialExams from "layouts/pages/trial-exams";

const ProtectedRoute = ({
  component: Component,
  allowedRoles,
  allowedIfNotActive,
}) => {
  const location = useLocation();
  const [user, setUser] = useState({
    role: null,
    isActive: null,
    isLoading: true,
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await authService.getProfile();
        setUser({
          role: response?.data.attributes.role,
          isActive: response?.data.attributes.isActive,
          isLoading: false,
        });
      } catch (error) {
        console.error("Profile fetch failed:", error);
      }
    };

    fetchProfile();
  }, []);

  if (user.isLoading) {
    return; // Loading indicator can be customized
  }

  if (!user.isActive && !allowedIfNotActive) {
    // Kullanıcı aktif değilse ve sayfaya aktif olmayanların erişimi yoksa login sayfasına yönlendir
    return <Navigate to="/403" state={{ from: location }} replace />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role)) {
    // Kullanıcının rolü yetkili roller arasında değilse yine login sayfasına yönlendir
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return <Component />;
};

const routes = [
  {
    type: "collapse",
    name: "Ana Sayfa",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: (
      <ProtectedRoute component={Dashboard} allowedIfNotActive={true} />
    ),
  },
  {
    type: "examples",
    name: "Yönetim Paneli",
    key: "management-panel",
    icon: <Icon fontSize="small">tune</Icon>,
    route: "/management-panel",
    roles: ["admin", "superuser", "teacher"],
    component: (
      <ProtectedRoute
        component={ManagementPanel}
        allowedRoles={["admin", "superuser", "teacher"]}
        allowedIfNotActive={false}
      />
    ),
  },
  {
    type: "examples",
    name: "Kullanıcı Profili",
    key: "user-profile",
    icon: <Icon fontSize="small">account_box</Icon>,
    route: "/user-profile",
    component: (
      <ProtectedRoute component={UserProfile} allowedIfNotActive={true} />
    ),
  },
  {
    type: "examples",
    name: "Kullanıcı Yönetimi",
    key: "user-management",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/user-management",
    roles: ["admin", "superuser", "teacher"],
    component: (
      <ProtectedRoute
        component={UserManagement}
        allowedRoles={["admin", "superuser", "teacher"]}
        allowedIfNotActive={false}
      />
    ),
  },
  {
    type: "collapse",
    name: "İlerleme Takibi",
    key: "progress-tracking",
    icon: <Icon fontSize="small">emoji_events</Icon>,
    route: "/progress-tracking",
    roles: ["student", "admin", "superuser", "teacher"],
    component: (
      <ProtectedRoute
        component={ProgressTracking}
        allowedIfNotActive={false}
        allowedRoles={["student", "admin", "superuser", "teacher"]}
      />
    ),
  },
  {
    type: "collapse",
    name: "Videolar",
    key: "videos",
    icon: <Icon fontSize="small">ondemand_video</Icon>,
    route: "/videos",
    roles: ["admin", "superuser", "teacher"],
    component: (
      <ProtectedRoute
        component={Videos}
        allowedRoles={["admin", "superuser", "teacher"]}
        allowedIfNotActive={false}
      />
    ),
  },
  {
    type: "collapse",
    name: "Testler",
    key: "quizzes",
    icon: <Icon fontSize="small">quiz_icon</Icon>,
    route: "/quizzes",
    roles: ["admin", "superuser", "teacher"],
    component: (
      <ProtectedRoute
        component={Quizzes}
        allowedRoles={["admin", "superuser", "teacher"]}
        allowedIfNotActive={false}
      />
    ),
  },
  {
    type: "collapse",
    name: "Konu Anlatımları",
    key: "lessons",
    icon: <Icon fontSize="small">video_library</Icon>,
    route: "/lessons",
    component: (
      <ProtectedRoute
        allowedRoles={["admin", "superuser", "teacher", "student"]}
        component={Lessons}
        allowedIfNotActive={false}
      />
    ),
  },
  {
    type: "collapse",
    name: "Rehberlik Videoları",
    key: "guidance-videos",
    icon: <Icon fontSize="small">psychology</Icon>,
    route: "/guidance-videos",
    component: (
      <ProtectedRoute
        allowedRoles={["admin", "superuser", "teacher", "student"]}
        component={GuidanceVideos}
        allowedIfNotActive={false}
      />
    ),
  },
  // {
  //   type: "collapse",
  //   name: "Deneme Sınavları",
  //   key: "trial-exams",
  //   icon: <Icon fontSize="small">article</Icon>,
  //   route: "/trial-exams",
  //   component: (
  //     <ProtectedRoute component={TrialExams} allowedIfNotActive={false} />
  //   ),
  // },
  // {
  //   type: "collapse",
  //   name: "Canlı Yayın",
  //   key: "live-cast",
  //   icon: <Icon fontSize="small">live_tv</Icon>,
  //   route: "/live-cast",
  //   component: (
  //     <ProtectedRoute component={LiveCast} allowedIfNotActive={false} />
  //   ),
  // },
  // {
  //   type: "collapse",
  //   name: "Aylık Program",
  //   key: "monthly-program",
  //   icon: <Icon fontSize="small">calendar_month</Icon>,
  //   route: "/monthly-program",
  //   component: (
  //     <ProtectedRoute
  //       component={Schedule}
  //       allowedIfNotActive={false}
  //       allowedRoles={["admin", "superuser", "teacher", "student"]}
  //     />
  //   ),
  // },
  {
    type: "collapse",
    name: "Ödeme Ekranı",
    key: "payment",
    icon: <Icon fontSize="small">payment</Icon>,
    route: "/payment",
    roles: ["admin", "superuser", "teacher", "student"],
    component: <ProtectedRoute component={Payment} allowedIfNotActive={true} />,
  },
  {
    type: "item",
    name: "Ders",
    key: "lesson",
    icon: <Icon fontSize="small">video_library</Icon>,
    route: "/lessons/:subject",
    component: <ProtectedRoute component={Lesson} allowedIfNotActive={false} />,
  },
  {
    type: "item",
    name: "Quiz",
    key: "quiz",
    route: "/quiz/:subject",
    component: (
      <ProtectedRoute component={QuizPage} allowedIfNotActive={false} />
    ),
  },
  {
    type: "item",
    name: "Ders",
    key: "guidance",
    icon: <Icon fontSize="small">video_library</Icon>,
    route: "/guidance-videos/:subject",
    component: (
      <ProtectedRoute component={Guidance} allowedIfNotActive={false} />
    ),
  },
  {
    type: "item",
    name: "Kullanıcı Detay",
    key: "user-detail",
    route: "/user-management/user-detail/:userId",
    roles: ["admin", "superuser", "teacher"],
    component: (
      <ProtectedRoute
        component={UserDetail}
        allowedRoles={["admin", "superuser", "teacher"]}
        allowedIfNotActive={false}
      />
    ),
  },
  {
    type: "item",
    name: "403",
    key: "403",
    route: "/403",
    component: <ErrorPage />,
  },
  {
    type: "item",
    name: "404",
    key: "404",
    route: "/404",
    component: <ErrorPage />,
  },
];

export default routes;
