import { useState, useEffect, useContext } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import LightModeIcon from "@mui/icons-material/LightMode";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import AuthService from "services/auth-service";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode,
} from "context";
import MDButton from "components/MDButton";
import { AuthContext } from "context";
import { logout } from "slices/Auth/authSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import AlertDialog from "components/Custom/Dialog";
import MDTypography from "components/MDTypography";
import { useMediaQuery, useTheme } from "@mui/material";
import TopNavbar from "../TopNavbar";
import routes from "routes";
import { Logout } from "@mui/icons-material";
import { findUserRole } from "helpers";

function DashboardNavbar({ absolute, light, isMini }) {
  const dispatchAction = useDispatch();
  const authContext = useContext(AuthContext);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [navbarType, setNavbarType] = useState(fixedNavbar ? "sticky" : "static");
  const [openMenu, setOpenMenu] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg")); // Büyük ekran kontrolü

  const userRole = findUserRole();

  const filteredRoutes = routes.filter((route) => {
    return !route.roles || route.roles.includes(userRole);
  });

  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // Rota isimlerini Türkçeleştirmek için eşleme fonksiyonu
  const routeMapping = {
    dashboard: "Ana Sayfa",
    "user-profile": "Kullanıcı Profili",
    "management-panel": "Yönetim Paneli",
    quizzes: "Testler",
    videos: "Videolar",
    "progress-tracking": "İlerleme Takibi",
    "user-management": "Kullanıcı Yönetimi",
    payment: "Ödeme Ekranı",
    lessons: "Konu Anlatımları",
    "guidance-videos": "Rehberlik Videoları",
    "trial-exams": "Deneme Sınavları",
    "live-cast": "Canlı Yayın",
    "monthly-program": "Aylık Program",
    lesson: "Ders",
    quiz: "Quiz",
    guidance: "Rehberlik Dersi",
    "user-detail": "Kullanıcı Detay",
    403: "Erişim Engellendi",
    404: "Sayfa Bulunamadı",
  };

  const currentRoute = route[route.length - 1];
  const translatedRoute = routeMapping[currentRoute] || currentRoute;

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const handleLogOut = () => {
    AuthService.logout();
    dispatchAction(logout());
    Cookies.remove("token");
    Cookies.remove("varaAnimationPlayed");
    navigate("/auth/login");
  };

  return (
    <AppBar
      color="inherit"
      position={absolute ? "absolute" : navbarType}
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {!isXsScreen && (
          <MDBox
            color="inherit"
            mb={{ xs: 0, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon="home"
              title={translatedRoute} // Türkçeleştirilmiş rotayı kullan
              route={route}
              light={light}
            />
            {isLgScreen && <TopNavbar routes={filteredRoutes} />}{" "}
            {/* Sadece büyük ekranlarda */}
          </MDBox>
        )}
        {isMini ? null : (
          <MDBox
            sx={(theme) => ({
              ...navbarRow(theme, false),
              justifyContent: isXsScreen ? "space-between" : "stretch",
            })}
          >
            <MDBox
              display="flex"
              alignItems="center"
              color={light ? "white" : "inherit"}
            >
              {!pathname.startsWith("/lessons/") && !isLgScreen && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu" : "menu_open"}
                  </Icon>
                </IconButton>
              )}
              {renderMenu()}
            </MDBox>
            <MDBox ml={1} sx={{ display: "flex", alignItems: "center" }}>
              {/* <MDButton
                variant="gradient"
                color="dark"
                fullWidth
                type="button"
                size="small"
                onClick={() => setOpenDialog(true)}
              >
                Çıkış Yap
              </MDButton> */}
              <IconButton onClick={() => setOpenDialog(true)}>
                <Logout />
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <AlertDialog
        width="sm"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleLogOut}
        title={"Oturumu Sonlandır"}
      >
        <MDTypography color="text" variant="body2" mx={1}>
          Bu cihazda açık olan oturumlarınız sonlandırılacaktır. Çıkış yapmak
          istediğinizden emin misiniz?
        </MDTypography>
      </AlertDialog>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
