import { TextField, styled } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: pxToRem(20),
  },
  "& .MuiInputBase-root": {
    fontSize: "0.875rem",
    padding: "8px 14px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "4px 14px",
  },
  width: "15rem",
});
