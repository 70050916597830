// Material Dashboard 2 React Base Styles
import borders from "assets/theme/base/borders";

const { borderRadius } = borders;

const cardMedia = {
  styleOverrides: {
    root: {
      borderTopLeftRadius: borderRadius.xxl,
      borderTopRightRadius: borderRadius.xxl,
      margin: 0,
    },

    media: {
      width: "auto",
    },
  },
};

export default cardMedia;
