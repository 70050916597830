import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function PackageCard({ title, description, value, isSelected, onSelect }) {
  return (
    <DefaultInfoCard
      color={isSelected ? "orange" : "orange"}
      icon={isSelected ? "shopping_cart" : "shopping_cart"}
      title={title}
      description={description}
      value={value}
      bgColor={{
        main: isSelected ? "#028391" : "#028391",
        state: isSelected ? "#57A6A1" : "#57A6A1",
      }}
      isSelected={isSelected}
    >
      {/* <MDBox width="100%" display="flex" justifyContent="center">
        <MDButton
          variant="gradient"
          size="small"
          color={isSelected ? "dark" : "secondary"}
          sx={{
            width: "100%",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
          onClick={onSelect}
        >
          {isSelected ? "Seçildi" : "Seç"}
        </MDButton>
      </MDBox> */}
    </DefaultInfoCard>
  );
}

export default PackageCard;
