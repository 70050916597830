import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const response = await axios.get("/users");
  return response.data;
});

export const updateIsActive = createAsyncThunk(
  "users/updateIsActive",
  async ({ id, isActive }, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`/users/user-update`, {
        isActive,
        userId: id,
      });

      if (res.status !== 200) {
        throw new Error("Kullanıcı durumu başarıyla güncellendi");
      }
      const response = await axios.get("/users");
      return response.data;
    } catch (error) {
      console.error(
        "Kullanıcı durumunu güncelleme işlemi sırasında hata meydana geldi:",
        error
      );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (userId, { rejectWithValue }) => {
    try {
      await axios.delete(`/users/user-delete/${userId}`);

      const response = await axios.get("/users");
      return response.data;
    } catch (error) {
      console.error(
        "Kullanıcı silme işlemi sırasında hata meydana geldi:",
        error
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.patch("/users/reset-password", { userId });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);