import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import profilePicture from "assets/images/other/default-pp.png";
import MDTypography from "components/MDTypography";
import { Divider, Tooltip } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import backgroundImage from "assets/images/illustrations/award.png";
import { getRandomAvatar } from "helpers";

const ScoreCard = ({ users }) => {
  const [userAvatars, setUserAvatars] = useState({});

  useEffect(() => {
    // Assign avatars to users only once, when the component first renders
    const avatarsMap = {};
    users.forEach((user) => {
      if (!userAvatars[user.id]) {
        avatarsMap[user.id] = getRandomAvatar(user.gender);
      }
    });
    setUserAvatars(avatarsMap);
  }, [users]);

  // Sort users by score in descending order
  const sortedUsers = [...users].sort((a, b) => b.score - a.score);

  return (
    <Card
      sx={{
        height: "100%",
        maxHeight: "30rem",
        display: "flex",
        flexDirection: "column",
        userSelect: "none",
      }}
    >
      <Tooltip
        title={
          "Konu testlerinde doğru yaptığınız her soru için 10 puan kazanırsınız!"
        }
        placement="top"
      >
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="xl"
          px={3}
          py={3}
        >
          <MDTypography variant="h6" mx={1} color="dark">
            Skor Tablosu
          </MDTypography>
          <EmojiEventsIcon
            fontSize="large"
            sx={{ marginLeft: 0.5, color: "#FFAF45" }}
          />
        </MDBox>
      </Tooltip>
      <CardContent
        sx={{
          flex: 1,
          overflow: "auto",
          position: "relative",
        }}
      >
        {/* Arka plan resmi için ayrı bir box */}
        <MDBox
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            opacity: 0.2,
          }}
        />

        {sortedUsers.length === 0 && (
          <MDBox display="flex" textAlign="flex-start" mt={1}>
            <MDTypography color="text" variant="overline">
              Listelenecek kullanıcı bulunamadı
            </MDTypography>
          </MDBox>
        )}
        <Grid container spacing={1} mt={0.5}>
          {sortedUsers.map((user, index) => (
            <Grid
              item
              xs={12}
              key={user.id}
              sx={{
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.01)",
                },
              }}
            >
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
              >
                <MDBox display="flex" alignItems="center">
                  <MDAvatar
                    src={
                      user?.profileImage ||
                      user?.profile_image ||
                      userAvatars[user.id] ||
                      profilePicture // Use pre-assigned avatar or default
                    }
                    alt="profile-image"
                    size={"md"}
                    shadow="lg"
                  />
                  <MDTypography variant="overline" fontWeight="bold" mx={2}>
                    {user.name}
                  </MDTypography>
                </MDBox>
                <MDTypography variant="overline" fontWeight="bold">
                  {user.score} Puan
                </MDTypography>
              </MDBox>
              {index < sortedUsers.length - 1 && (
                <Divider sx={{ margin: ".5rem 0 0 0" }} />
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ScoreCard;
