// React essentials
import React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function FormFieldTitle({ children }) {
  return (
    <MDBox mb={0.5}>
      <MDTypography variant="caption" fontWeight="bold" ml={1.5}>
        {children}
      </MDTypography>
    </MDBox>
  );
}
