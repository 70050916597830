import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function AlertDialog({
  open,
  onClose,
  onSubmit,
  title,
  width,
  disabled,
  children,
  hasNoAction,
  actionText,
  fullScreen,
}) {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <React.Fragment>
      <Dialog
        maxWidth={width}
        fullWidth
        open={open}
        fullScreen={fullScreen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            margin: isXsScreen ? 1 : 2,
            marginBottom: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          <MDTypography variant={isXsScreen ? "h6" : "h5"}>
            {title}
          </MDTypography>
          <MDBox>
            <IconButton onClick={onClose}>
              <CloseIcon color="dark" />
            </IconButton>
          </MDBox>
        </DialogTitle>
        <DialogContent sx={{ paddingX: isXsScreen ? 2 : 3 }}>
          <MDTypography variant={isXsScreen ? "button" : "body2"}>
            {children}
          </MDTypography>
        </DialogContent>
        {!hasNoAction && (
          <DialogActions sx={{ marginX: 2, marginY: 1 }}>
            <MDButton
              size={isXsScreen ? "small" : "medium"}
              color="secondary"
              variant="outlined"
              onClick={onClose}
            >
              Vazgeç
            </MDButton>
            <MDButton
              color="info"
              variant="gradient"
              onClick={onSubmit}
              autoFocus
              disabled={disabled}
              size={isXsScreen ? "small" : "medium"}
            >
              {actionText ? actionText : "Onayla"}
            </MDButton>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}

AlertDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
  title: "Default Title",
  width: "sm",
};
