import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const updateProfilePicture = createAsyncThunk(
  "me/updateProfilePicture",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.patch("/me/profile-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
