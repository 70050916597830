export const accordionData = [
    {
      title: "Dijital Derslik olarak başarımızın sırrı nedir?",
      text: "Başarının sırrı, doğru materyallerle düzenli bir çalışma programını takip etmektir. Dijital Derslik olarak öğrencilerin konuları kavrayabilmeleri için yapılandırılmış ders programları, kapsamlı video kütüphanesi ve kişiye özel takiple matematik öğrenimine destek oluyoruz. Çeşitli quiz ve alıştırmalarla öğrendiklerini pekiştirmeleri için öğrencileri motive ediyoruz.",
    },
    {
      title: "Neden Dijital Derslik’i tercih etmeliyiz?",
      text: "Dijital Derslik, öğrencilere özel hazırlanmış ders planlarıyla öğrencileri hedeflerine ulaşmaları için destekler. Canlı yayınlar, geniş kapsamlı video kütüphanesi, quiz ve diğer araçlarla öğrencilere esnek ve kişiselleştirilmiş bir öğrenme deneyimi sunar. Alanında uzman eğitim kadromuz ve psikolojik destek hizmetimizle, öğrencilerin gelişimlerini en iyi şekilde destekleriz.",
    },
    {
      title: "Dijital Derslik’in eğitim-öğretime bakışı nedir?",
      text: "Dijital Derslik, öğrencinin kişisel ihtiyaçlarına uygun, odaklı ve verimli bir eğitim anlayışını benimser. Matematik bilgisinin temelden en üst seviyeye kadar yapılandırılmasını hedefleriz. Öğrencilerimize bireysel ilgi gösterip, psikolojik destekle onların kendilerine güvenlerini artırmayı amaçlarız.",
    },
    {
      title: "Dijital Derslik hangi yaş grubundaki öğrencilere hitap ediyor?",
      text: "Dijital Derslik, özellikle ortaokul 8. sınıf öğrencilerine yönelik eğitim sunar. Bu yaş grubundaki öğrenciler için temel matematik bilgisi ve LGS sınavına hazırlık programları sağlanır. Bununla birlikte, farklı seviyelerdeki öğrenciler için de materyaller sunarak, onların eksiklerini gidermelerine yardımcı oluruz.",
    },
    {
      title: "Canlı dersler nasıl yürütülüyor?",
      text: "Canlı dersler, eğitimcilerimiz tarafından belirli saatlerde yapılır ve tüm öğrenciler katılım gösterebilir. Ders sırasında öğrenciler sorularını sorabilir ve öğretmenle doğrudan etkileşime geçebilirler.",
    },
    {
      title: "Quizler ve testler ne sıklıkta yapılıyor?",
      text: "Quiz ve testler, her ana konu sonunda öğrencilerin bilgilerini pekiştirmeleri için düzenli olarak yapılır. Öğrenciler, quiz sonuçlarını platform üzerinden takip edebilir ve hangi alanlarda eksik olduklarını öğrenebilirler. Bu sonuçlar, öğrencinin ilerlemesine göre güncellenir.",
    },
    {
      title: "Öğrenciler gelişimlerini nasıl takip edebilirler?",
      text: "Dijital Derslik platformunda, öğrenciler için kişisel bir ilerleme tablosu oluşturulur. Bu tablo, öğrencinin aldığı dersleri, tamamladığı quizleri ve test sonuçlarını gösterir. Öğrenciler, hangi konularda iyi olduklarını ve hangi konularda daha fazla çalışmaları gerektiğini buradan rahatça görebilirler.",
    },
  ];