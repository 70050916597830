/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import linearGradient from "assets/theme/functions/linearGradient";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function DefaultInfoCard({
  color,
  icon,
  title,
  description,
  value,
  bgColor,
  isSelected,
  children,
}) {
  function PriceDisplay({ value, isSelected }) {
    const renderPrice = () => {
      if (value === "19.999₺") {
        return (
          <MDTypography
            variant="body1"
            fontWeight="regular"
            color={isSelected ? "light" : "light"}
          >
            <span
              style={{
                textDecoration: "line-through",
                marginRight: "5px",
                textDecorationThickness: "1px", // Çizgi kalınlığı
                textDecorationColor: "rgba(255, 255, 255, 0.75)", // Çizgi saydamlığı
              }}
            >
              24.999₺
            </span>
            <MDTypography variant="h3" color="light">
              17.999₺
            </MDTypography>
          </MDTypography>
        );
      }
      if (value === "5.999₺") {
        return (
          <MDTypography
            variant="body1"
            fontWeight="regular"
            color={isSelected ? "light" : "light"}
          >
            <span
              style={{
                textDecoration: "line-through",
                marginRight: "5px",
                textDecorationThickness: "1px", // Çizgi kalınlığı
                textDecorationColor: "rgba(255, 255, 255, 0.75)", // Çizgi saydamlığı
              }}
            >
              5.999₺
            </span>
            <MDTypography variant="h3" color="light">
              3.999₺
            </MDTypography>
          </MDTypography>
        );
      } else {
        return (
          <MDTypography
            variant="h5"
            fontWeight="medium"
            color={isSelected ? "light" : "light"}
          >
            {value}
          </MDTypography>
        );
      }
    };

    return <>{renderPrice()}</>;
  }

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <MDBox
        bgColor={linearGradient(bgColor.state, bgColor.main)}
        sx={{
          height: "100%",
          borderRadius: "16px",
        }}
      >
        <MDBox p={2} mx={3} display="flex" justifyContent="center">
          <MDBox
            display="grid"
            justifyContent="center"
            alignItems="center"
            bgColor={color}
            color="white"
            width="3rem"
            height="3rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"
          >
            <Icon fontSize="default">{icon}</Icon>
          </MDBox>
        </MDBox>
        <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
          <MDTypography
            variant="body2"
            textTransform="capitalize"
            color={isSelected ? "light" : "light"}
            mb={0.5}
          >
            {value === "19.999₺" ? "Paket 1" : "Paket 2"}
          </MDTypography>
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
            color={isSelected ? "light" : "light"}
          >
            {title}
          </MDTypography>
          {description && (
            <MDTypography
              variant="caption"
              color={isSelected ? "light" : "light"}
              fontWeight="regular"
            >
              {description}
            </MDTypography>
          )}
          {description && !value ? null : (
            <Divider light={true} sx={{ opacity: "1" }} />
          )}
          {value && <PriceDisplay value={value} />}
        </MDBox>
      </MDBox>
      {children}
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DefaultInfoCard;
