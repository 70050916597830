import { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import BasicLayoutLanding from "examples/LayoutContainers/BasicLayoutLanding";

// Images
import bgImage from "assets/images/other/reset-password.jpeg";
import authService from "services/auth-service";

function ForgotPassword() {
  const [disabled, setDisabled] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [notification, setNotification] = useState(false);
  const [input, setEmail] = useState({
    email: "",
  });
  const [error, setError] = useState({
    err: false,
    textError: "",
  });

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
  }, []);

  const changeHandler = (e) => {
    setEmail({
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (
      input.email.trim().length === 0 ||
      !input.email.trim().match(mailFormat)
    ) {
      setError({ err: true, textError: "Geçerli bir e-posta girin" });
      setDisabled(false);
      return;
    }

    // somthing not right with the data
    const myData = {
      data: {
        type: "password-forgot",
        attributes: {
          redirect_url: `${process.env.REACT_APP_URL}/auth/reset-password`,
          ...input,
        },
      },
    };

    try {
      if (isDemo == false) {
        const response = await authService.forgotPassword(myData);
        setError({ err: false, textError: "" });
      }
      setNotification(true);
    } catch (err) {
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        if (err.errors.hasOwnProperty("email")) {
          setError({ err: true, textError: err.errors.email[0] });
        } else {
          setError({ err: true, textError: "An error occured" });
        }
      }
      return null;
    } finally {
      setDisabled(false);
    }
  };

  return (
    <BasicLayoutLanding coverHeight="30vh" image={bgImage}>
      <MDBox
        // variant="gradient"
        // bgColor="info"
        borderRadius="lg"
        // coloredShadow="success"
        mx={2}
        pt={2}
        textAlign="center"
      >
        <MDTypography
          variant="h4"
          fontWeight="medium"
          sx={{ color: "#17171" }}
          mt={1}
        >
          Şifreyi sıfırla
        </MDTypography>
        <MDTypography
          display="block"
          variant="button"
          sx={{ color: "#17171" }}
          my={1}
        >
          En fazla 60 saniye içinde e-posta alacaksınız
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={5}>
        <MDBox
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
        >
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="E-posta"
              variant="standard"
              fullWidth
              value={input.email}
              name="email"
              onChange={changeHandler}
              error={error.err}
            />
          </MDBox>
          {error.err && (
            <MDTypography variant="caption" color="error" fontWeight="light">
              {error.textError}
            </MDTypography>
          )}
          <MDBox mt={4} mb={1}>
            <MDButton
              disabled={disabled}
              variant="gradient"
              color="info"
              fullWidth
              type="submit"
            >
              Sıfırla
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      {notification && (
        <MDAlert color="info" mt="20px" dismissible>
          <MDTypography variant="body2" color="white">
            {isDemo
              ? "You can't update the password in the demo version"
              : "Şifrenizi sıfırlamak için lütfen e-postanızı kontrol edin"}
          </MDTypography>
        </MDAlert>
      )}
    </BasicLayoutLanding>
  );
}

export default ForgotPassword;
