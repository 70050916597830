import { createSlice } from "@reduxjs/toolkit";
import { fetchSchedules, uploadSchedule } from "./services";

const scheduleSlice = createSlice({
  name: "schedule",
  initialState: {
    data: [],
    uploadSchedule: {
      status: "idle",
      error: null,
    },
    fetchSchedules: {
      status: "idle",
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadSchedule.pending, (state) => {
        state.uploadSchedule.status = "loading";
      })
      .addCase(uploadSchedule.fulfilled, (state, action) => {
        state.uploadSchedule.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(uploadSchedule.rejected, (state, action) => {
        state.uploadSchedule.status = "failed";
        state.uploadSchedule.error = action.payload;
      })

      .addCase(fetchSchedules.pending, (state) => {
        state.fetchSchedules.status = "loading";
      })
      .addCase(fetchSchedules.fulfilled, (state, action) => {
        state.fetchSchedules.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchSchedules.rejected, (state, action) => {
        state.fetchSchedules.status = "failed";
        state.fetchSchedules.error = action.payload;
      });
  },
});

export default scheduleSlice.reducer;
