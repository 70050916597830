import React from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import MDBox from "components/MDBox";
import { Card, useMediaQuery, useTheme } from "@mui/material";
import MDTypography from "components/MDTypography";
import linearGradient from "assets/theme/functions/linearGradient";

export default function CountdownComponent() {
  const theme = useTheme();

  // useMediaQuery kullanarak farklı ekran boyutlarını kontrol ediyoruz
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  // Ekran boyutuna göre farklı stiller tanımlıyoruz
  let digitBlockStyle;
  if (isXSmallScreen) {
    digitBlockStyle = { width: 20, height: 50, fontSize: 20 };
  }else if (isSmallScreen) {
    digitBlockStyle = { width: 30, height: 50, fontSize: 30 };
  } else if (isMediumScreen) {
    digitBlockStyle = { width: 35, height: 60, fontSize: 35 };
  } else if (isLargeScreen) {
    digitBlockStyle = { width: 50, height: 70, fontSize: 40 };
  }

  const targetDate = new Date("2025-06-01T09:00:00").getTime(); // 1 Haziran 2025, 09:00 (sabah)

  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "20px",
        borderRadius: "12px",
      }}
    >
      <FlipClockCountdown
        to={targetDate}
        labels={["Gün", "Saat", "Dakika", "Saniye"]} // Etiketleri Türkçe olarak değiştirdik
        digitBlockStyle={digitBlockStyle}
        dividerStyle={{ color: "#FF5F00", height: 2 }}
        labelStyle={{ color: "black", fontSize: 16, marginTop: 10 }}
      />
    </MDBox>
  );
}
