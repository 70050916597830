import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function TopNavbar({ routes = [] }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);

  const handleMenuOpen = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(menu);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  // Hem MDBox hem de Menu'dan ayrıldığınızda çalışacak olan mouse leave olayını düzenleyin
  const handleMouseLeave = () => {
    handleMenuClose();
  };

  // Sayfalar menüsünde gösterilecek öğeler (İlerleme Takibi ve Ödeme Ekranı hariç)
  const filteredPagesRoutes = routes.filter(
    (route) =>
      route.type === "collapse" &&
      route.key !== "dashboard" &&
      route.key !== "user-profile" &&
      route.key !== "management-panel" &&
      route.key !== "user-management" &&
      route.key !== "progress-tracking" && // İlerleme Takibi hariç
      route.key !== "payment" && // Ödeme Ekranı hariç
      route.key !== "videos" && // Ödeme Ekranı hariç
      route.key !== "quizzes" // Ödeme Ekranı hariç
  );

  // İlerleme Takibi ve Ödeme Ekranı ayrı birer menü öğesi olacak
  const progressTrackingRoute = routes.find(
    (route) => route.key === "progress-tracking"
  );
  const paymentRoute = routes.find((route) => route.key === "payment");

  // Yönetim menüsü için kontrol
  const hasManagementRoutes = routes.some(
    (route) =>
      route.key === "user-management" || route.key === "management-panel"
  );

  const renderMenuItems = (groupedRoutes) =>
    groupedRoutes.map(({ name, route }) => (
      <MenuItem
        key={name}
        onClick={() => navigate(route)}
        sx={{
          color: "black",
          padding: "10px 20px",
          fontSize: "0.875rem",
        }}
      >
        {name}
      </MenuItem>
    ));

  return (
    <MDBox mx={5} sx={{ display: "flex", gap: 5, alignItems: "center" }}>
      {/* Dashboard Link */}
      <NavLink
        to="/dashboard"
        style={{
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <MDTypography
          variant="button"
          color="dark"
          component="span"
          fontWeight="regular"
          textTransform="capitalize"
          sx={{ lineHeight: 0 }}
        >
          Ana Sayfa
        </MDTypography>
      </NavLink>

      {/* Profile Link */}
      <NavLink
        to="/user-profile"
        style={{
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <MDTypography
          variant="button"
          color="dark"
          component="span"
          fontWeight="regular"
          textTransform="capitalize"
          sx={{ lineHeight: 0 }}
        >
          Profil
        </MDTypography>
      </NavLink>

      {/* Management Menu */}
      {hasManagementRoutes && (
        <MDBox
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
          onMouseEnter={(e) => handleMenuOpen(e, "management")}
          onMouseLeave={handleMouseLeave} // Mouse MDBox'tan çıkınca menüyü kapatalım
        >
          <MDTypography
            variant="button"
            color="dark"
            component="span"
            fontWeight="regular"
            textTransform="capitalize"
            sx={{ lineHeight: 0 }}
          >
            Yönetim{" "}
            {openMenu === "management" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </MDTypography>
          <Menu
            id="management-menu"
            anchorEl={anchorEl}
            open={openMenu === "management"}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left", // Menünün açıldığı yer sabit olmalı
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            MenuListProps={{
              onMouseLeave: handleMouseLeave, // Mouse menüden ayrılınca menüyü kapatalım
            }}
            PaperProps={{
              elevation: 3,
              sx: {
                padding: "10px 5px",
                borderRadius: "8px",
                mt: "16px", // Menüye aşağı doğru bir boşluk ekleyelim
                minWidth: "160px", // Menü genişliğini kontrol edelim
              },
            }}
          >
            {renderMenuItems(
              routes.filter(
                (route) =>
                  route.key === "user-management" ||
                  route.key === "management-panel" ||
                  route.key === "videos" ||
                  route.key === "quizzes"
              )
            )}
          </Menu>
        </MDBox>
      )}

      {/* Pages Menu */}
      <Box
        onMouseEnter={(e) => handleMenuOpen(e, "pages")}
        onMouseLeave={handleMouseLeave}
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <MDTypography
          variant="button"
          color="dark"
          fontWeight="regular"
          textTransform="capitalize"
          sx={{ lineHeight: 0, cursor: "pointer" }}
        >
          Dersler{" "}
          {openMenu === "pages" ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </MDTypography>
        <Menu
          id="pages-menu"
          anchorEl={anchorEl}
          open={openMenu === "pages"}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left", // Menünün açıldığı yer sabit olmalı
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          MenuListProps={{
            onMouseLeave: handleMouseLeave, // Mouse menüden ayrılınca menüyü kapatalım
          }}
          PaperProps={{
            elevation: 3,
            sx: {
              padding: "10px 5px",
              borderRadius: "8px",
              mt: "16px", // Menüye aşağı doğru bir boşluk ekleyelim
              minWidth: "200px", // Menü genişliğini kontrol edelim
            },
          }}
        >
          {renderMenuItems(filteredPagesRoutes)}
        </Menu>
      </Box>

      {/* Progress Tracking Link */}
      {progressTrackingRoute && (
        <NavLink
          to={progressTrackingRoute.route}
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <MDTypography
            variant="button"
            color="dark"
            component="span"
            fontWeight="regular"
            textTransform="capitalize"
            sx={{ lineHeight: 0 }}
          >
            İlerleme Takibi
          </MDTypography>
        </NavLink>
      )}

      {/* Payment Link */}
      {paymentRoute && (
        <NavLink
          to={paymentRoute.route}
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <MDTypography
            variant="button"
            color="dark"
            component="span"
            fontWeight="regular"
            textTransform="capitalize"
            sx={{ lineHeight: 0 }}
          >
            Ödeme Ekranı
          </MDTypography>
        </NavLink>
      )}
    </MDBox>
  );
}
