// React essentials
import React, { useMemo } from "react";

// Material-UI components
import { Card } from "@mui/material";
import PieChartIcon from "@mui/icons-material/PieChart";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Chart from "react-apexcharts";

const TestPerformance = ({ quizGroups, completedTests, title }) => {
  const chartData = useMemo(() => {
    return completedTests
      .map((completedTest) => {
        const quizGroup = quizGroups.find(
          (group) => group._id === completedTest.testId
        );
        if (!quizGroup) {
          console.warn(
            `Quiz group not found for testId: ${completedTest.testId}`
          );
          return null;
        }

        const totalQuestions = quizGroup.questions.length;

        const correctAnswersCount = completedTest.answers.filter(
          (answer, index) =>
            answer === quizGroup.questions[index]?.correctAnswer // Use optional chaining to avoid errors
        ).length;

        const successRate = (correctAnswersCount / totalQuestions) * 100;

        return {
          name: quizGroup.mainTopic,
          successRate: successRate,
        };
      })
      .filter((data) => data !== null);
  }, [quizGroups, completedTests]);

  if (chartData.length === 0) {
    console.warn("Chart data is empty.");
  }

  const seriesData = chartData.map((item) => item.successRate);
  const labelsData = chartData.map((item) => item.name);

  const chartOptions = {
    series: seriesData,
    options: {
      chart: {
        type: "pie",
        width: "100%",
      },
      labels: labelsData,
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${val.toFixed(2)}%`;
        },
      },
      legend: {
        position: "bottom",
        itemMargin: {
          vertical: 5, // Legend etiketleri arasında dikey padding
        },
        labels: {
          colors: "#888", // Etiket renklerini gri yap
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val.toFixed(2)}%`;
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {},
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <Card
      sx={{
        padding: 3,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {chartData.length !== 0 && (
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <PieChartIcon sx={{ marginBottom: 3.2 }} />
          <MDTypography variant="h6" mb={3} ml={1}>
            {title}
          </MDTypography>
        </MDBox>
      )}
      <MDBox width="100%" maxWidth="450px" alignSelf="center">
        {chartData.length !== 0 ? (
          <Chart
            options={chartOptions.options}
            series={chartOptions.series}
            type="pie"
            key={Date.now()} // Her veri güncellemesinde komponentin yeniden oluşturulmasını sağlar.
          />
        ) : (
          <MDBox display="flex" alignItems="center" justifyContent="center">
            <MDTypography variant="body2" color="text" fontWeight="regular">
              Veri bulunamadı.
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
};

export default React.memo(TestPerformance);
