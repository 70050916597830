// React essentials
import React, { useMemo } from "react";

// Material-UI components
import { Card } from "@mui/material";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DonutChart from "../Charts/DonutChart";

const TimeSpent = ({ items, completedItemsIds, title }) => {
  const chartData = useMemo(() => {
    const topicDurations = {};

    items.forEach((item) => {
      if (completedItemsIds.includes(item._id) && item.isAccessible) {
        const topic = item.mainTopic;
        const duration = item.duration;
        topicDurations[topic] = (topicDurations[topic] || 0) + duration;
      }
    });

    return Object.keys(topicDurations).map((topic) => ({
      mainTopic: topic,
      duration: topicDurations[topic],
    }));
  }, [items, completedItemsIds]);

  return (
    <Card
      sx={{
        padding: 3,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {chartData.length !== 0 && (
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <DonutLargeIcon sx={{ marginBottom: 3.2 }} />
          <MDTypography variant="h6" mb={3} ml={1}>
            {title}
          </MDTypography>
        </MDBox>
      )}
      <MDBox width="100%" maxWidth="450px" alignSelf="center">
        {chartData.length !== 0 ? (
          <DonutChart data={chartData} />
        ) : (
          <MDBox display="flex" alignItems="center" justifyContent="center">
            <MDTypography variant="body2" color="text" fontWeight="regular">
              Veri bulunamadı.
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
};

export default React.memo(TimeSpent);
