// React
import React from "react";

// Material UI components
import { Divider, Grid, Icon, useMediaQuery, useTheme } from "@mui/material";
import * as Icons from "@mui/icons-material";

// Custom components
import HomePageLayout from "examples/LayoutContainers/HomepageLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Assets
import image1 from "assets/images/illustrations/about-us-1.png";
import image2 from "assets/images/illustrations/about-us-2.png";

// Functions and utilities
import linearGradient from "assets/theme/functions/linearGradient";
import { useNavigate } from "react-router-dom";

const FeaturesBox = ({
  title,
  text,
  icon,
  textColor,
  titleColor,
  isXsScreen,
}) => {
  return (
    <MDBox>
      <MDBox
        mt={3}
        display="flex"
        justifyContent={isXsScreen ? "center" : "flex-start"}
        textAlign={isXsScreen ? "center" : "start"}
        alignItems="center"
      >
        {icon}
        <MDTypography
          mx={1}
          sx={{ color: titleColor ? titleColor : "#f8f9fa" }}
          variant={isXsScreen ? "body2" : "h6"}
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDTypography
        sx={{ color: textColor ? textColor : "#ced4da" }}
        variant={isXsScreen ? "button" : "body2"}
        fontWeight="bold"
        ml={isXsScreen ? 0 : 4}
      >
        {text}
      </MDTypography>
    </MDBox>
  );
};

const CommentsBox = ({ name, text, isXsScreen }) => {
  return (
    <MDBox
      textAlign={isXsScreen ? "center" : "start"}
      sx={{
        paddingY: isXsScreen ? "1rem" : "1.5rem",
        height: "100%",
      }}
    >
      <MDTypography variant={isXsScreen ? "body2" : "h6"} mb={1} color="white">
        {name}
      </MDTypography>
      <MDTypography variant={isXsScreen ? "button" : "body2"} color="light">
        "{text}"
      </MDTypography>
    </MDBox>
  );
};

export default function AboutUs() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const LiveTvIcon = Icons.LiveTv;
  const GroupsIcon = Icons.Groups;
  const VideoLibraryIcon = Icons.VideoLibrary;
  const QuizIcon = Icons.Quiz;
  const QueryStatsIcon = Icons.QueryStats;
  const PsychologyIcon = Icons.Psychology;

  return (
    <HomePageLayout>
      <MDBox
        mt={isXsScreen ? 5 : 25}
        mb={isXsScreen ? 2 : 5}
        mx={3}
        sx={{
          height: "auto",
          borderRadius: "1rem",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          container
          spacing={1}
          display="flex"
          height="100%"
          maxWidth={"1920px"}
          alignItems="center"
          justifyContent="center"
          mb={3}
        >
          <Grid item xs={12} md={5.5}>
            <MDBox
              sx={{
                borderRadius: "1rem",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <img
                src={image1}
                alt="About Us"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  maxWidth: "35rem",
                }}
              />
            </MDBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            height="100%"
            alignItems="center"
            mt={3}
          >
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign={isXsScreen ? "center" : "start"}
            >
              <MDTypography variant={isXsScreen ? "h3" : "h2"} mb={1}>
                Dijital Derslik'e Hoş Geldiniz!
              </MDTypography>
              <MDBox display="flex">
                <MDTypography
                  variant={isXsScreen ? "body2" : "h6"}
                  color="text"
                >
                  Dijital Derslik, 8. sınıf öğrencilerini LGS'ye hazırlamak için
                  uzun soluklu bir matematik eğitimi sunar. Canlı yayınlar,
                  video kütüphanesi ve quizlerle kapsamlı bir kamp planı
                  sunuyoruz.
                </MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent={isXsScreen ? "center" : "flex-start"}
              >
                <MDButton
                  variant="contained"
                  color="orange"
                  size={isXsScreen ? "medium" : "large"}
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                  sx={{ mt: isXsScreen ? 3 : 1 }}
                >
                  İletişime Geçin
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox
        mb={isXsScreen ? 5 : 10}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={2}
          container
          py={3}
          display="flex"
          justifyContent="center"
          height="100%"
          alignItems="center"
          sx={{
            backgroundImage: linearGradient("#57A6A1", "#028391"),
            borderRadius: "1rem",
            boxShadow: "0px 0px 3px 0px gray",
          }}
          maxWidth={"1920px"}
        >
          <Grid
            item
            xs={12}
            lg={5.5}
            sx={{ margin: isXsScreen ? 3 : 5, marginX: isXsScreen ? 3 : 8 }}
            display="flex"
            flexDirection="column"
            textAlign={isXsScreen ? "center" : "start"}
            height="100%"
          >
            <MDTypography
              color="white"
              variant={isXsScreen ? "h3" : "h2"}
              mb={2}
            >
              Misyonumuz
            </MDTypography>
            <MDTypography color="light" variant={isXsScreen ? "body2" : "h6"}>
              Dijital Derslik, 8. sınıf öğrencilerini LGS sınavına hazırlamak
              için yenilikçi eğitim çözümleri sunar. Bu çözümler, öğrencilerin
              matematik bilgisini derinleştirir ve başarılarını artırır.
            </MDTypography>
            <FeaturesBox
              title={"Canlı Yayınlar"}
              text={
                "Eğitimcilerimizle gerçek zamanlı etkileşimde bulunarak matematik öğrenmenin keyfini çıkarın. "
              }
              icon={<LiveTvIcon sx={{ color: "#ced4da", marginBottom: 0.5 }} />}
              isXsScreen={isXsScreen}
            />
            <FeaturesBox
              title={"Video Kütüphanesi"}
              text={
                "Matematik konularını içeren geniş bir video kütüphanesi ile dilediğiniz zaman tekrar yapabilirsiniz."
              }
              icon={<VideoLibraryIcon sx={{ color: "#ced4da" }} />}
              isXsScreen={isXsScreen}
            />
            <FeaturesBox
              title={"Quizler"}
              text={
                "Çeşitli quizlerle becerilerinizi test edin ve eksiklerinizi gidermeye yönelik geri bildirimler alın."
              }
              icon={<QuizIcon sx={{ color: "#ced4da" }} />}
              isXsScreen={isXsScreen}
            />
          </Grid>

          <Grid item xs={12} lg={5} display="flex" justifyContent="center">
            <MDBox
              sx={{
                borderRadius: "1rem",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <img
                src={image2}
                alt="About Us"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  maxWidth: "35rem",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox
        mt={5}
        mb={isXsScreen ? 5 : 10}
        mx={3}
        sx={{
          height: "auto",
          borderRadius: "1rem",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          container
          spacing={1}
          display="flex"
          height="100%"
          maxWidth={"1920px"}
          alignItems="center"
          justifyContent="center"
          mb={isXsScreen ? 0 : 3}
        >
          <Grid
            item
            xs={12}
            display="flex"
            height="100%"
            alignItems="center"
            sx={{ marginX: !isXsScreen && 8 }}
            mb={3}
          >
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign={isXsScreen ? "center" : "start"}
              mx={2}
            >
              <MDTypography variant={isXsScreen ? "h3" : "h2"} mb={1}>
                Neden Bizi Seçmelisiniz?
              </MDTypography>
              <MDTypography
                variant={isXsScreen ? "body2" : "h6"}
                color="text"
              >
                Dijital Derslik, öğrenciler için yaratıcı, etkili ve teknik
                açıdan sağlam bir eğitim platformu sunar.
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid
            container
            display="flex"
            height="100%"
            spacing={isXsScreen ? 1 : 3}
            textAlign={isXsScreen ? "center" : "start"}
            mx={3}
            sx={{ marginX: !isXsScreen && 8 }}
          >
            <Grid item xs={12} md={4}>
              {" "}
              <FeaturesBox
                title={"Grup Dersleri"}
                text={
                  "Platformumuz, öğrencilerin seviyelerine göre gruplara ayrıldığı dersler sunar. Bu sayede her seviyedeki öğrenci, kendi hızına ve anlayışına uygun dersler alarak konuları daha iyi kavrar ve ilerleyebilir."
                }
                icon={<GroupsIcon sx={{ color: "black" }} />}
                textColor="#6c757d"
                titleColor="black"
                isXsScreen={isXsScreen}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeaturesBox
                title={"İlerleme Takibi"}
                text={
                  "Öğrenciler, platformumuzda kendilerine sunulan ilerleme takip araçlarıyla gelişimlerini izleyebilirler. Bu araçlar sayesinde hangi konularda daha fazla çalışmaları gerektiğini görebilir ve ilerlemelerini daha net bir şekilde takip edebilirler."
                }
                icon={
                  <QueryStatsIcon sx={{ color: "black", marginBottom: 1 }} />
                }
                textColor="#6c757d"
                titleColor="black"
                isXsScreen={isXsScreen}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {" "}
              <FeaturesBox
                title={"Psikolojik Danışmanlık"}
                text={
                  "Öğrencilerin sadece akademik değil, aynı zamanda psikolojik olarak da desteklenmesine önem veriyoruz. Belirli aralıklarla gerçekleştirilecek oturumlarla, öğrencilerimize psikolojik danışmanlık hizmeti sunuyoruz."
                }
                icon={<PsychologyIcon sx={{ color: "black" }} />}
                textColor="#6c757d"
                titleColor="black"
                isXsScreen={isXsScreen}
              />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mx={2} display="flex" justifyContent="center">
        <Grid
          container
          py={3}
          mb={5}
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: ".5rem",
            boxShadow: "0px 0px 3px 0px gray",
            backgroundImage: linearGradient("#57A6A1", "#028391"),
            borderRadius: "1rem",
          }}
          maxWidth={"1920px"}
          px={3}
        >
          <MDBox
            width="100%"
            justifyContent="center"
            display="flex"
            flexDirection="column"
            textAlign="center"
            px={3}
          >
            <MDTypography
              mb={0.5}
              variant={isXsScreen ? "h3" : "h2"}
              sx={{ alignSelf: "center" }}
              color="white"
            >
              Öğrenci Yorumları
            </MDTypography>
            <Divider light sx={{ opacity: 0.6 }} />
          </MDBox>
          <Grid
            container
            spacing={1}
            mt={isXsScreen ? 0 : 2}
            justifyContent="center"
            display="flex"
            alignItems="stretch"
            sx={{ marginX: !isXsScreen && 8 }}
          >
            <Grid item xs={12} md={4}>
              <CommentsBox
                name={"Elif Kaya"}
                text={
                  "Dijital Derslik ile geçirdiğim 10 ay boyunca, matematikteki başarı seviyem ciddi şekilde arttı. Canlı yayınlar ve quizler, anlayışımı derinleştirip güvenimi artırdı."
                }
                isXsScreen={isXsScreen}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CommentsBox
                name={"Celal Sarıdağ"}
                text={
                  "Dijital Derslik’in video kütüphanesi ve öğretmenlerin desteği, LGS hazırlığımda çok yardımcı oldu. Tüm konuları tekrarlayarak eksiklerimi giderdim."
                }
                isXsScreen={isXsScreen}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CommentsBox
                name={"Akile Sönmez"}
                text={
                  "Sınavlara yönelik quizler, matematik becerilerimi test etmemi ve anında geri bildirim almamı sağladı. Dijital Derslik sayesinde LGS'ye güvenle girdim."
                }
                isXsScreen={isXsScreen}
              />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </HomePageLayout>
  );
}
