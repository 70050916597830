// react
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { useEffect, useRef, useState } from "react";

// @mui material components
import { Quiz } from "@mui/icons-material";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { Card, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Home Page layout components
import HomePageLayout from "examples/LayoutContainers/HomepageLayout";

// Images
import welcome1 from "assets/images/illustrations/welcome-1.jpeg";
import friends from "assets/images/illustrations/friends-3.png";

// js cookie
import Cookies from "js-cookie";
import linearGradient from "assets/theme/functions/linearGradient";

const FeaturesCard = ({
  text1,
  text2,
  icon,
  shouldAnimate,
  delay,
  isXsScreen,
}) => {
  const style = useSpring({
    from: { transform: "translateY(50px)", opacity: 0 },
    to: {
      transform: shouldAnimate ? "translateY(0)" : "translateY(50px)",
      opacity: shouldAnimate ? 1 : 0,
    },
    delay: delay, // Kartların animasyon başlama gecikmesi
  });

  return (
    <animated.div style={style}>
      <Card
        sx={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          height: "auto",
          minHeight: isXsScreen ? "15rem" : "20rem",
          backgroundColor: "#e9ecef",
          "&:hover": {
            transform: "scale(1.01)", // Kartın boyutunu arttır
            boxShadow: "0px 5px 15px rgba(0,0,0,0.3)", // Gölgelendirme ekleyerek derinlik kazandır
          },
        }}
      >
        {icon}
        <MDTypography
          letterSpacing="0.03em"
          my={1}
          mb={1.5}
          color="dark"
          variant={isXsScreen ? "body2" : "h6"}
        >
          {text1}
        </MDTypography>

        <MDTypography
          letterSpacing="0.03em"
          mx={1}
          variant={isXsScreen ? "button" : "body2"}
          sx={{ color: "#343a40" }}
        >
          {text2}
        </MDTypography>
      </Card>
    </animated.div>
  );
};

function WelcomePage() {
  Cookies.set("mode", "light");

  const navigate = useNavigate();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [statsAnimate, setStatsAnimate] = useState(false);
  const [campaignAnimate, setCampaignAnimate] = useState(false);
  const [animate, setAnimate] = useState(false);

  const ref = useRef(null); // Kartların bulunduğu bölüme referans oluştur
  const statsRef = useRef(null);
  const campaignRef = useRef(null);

  // Öğrenci sayısı animasyonu
  const { studentCount } = useSpring({
    studentCount: statsAnimate ? 60 : 0,
    from: { studentCount: 0 },
    delay: 100,
  });

  // Tam net yapanların sayısı animasyonu
  const { fullScoreCount } = useSpring({
    fullScoreCount: statsAnimate ? 14 : 0,
    from: { fullScoreCount: 0 },
    delay: 200,
  });

  // Ortalama net oranı animasyonu
  const { averageNet } = useSpring({
    averageNet: statsAnimate ? 17.33 : 0,
    from: { averageNet: 0 },
    precision: 0.01,
    delay: 300,
  });

  // Başarı oranı animasyonu
  const { successRate } = useSpring({
    successRate: statsAnimate ? 86.65 : 0,
    from: { successRate: 0 },
    precision: 0.01,
    delay: 400,
  });

  const textSpring = useSpring({
    from: { transform: "translate3d(-100px, 0, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 100, // Animasyonun başlaması için gecikme süresi
  });

  // Sağ taraftaki resim için animasyon
  const imageSpring = useSpring({
    from: { transform: "translate3d(100px, 0, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 100, // Animasyonun başlaması için gecikme süresi
  });

  const friendsSpring = useSpring({
    from: { transform: "translate3d(100px, 0, 0)", opacity: 0 },
    to: {
      transform: campaignAnimate
        ? "translate3d(0, 0, 0)"
        : "translate3d(100px, 0, 0)",
      opacity: campaignAnimate ? 1 : 0,
    },
    delay: 100, // Kartların animasyon başlama gecikmesi
  });

  const campaignSpring = useSpring({
    from: { transform: "translate3d(-100px, 0, 0)", opacity: 0 },
    to: {
      transform: campaignAnimate
        ? "translate3d(0, 0, 0)"
        : "translate3d(-100px, 0, 0)",
      opacity: campaignAnimate ? 1 : 0,
    },
    delay: 100, // Kartların animasyon başlama gecikmesi
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Eğer kartlar viewport içindeyse ve daha önce animasyon yapılmadıysa
        if (entry.isIntersecting && !animate) {
          setAnimate(true); // Animasyonları başlat
        }
      },
      {
        root: null, // Tarayıcı viewport'u root olarak kullan
        rootMargin: "0px",
        threshold: 0.4, // Kartların %10'u göründüğünde tetiklenir
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    // Cleanup fonksiyonu
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [animate]); // Bağımlılıklar listesinde animate'i kontrol et

  // IntersectionObserver ile animasyonları tetikle
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStatsAnimate(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.4,
      }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, []);

  // IntersectionObserver ile animasyonları tetikle
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setCampaignAnimate(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.4,
      }
    );

    if (campaignRef.current) {
      observer.observe(campaignRef.current);
    }

    return () => {
      if (campaignRef.current) {
        observer.unobserve(campaignRef.current);
      }
    };
  }, []);

  const firstSection = (
    <>
      <MDTypography
        variant={isXsScreen ? "h4" : "h3"}
        mb={2}
        textAlign={isXsScreen ? "center " : "flex-start"}
        mx={3}
      >
        Matematik dünyasının kilidini açın!
      </MDTypography>
      <MDTypography
        variant={isXsScreen ? "body2" : "h6"}
        sx={{ lineHeight: !isXsScreen && 1.75 }}
        mr={1}
        color="text"
        textAlign={isXsScreen ? "center " : "flex-start"}
        mx={3}
      >
        LGS sınavına hazırlanan öğrenciler için özel olarak tasarladığımız bu
        platformda, yenilikçi matematik eğitim yaklaşımımızla başarınızı en üst
        seviyeye taşıyın. Öğrencilerimizin hedeflerine ulaşmaları için
        motivasyonlarını artıracak, özgüvenlerini pekiştirecek ve başarıya giden
        yolda onlara rehberlik edecek bir öğrenme deneyimi sunuyoruz. Şimdi
        yolculuğa başlayın ve LGS'de zirveye ulaşmak için ilk adımınızı atın!
      </MDTypography>
      <MDBox
        mt={3}
        mx={3}
        display="flex"
        justifyContent={isXsScreen ? "center " : "flex-start"}
      >
        <MDButton
          variant="contained"
          color="orange"
          onClick={() => navigate("auth/register")}
          size={isXsScreen ? "medium" : "large"}
        >
          Yolculuğa Başla
        </MDButton>
      </MDBox>
    </>
  );

  const firstImage = (
    <MDBox position="relative" display="flex" justifyContent="flex-end">
      <img
        className="d-block"
        style={{
          objectFit: "cover",
          borderRadius: ".5rem",
          maxWidth: "100%", // img'i Grid içine sığdırmak için
          maxHeight: "500px", // İstediğiniz bir yükseklik
        }}
        src={welcome1}
        alt="Image One"
      />
    </MDBox>
  );

  const lastSection = (
    <MDBox>
      <MDTypography
        variant={isXsScreen ? "h3" : "h2"}
        mb={2}
        mx={3}
        textAlign={isXsScreen ? "center " : "flex-start"}
        color="light"
      >
        5 Arkadaş Birlikte Kaydolun, %10 İndirim Kazanın!
      </MDTypography>
      <MDTypography
        variant={isXsScreen ? "body2" : "h6"}
        mr={1}
        textAlign={isXsScreen ? "center " : "flex-start"}
        mx={3}
        sx={{ color: "#f8f9fa" }}
      >
        Kontenjanlar 60 Kişiyle Sınırlıdır.
      </MDTypography>
      <MDBox
        mt={3}
        mx={3}
        display="flex"
        justifyContent={isXsScreen ? "center " : "flex-start"}
      >
        <MDButton
          variant="contained"
          color="orange"
          onClick={() => navigate("contact-us")}
          size={isXsScreen ? "medium" : "large"}
        >
          Daha Fazla Bilgi
        </MDButton>
      </MDBox>
    </MDBox>
  );

  const lastImage = (
    <MDBox position="relative" display="flex" justifyContent="flex-end" mx={4}>
      <img
        className="d-block"
        style={{
          objectFit: "cover",
          borderRadius: ".5rem",
          width: "100%",
          maxWidth: "35rem", // img'i Grid içine sığdırmak için
          maxHeight: "500px", // İstediğiniz bir yükseklik
        }}
        src={friends}
        alt="Image One"
      />
    </MDBox>
  );

  return (
    <HomePageLayout isCarousel>
      <MDBox display="flex" justifyContent="center">
        <Grid
          container
          py={6}
          mb={{ xs: 0, md: 5 }}
          sx={{ marginX: !isXsScreen && 8 }}
          ref={ref}
          spacing={1}
          alignItems="center"
          justifyContent="center"
          maxWidth={"1920px"}
        >
          <Grid item xs={12} md={6}>
            {isXsScreen ? (
              firstSection
            ) : (
              <animated.div style={textSpring}>{firstSection}</animated.div>
            )}
          </Grid>
          <Grid item xs={12} md={5} mx={{ xs: 2, xl: "1rem" }} mt={{ xs: 1 }}>
            {isXsScreen ? (
              firstImage
            ) : (
              <animated.div style={imageSpring}>{firstImage}</animated.div>
            )}
          </Grid>
        </Grid>
      </MDBox>

      <MDBox display="flex" justifyContent="center">
        <Grid
          container
          py={3}
          mb={3}
          mx={{ xs: 2 }}
          ref={campaignRef}
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: ".5rem",
            boxShadow: "0px 0px 3px 0px gray",
            backgroundImage: linearGradient("#028391", "#57A6A1"),
            borderRadius: "1rem",
          }}
          maxWidth={"1920px"}
        >
          <MDBox
            width="100%"
            justifyContent="center"
            display="flex"
            flexDirection="column"
            textAlign="center"
            mb={isXsScreen ? 0 : 8}
            px={3}
          >
            <MDTypography
              mb={0.5}
              variant={isXsScreen ? "h4" : "h3"}
              sx={{ alignSelf: "center" }}
              color="light"
            >
              2024 LGS İstatistiklerimiz
            </MDTypography>
            <Divider light sx={{ opacity: 0.6 }} />
          </MDBox>
          <Grid item xs={12} lg={10} xl={8} ref={statsRef}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                md={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <MDTypography
                    variant={isXsScreen ? "h3" : "h2"}
                    color="light"
                  >
                    <animated.span>
                      {studentCount.to((n) => n.toFixed(0))}
                    </animated.span>
                  </MDTypography>
                  <MDTypography
                    fontWeight="bold"
                    variant={isXsScreen ? "button" : "Overline"}
                    color="light"
                    mt={1}
                  >
                    Öğrenci Sayısı
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={3}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  flexDirection="column"
                >
                  <MDTypography
                    variant={isXsScreen ? "h3" : "h2"}
                    color="light"
                  >
                    <animated.span>
                      {fullScoreCount.to((n) => n.toFixed(0))}
                    </animated.span>
                  </MDTypography>
                  <MDTypography
                    fontWeight="bold"
                    color="light"
                    variant={isXsScreen ? "button" : "Overline"}
                    mt={1}
                  >
                    Tam Net Yapanlar
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={3}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <MDTypography
                    variant={isXsScreen ? "h3" : "h2"}
                    color="light"
                  >
                    <animated.span>
                      {averageNet.to((n) => n.toFixed(2))}
                    </animated.span>
                    &nbsp;/ 20
                  </MDTypography>
                  <MDTypography
                    fontWeight="bold"
                    variant={isXsScreen ? "button" : "Overline"}
                    color="light"
                    mt={1}
                  >
                    Ortalama Net Oranı
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={3}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <MDTypography
                    variant={isXsScreen ? "h3" : "h2"}
                    color="light"
                  >
                    %
                    <animated.span>
                      {successRate.to((n) => n.toFixed(2))}
                    </animated.span>
                  </MDTypography>
                  <MDTypography
                    fontWeight="bold"
                    variant={isXsScreen ? "button" : "Overline"}
                    color="light"
                    mt={1}
                  >
                    Başarı Oranı
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={1} mt={1}>
        <MDBox
          mt={1}
          pt={3}
          width="100%"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          textAlign="center"
        >
          <MDTypography
            mb={0.5}
            variant={isXsScreen ? "h4" : "h3"}
            sx={{ alignSelf: "center" }}
          >
            Seni Neler Bekliyor?
          </MDTypography>
          <MDTypography
            variant={isXsScreen ? "button" : "body2"}
            color="text"
            sx={{ alignSelf: "center" }}
            mx={3}
          >
            Matematik öğrenmeyi gerçekten sürükleyici ve eğlenceli bir deneyim
            haline getiren Dijital Derslik'in birinci sınıf özelliklerini
            keşfedin.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="center" mt={3}>
          <Grid
            container
            py={{ xs: 0, md: 6 }}
            mb={5}
            ref={ref}
            spacing={3}
            alignItems="center"
            justifyContent="center"
            maxWidth={"1920px"}
          >
            <Grid item xs={12} md={4} lg={3} mx={{ xs: 3 }}>
              <FeaturesCard
                shouldAnimate={animate}
                delay={100}
                text1="Videolu Dersler"
                text2=" Kavramlara hayat veren ve öğrenme sevgisini teşvik eden matematik eğitimlerimizle konulara dalın ve her seviyede ustalaşın."
                icon={
                  <VideoLibraryIcon
                    color="text"
                    fontSize="medium"
                    sx={{
                      alignSelf: "center",
                      marginBottom: 1,
                      color: "#343a40",
                    }}
                  />
                }
                isXsScreen={isXsScreen}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} mx={{ xs: 3 }}>
              <FeaturesCard
                shouldAnimate={animate}
                delay={300}
                text1="Testler"
                text2=" Seviyenizi ölçen testler ile sürekli iyileştirme sağlayın. Test sonuçlarınıza göre bir sonraki hedefinizi belirleyin."
                icon={
                  <Quiz
                    color="text"
                    fontSize="medium"
                    sx={{
                      alignSelf: "center",
                      marginBottom: 1,
                      color: "#343a40",
                    }}
                  />
                }
                isXsScreen={isXsScreen}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} mx={{ xs: 3 }}>
              <FeaturesCard
                shouldAnimate={animate}
                delay={500}
                text1="Canlı Yayınlar"
                text2="Uzman eğitmenlerimizle gerçek zamanlı etkileşim ve anında geri bildirim fırsatı ile matematik öğrenmenin keyfini çıkarın."
                icon={
                  <OndemandVideoIcon
                    color="text"
                    fontSize="medium"
                    sx={{
                      alignSelf: "center",
                      marginBottom: 1,
                      color: "#343a40",
                    }}
                  />
                }
                isXsScreen={isXsScreen}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>

      <MDBox display="flex" justifyContent="center">
        <Grid
          container
          py={3}
          mb={5}
          mx={{ xs: 2 }}
          ref={campaignRef}
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: ".5rem",
            boxShadow: "0px 0px 3px 0px gray",
            backgroundImage: linearGradient("#028391", "#57A6A1"),
            borderRadius: "1rem",
          }}
          maxWidth={"1920px"}
        >
          <Grid item xs={12} md={5} mx={3}>
            {isXsScreen ? (
              lastImage
            ) : (
              <animated.div style={friendsSpring}>{lastImage}</animated.div>
            )}
          </Grid>
          <Grid item xs={12} md={5} mx={3}>
            <MDBox
              display="flex"
              justifyContent={isXsScreen ? "center " : "flex-start"}
            >
              {isXsScreen ? (
                lastSection
              ) : (
                <animated.div style={campaignSpring}>
                  {lastSection}
                </animated.div>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </HomePageLayout>
  );
}

export default WelcomePage;
