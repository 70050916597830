import React, { useState, useEffect, useRef } from "react";

// MUI components
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

export default function DynamicAutocomplete({
  label,
  placeholder,
  value: externalValue,
  onChange,
  options: externalOptions,
}) {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(externalValue);
  const [options, setOptions] = useState(externalOptions);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setValue(externalValue);
  }, [externalValue]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (
        newInputValue &&
        !options.find((option) => option.label === newInputValue)
      ) {
        const newOption = { value: newInputValue, label: newInputValue };
        setOptions((prevOptions) => [...prevOptions, newOption]);
        setValue(newOption);
        onChange(newOption);
      }
    }, 500);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (
      newValue &&
      !options.find((option) => option.label === newValue.label)
    ) {
      setOptions([...options, newValue]);
    }
    onChange(newValue);
  };

  return (
    <MDBox sx={{ minWidth: 120 }}>
      <Autocomplete
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        id="dynamic-autocomplete"
        freeSolo
        options={options}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} label={label} />
        )}
      />
    </MDBox>
  );
}
