import React, { useEffect, useState } from "react";

import {
  Grid,
  useMediaQuery,
  useTheme,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import HomePageLayout from "examples/LayoutContainers/HomepageLayout";

import image from "assets/images/blog/blog.png";

import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { blogItemContent } from "./content";
import linearGradient from "assets/theme/functions/linearGradient";
import CountdownComponent from "components/Custom/Countdown";

const BlogItem = ({ title, text, image, onLearnMore }) => {
  return (
    <Card
      sx={{
        height: "100%",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.01)", // Kartın boyutunu arttır
          boxShadow: "0px 5px 15px rgba(0,0,0,0.3)", // Gölgelendirme ekleyerek derinlik kazandır
        },
        backgroundColor: "#e9ecef",
      }}
      onClick={onLearnMore}
    >
      <CardMedia
        sx={{
          height: 220,
          opacity: 0.9,
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        title={title}
      />
      <CardContent sx={{ textAlign: "justify" }}>
        <MDTypography
          gutterBottom
          variant="subtitle2"
          fontWeight="bold"
          component="div"
          mt={1}
        >
          {title}
        </MDTypography>
        <MDTypography variant="body2" color="text">
          {text.substring(0, 60)}...
        </MDTypography>
      </CardContent>
    </Card>
  );
};

export default function Blog() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const [timeLeft, setTimeLeft] = useState({});
  const targetDate = "2025-06-01T09:00:00";

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeRemaining = {};

    if (difference > 0) {
      timeRemaining = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeRemaining = { message: "Sınav günü geldi!" };
    }

    return timeRemaining;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [targetDate]);

  const handleLearnMore = (post) => {
    setSelectedPost(post);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPost(null);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        url: window.location.href,
      });
    } else {
      alert("Tarayıcı paylaşım özelliğini desteklemiyor.");
    }
  };

  return (
    <HomePageLayout>
      <MDBox
        mb={isXsScreen ? 5 : 10}
        mt={isXsScreen ? 10 : 30}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={2}
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          height="100%"
          pb={3}
          alignItems="center"
          maxWidth={"1920px"}
          sx={{
            backgroundImage: linearGradient("#028391", "#57A6A1"),
            borderRadius: "1rem",
            boxShadow: "0px 0px 3px 0px gray",
          }}
        >
          <Grid
            item
            xs={12}
            lg={5.5}
            sx={{ margin: isXsScreen ? 3 : 5, marginX: !isXsScreen && 8 }}
            display="flex"
            flexDirection="column"
            textAlign={isXsScreen ? "center" : "start"}
            height="100%"
          >
            <MDTypography
              color="white"
              variant={isXsScreen ? "h3" : "h2"}
              mb={2}
            >
              Blog Yazılarımız
            </MDTypography>
            <MDBox display="flex">
              <MDTypography
                color="light"
                variant={isXsScreen ? "body2" : "h6"}
                mb={3}
              >
                Eğitimle ilgili en güncel içeriklerimizi, uzman görüşlerimizi ve
                rehberlik metinlerimizi burada bulabilirsiniz. Her öğrencinin
                daha verimli ve eğlenceli bir öğrenim süreci geçirmesi için
                hazırlanan blog yazılarımıza göz atın!
              </MDTypography>
            </MDBox>

            <MDBox
              display="flex"
              justifyContent={isXsScreen ? "center" : "flex-start"}
            >
              <MDButton
                variant="contained"
                color="orange"
                size={isXsScreen ? "medium" : "large"}
                onClick={handleShare}
              >
                Paylaş
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={5} display="flex" justifyContent="center">
            <MDBox
              sx={{
                overflow: "hidden",
                height: "100%",
              }}
            >
              <img
                src={image}
                alt="About Us"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  maxWidth: "35rem",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox
        mb={isXsScreen ? 5 : 10}
        mt={3}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          px={3}
          container
          spacing={3}
          mb={2}
          display="flex"
          height="100%"
          alignItems="stretch"
          maxWidth={"1920px"}
        >
          {blogItemContent.map((item, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <BlogItem
                key={index}
                title={item.title}
                text={item.text}
                image={item.image}
                onLearnMore={() => handleLearnMore(item)}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fullScreen={isXsScreen}
        sx={{ zIndex: 9999 }}
      >
        {selectedPost && (
          <MDBox p={3}>
            <DialogTitle>
              <MDTypography variant={isXsScreen ? "h6" : "h5"}>
                {selectedPost.title}
              </MDTypography>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ textAlign: "justify" }}>
              <MDTypography
                variant={isXsScreen ? "button" : "body2"}
                color="text"
                sx={{ letterSpacing: 1 }}
              >
                {selectedPost.text}
              </MDTypography>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={handleCloseDialog}
                variant="outlined"
                color="secondary"
              >
                Kapat
              </MDButton>
            </DialogActions>
          </MDBox>
        )}
      </Dialog>

      <MDBox
        mb={5}
        mt={3}
        sx={{
          height: "auto",
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          mx={2}
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          height="100%"
          pb={3}
          alignItems="center"
          maxWidth={"1920px"}
          sx={{
            backgroundImage: linearGradient("#028391", "#57A6A1"),
            borderRadius: "1rem",
            boxShadow: "0px 0px 3px 0px gray",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ margin: isXsScreen ? 3 : 5, marginX: !isXsScreen && 10 }}
            display="flex"
            flexDirection="column"
            textAlign= "center"
            height="100%"
          >
            <MDTypography
              color="white"
              variant={isXsScreen ? "h3" : "h2"}
              mb={1}
            >
              2025 LGS Geri Sayım 
            </MDTypography>
            <CountdownComponent />
          </Grid>
        </Grid>
      </MDBox>
    </HomePageLayout>
  );
}
