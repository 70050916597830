// React essentials
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AdviceCard from "components/Custom/AdviceCard";

// Material UI components
import { Divider, Grid, useMediaQuery, useTheme } from "@mui/material";

// Material UI icons
import UpdateIcon from "@mui/icons-material/Update";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

// Redux slices
import { fetchVideos } from "slices/Videos/services";
import { videoComplete, fetchCompletedItems } from "slices/Statistics/services";

// Helpers
import { formatDuration, formatDateTime } from "helpers/index";

// Other
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Vimeo
import Player from "@vimeo/player";

export default function index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subject } = useParams();

  const videoRef = useRef(null); // Video elementi için ref
  const playerRef = useRef(null); // Player referansı ekleyin

  const videos = useSelector((state) => state.videos.items);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [videoIndex, setVideoIndex] = useState(0);
  const [isLastVideo, setIsLastVideo] = useState(false);
  const [totalDuration, setTotalDuration] = useState("");
  const [userId, setUserId] = useState();
  const [showOverlay, setShowOverlay] = useState(false); // Overlay gösterim durumu için state
  const [latestUploadDate, setLatestUploadDate] = useState("");
  const [filteredVideos, setFilteredVideos] = useState([]); // filteredVideos state'i ve onu güncelleyecek setter fonksiyonu

  useEffect(() => {
    const token = Cookies.get("token");
    const decodedToken = jwtDecode(token);
    setUserId(decodedToken?.id);
    dispatch(fetchCompletedItems(decodedToken?.id));
  }, []);

  useEffect(() => {
    // Assuming each video has an 'order' property that you want to sort by.
    const sortedAndFilteredVideos = videos
      .filter(
        (video) => video.guidanceMainTopic === subject && video.isAccessible
      )
      .sort((a, b) => a.order - b.order); // Sorting by the 'order' property

    setFilteredVideos(sortedAndFilteredVideos);
  }, [videos, subject]); // 'videos' or 'subject' change triggers this effect

  const selectedVideo = videos?.filter(
    (video) => video._id === selectedVideoId
  );

  const handleVideoEnd = async () => {
    if (!showOverlay) {
      // Only trigger if overlay isn't already shown (i.e., manual navigation wasn't used)
      setShowOverlay(true); // Video ended naturally, show overlay to proceed to next

      if (userId) {
        const videoData = {
          userId: userId,
          videoId: filteredVideos[videoIndex]._id,
        };

        await dispatch(videoComplete(videoData))
          .unwrap()
          .then(() => {
            dispatch(fetchCompletedItems(userId));
          })
          .catch((error) => console.error("Video completion error:", error));
      } else {
        console.error("User ID not found in token");
      }
    }
  };

  useEffect(() => {
    if (filteredVideos.length > 0 && videoRef.current) {
      playerRef.current = new Player(videoRef.current, {
        autopause: true,
        autoplay: true,
      });

      playerRef.current
        .loadVideo(filteredVideos[videoIndex]?.playerEmbedUrl)
        .then(() => {
          return playerRef.current.play();
        })
        .catch((error) => console.error("Video load error:", error));

      // Add the event listener for when the video ends
      playerRef.current.on("ended", handleVideoEnd);

      // Clean up the event listener when the component unmounts or video changes
      return () => {
        playerRef.current.off("ended", handleVideoEnd);
      };
    }
  }, [filteredVideos, videoIndex]); // Ensure videoIndex is included in the dependency array

  useEffect(() => {
    if (filteredVideos.length > 0) {
      setSelectedVideoId(filteredVideos[0]?._id);
      setVideoIndex(0); // İlk video ile başla
      setIsLastVideo(filteredVideos.length === 1); // Check if this is the only video
    }
  }, [filteredVideos]);

  useEffect(() => {
    dispatch(fetchVideos());
    setVideoIndex(1);
  }, [dispatch]);

  useEffect(() => {
    const totalSeconds = filteredVideos.reduce(
      (acc, curr) => acc + (curr.duration || 0),
      0
    );
    if (filteredVideos.length > 0) {
      const duration = formatDuration(totalSeconds);
      setTotalDuration(duration);
    } else {
      setTotalDuration("0h 0m 0s");
    }

    if (filteredVideos.length > 0) {
      // Extract and sort the upload dates to find the latest
      const latestDate = filteredVideos
        .map((video) => new Date(video.uploadDate))
        .sort((a, b) => b - a)[0]; // Get the most recent date

      // Use your formatDateTime function
      const { readableDate, readableTime } = formatDateTime(latestDate);
      setLatestUploadDate(`${readableDate} at ${readableTime}`);
    }
  }, [filteredVideos]);

  const VideoOverlay = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: isLastVideo ? "100%" : "98%",
          backgroundColor: "#F8F9FA",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
        }}
      >
        <MDBox display="flex" flexDirection="column">
          <MDTypography
            color={!isLastVideo ? "white" : "dark"}
            variant={isXsScreen ? "h6" : "h5"}
            fontWeight="bold"
            sx={{ mb: 2 }}
          >
            🎉 Tebrikler {subject} videosunu tamamladınız!
          </MDTypography>
          <MDButton
            sx={{ alignSelf: "center" }}
            variant="outlined"
            color="secondary"
            size={isXsScreen ? "small" : "medium"}
            onClick={() => navigate("/guidance-videos")}
          >
            Rehberlik Videolarına Git
          </MDButton>
        </MDBox>
      </div>
    );
  };

  return (
    <MDBox>
      <MDBox
        height="4rem"
        width="100%"
        sx={{ backgroundColor: "#2D2F31" }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <MDBox
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <MDBox
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          >
            <MDTypography
              color="light"
              variant={isXsScreen ? "body2" : "h6"}
              ml={3}
              mr={2}
              sx={{ letterSpacing: "0.1em" }}
            >
              Dijital Derslik
            </MDTypography>
          </MDBox>
          <Divider orientation="vertical" flexItem light />
          <MDTypography
            color="light"
            variant={isXsScreen ? "button" : "body2"}
            mx={2}
            sx={{ letterSpacing: "0.1em" }}
          >
            {subject}
          </MDTypography>
        </MDBox>
      </MDBox>
      {selectedVideoId && (
        <MDBox
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          <MDBox sx={{ position: "relative" }}>
            {showOverlay && <VideoOverlay />}
            <div
              style={{
                position: "relative",
                width: "100%",
                height: 0,
                paddingBottom: "56.25%",
                backgroundColor: "#2D2F31",
                color: "#fff",
              }}
            >
              <iframe
                ref={videoRef}
                src={filteredVideos[0]?.playerEmbedUrl}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Vimeo Video Player"
              ></iframe>
            </div>
          </MDBox>
          <MDBox px={isXsScreen ? 3 : 5} mt={3}>
            <MDTypography
              px={0.5}
              variant={isXsScreen ? "h5" : "h4"}
              color="dark"
            >
              Genel Bakış
            </MDTypography>
            <Divider />
            <MDBox px={0.5} display="flex" alignItems="center">
              <UpdateIcon />
              <MDTypography variant="overline" mx={1}>
                Son Güncelleme: {latestUploadDate}
              </MDTypography>
            </MDBox>
            <AdviceCard
              title={"Yolculuğunuzda Rehberlik"}
              description={
                "Her öğrencinin öğrenme yolculuğu benzersizdir ve bazen bu yolculukta doğru rehberlik ve motivasyon en büyük farkı yaratır. Rehberlik videolarımız, sadece akademik başarınızı artırmakla kalmaz, aynı zamanda kişisel gelişiminize de katkıda bulunur. Bu videolar, sınav stratejilerinden zaman yönetimine, stresle başa çıkma tekniklerinden kariyer planlamasına kadar geniş bir yelpazede konuları kapsar. Her adımda yanınızda olan uzmanlarımızın tavsiyeleriyle hedeflerinize daha emin adımlarla ilerleyin."
              }
              icon={<AccessAlarmIcon sx={{ fontSize: 30, color: "#495057" }} />}
              isXsScreen={isXsScreen}
            />

            <Grid container spacing={1} px={0.5} pb={10} mt={1}>
              <Grid item xs={12} md={3} mt={isXsScreen && 3}>
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  fontWeight="regular"
                  mt={3}
                >
                  Sayıya göre
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={8}>
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  fontWeight="regular"
                  color="text"
                  mt={1}
                >
                  Video: Toplam {totalDuration}
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={3}>
                <MDTypography
                  fontWeight="regular"
                  mt={3}
                  variant={isXsScreen ? "button" : "body2"}
                >
                  Açıklama
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={8}>
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  fontWeight="regular"
                  color="text"
                  mt={3}
                >
                  {selectedVideo[0]?.description}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}
