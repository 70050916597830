import React, { useState } from "react";

import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useMaterialUIController } from "context";

import { formatDate } from "helpers";
import { formatPhoneNumber } from "helpers";

function ContactCard({ contact, handleDelete }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { id, name, phone, email, message, receivedAt, status, noGutter } =
    contact;

  return (
    <>
      <MDBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={noGutter ? 0 : 1}
        mt={2}
      >
        <MDBox width="100%" display="flex" flexDirection="column">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            mb={2}
          >
            <MDTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {name}
            </MDTypography>

            <MDBox
              display="flex"
              alignItems="center"
            >
              <MDBox mr={1}>
                <MDButton
                  variant="text"
                  color="error"
                  onClick={() => handleDelete(id)}
                >
                  <Icon>delete</Icon>&nbsp;delete
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Kullanıcı Rolü:&nbsp;&nbsp;&nbsp;
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {status === "custodian" ? "Veli" : "Öğrenci"}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Telefon Numarası:&nbsp;&nbsp;&nbsp;
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {formatPhoneNumber(phone)}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              E-posta:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {email}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Mesaj:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {message}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDTypography variant="caption" color="text">
            Gönderilme Tarihi:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {formatDate(receivedAt)}
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );
}

// Setting default values for the props of ContactCard
ContactCard.defaultProps = {
  noGutter: false,
};

// Typechecking props for the ContactCard
ContactCard.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    receivedAt: PropTypes.string.isRequired,
  }).isRequired,
  noGutter: PropTypes.bool,
};

export default ContactCard;
