// React essentials
import React from "react";

// @mui material components
import { Card, Grid, useMediaQuery, useTheme } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

export default function Header({
  title,
  description,
  buttonText,
  action,
  image,
  isStudent,
}) {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MDBox position="relative" mb={3}>
      <MDBox pt={!isXsScreen && 3} pb={3} />
      {image && (
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xxl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.1),
                rgba(gradients.dark.state, 0.1)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
      )}
      <Card
        sx={{
          position: "relative",
          mt: image && -8,
          mx: image && 3,
          py: image ? 4 : !buttonText ? 3.75 : isStudent ? 3.75 : 3,
          px: 2,
        }}
      >
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <MDBox height="100%" lineHeight={1} mx={1}>
              <MDTypography
                sx={{ color: "#495057" }}
                variant={isXsScreen ? "h6" : "h5"}
                fontWeight="medium"
              >
                {title}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {description}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item>
            {buttonText && !isStudent && (
              <MDBox mx={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={action}
                  size={isXsScreen ? "small" : "medium"}
                >
                  {buttonText}
                </MDButton>
              </MDBox>
            )}
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}
