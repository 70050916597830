import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  Divider,
} from "@mui/material";
import { Close, Twitter } from "@mui/icons-material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import LinkItem from "components/Custom/LinkItem";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import brand from "assets/images/logos/brand.png";

function Header({ light }) {
  const navigate = useNavigate();
  const { size } = typography;
  const { pathname } = useLocation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleDrawerClose();
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = "905543453544"; // Hedef telefon numarasını girin
    const message = encodeURIComponent("Merhaba, bilgi almak istiyorum"); // URL olarak kodlanacak mesaj
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+905543453544";
  };

  const renderSocials = () => (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={3} sx={{ cursor: "pointer" }}>
        <Link
          href="https://www.facebook.com/profile.php?id=61560670453443"
          target="_blank"
        >
          <FacebookIcon fontSize="medium" color="white" />
        </Link>
      </Grid>
      <Grid item xs={3} sx={{ cursor: "pointer" }}>
        <Link
          href="https://www.instagram.com/dijitalderslikmat?igsh=Nm1yenVsanB6c2Z0"
          target="_blank"
        >
          <InstagramIcon fontSize="medium" color="white" />
        </Link>
      </Grid>
      <Grid item xs={3} sx={{ cursor: "pointer" }}>
        <Link href="https://x.com/dijitalderslik" target="_blank">
          <Twitter fontSize="medium" color="white" />
        </Link>
      </Grid>
      <Grid item xs={3} sx={{ cursor: "pointer" }}>
        <Link
          href="https://www.linkedin.com/company/dijital-derslik/about/?viewAsMember=true"
          target="_blank"
        >
          <LinkedInIcon fontSize="medium" color="white" />
        </Link>
      </Grid>
    </Grid>
  );

  const drawerList = () => (
    <MDBox>
      <MDBox display="flex" justifyContent="flex-end" pt={1} px={1}>
        <IconButton onClick={handleDrawerClose}>
          <Close fontSize="medium" />
        </IconButton>
      </MDBox>
      <MDBox
        component="img"
        src={brand}
        alt="Brand"
        width="8rem"
        mt={-8}
        onClick={() => navigate("/")}
      />
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <MDButton
            fullWidth
            size="small"
            variant="contained"
            color="teal"
            sx={{ borderRadius: 0 }}
            onClick={handleWhatsAppClick}
          >
            WhatsApp
          </MDButton>
        </Grid>
        <Grid item xs={6}>
          <MDButton
            fullWidth
            size="small"
            color="orange"
            variant="contained"
            sx={{ borderRadius: 0 }}
            onClick={handlePhoneClick}
          >
            Ara
          </MDButton>
        </Grid>
      </Grid>
      <List>
        {[
          { text: "Ana Sayfa", path: "/" },
          { text: "Hakkımızda", path: "/about-us" },
          { text: "Bize Ulaşın", path: "/contact-us" },
          { text: "Yardım", path: "/help" },
          { text: "Blog", path: "/blog" },
        ].map((item) => (
          <>
            <MDBox
              onClick={() => handleNavigate(item.path)}
              sx={{
                "&:hover": {
                  backgroundColor: "#ced4da",
                },
              }}
              p={1.5}
            >
              <MDTypography variant="button" fontWeight="regular" mx={1}>
                {item.text}
              </MDTypography>
            </MDBox>
            <Divider sx={{ margin: 0 }} />
          </>
        ))}
      </List>
      <MDBox
        height="50px"
        width="60vw"
        position="fixed"
        bottom="0"
        bgcolor="primary.main"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mx={2}
      >
        <MDTypography variant="overline" color="dark">
          &#xA9; {new Date().getFullYear()} Dijital Derslik
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  return (
    <MDBox
      position={"absolute"}
      width="100%"
      bottom={0}
      py={{ xs: 0, sm: 1, lg: 2.7 }}
    >
      <Container sx={{ margin: 0, padding: 0 }}>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "row", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          {!isSmallScreen && !pathname.startsWith("/auth") && (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              color={light ? "white" : "text"}
              fontSize={size.sm}
            >
              <MDBox
                width="100%"
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <MDBox
                  fontSize={size.md}
                  display="flex"
                  alignItems="center"
                  sx={{ gap: 0.5 }}
                  mr={5}
                >
                  <WhatsAppIcon color="white" fontSize="medium" />
                  <Link href="tel:+905543453544" target="_blank">
                    <MDTypography
                      color="white"
                      variant="body2"
                      fontWeight="medium"
                      sx={{ letterSpacing: "0.05em" }}
                    >
                      0554 345 35 44
                    </MDTypography>
                  </Link>
                </MDBox>
                <MDBox
                  fontSize={size.md}
                  display="flex"
                  alignItems="center"
                  sx={{ gap: 0.5 }}
                  mr={5}
                >
                  <EmailIcon color="white" fontSize="medium" />
                  <Link href="mailto:info@dijitalderslik.net" target="_blank">
                    <MDTypography
                      color="white"
                      variant="body2"
                      fontWeight="medium"
                      sx={{ letterSpacing: "0.05em" }}
                    >
                      info@dijitalderslik.net
                    </MDTypography>
                  </Link>
                </MDBox>
                <MDBox>{renderSocials()}</MDBox>
              </MDBox>
            </MDBox>
          )}
          {!isSmallScreen && pathname.startsWith("/auth") && (
            <MDBox display="flex" gap={3} mx={4.5}>
              <LinkItem
                text="Ana Sayfa"
                target="/"
                shouldHighlighted={true}
                isActive={pathname === "/"}
              />
              <LinkItem
                text="Hakkımızda"
                target="/about-us"
                shouldHighlighted={true}
                isActive={pathname === "/about-us"}
              />
              <LinkItem
                text="Bize Ulaşın"
                target="/contact-us"
                shouldHighlighted={true}
                isActive={pathname === "/contact-us"}
              />
              <LinkItem
                text="Yardım"
                target="/help"
                shouldHighlighted={true}
                isActive={pathname === "/help"}
              />
              <LinkItem
                text="Blog"
                target="/blog"
                shouldHighlighted={true}
                isActive={pathname === "/blog"}
              />
            </MDBox>
          )}
          {isSmallScreen && (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              mt={3.5}
            >
              <IconButton onClick={handleDrawerOpen}>
                <MenuIcon fontSize="large" color="white" />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
                sx={{
                  flexShrink: 0,
                  zIndex: 9999,
                  "& .MuiDrawer-paper": {
                    bgcolor: "#EBEFF4",
                    height: "100vh", // Updated drawer height
                    boxSizing: "border-box",
                    marginTop: 0,
                    marginLeft: 0,
                    borderRadius: 0,
                    width: "70vw",
                  },
                }}
              >
                {drawerList()}
              </Drawer>
            </MDBox>
          )}
          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}
          >
            <MDBox component="li" pr={1} lineHeight={1}>
              <MDButton
                variant="outlined"
                color="white"
                size="small"
                onClick={() => navigate("/auth/register")}
              >
                kayıt ol
              </MDButton>
            </MDBox>
            <MDBox component="li" px={1} lineHeight={1}>
              <MDButton
                variant="contained"
                color="white"
                size="small"
                onClick={() => navigate("/auth/login")}
              >
                Giriş yap
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  light: false,
};

// Typechecking props for the Header
Header.propTypes = {
  light: PropTypes.bool,
};

export default Header;
