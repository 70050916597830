import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Grid, useMediaQuery, useTheme } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import image404 from "assets/images/illustrations/404.png";
import image403 from "assets/images/illustrations/403.png";

const styles = {
  layout: {
    height: "100vh", // Tüm ekranı kaplamasını sağlayacak
    // background: `url(${backgroundImage}) no-repeat center center`, // Arka plan resmini ortalar ve tekrar etmez
    backgroundSize: "cover", // Resmin tüm alanı kaplamasını sağlar
  },
  gridItem: {
    display: "flex",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    justifyContent: "flex-end",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "500px",
    objectFit: "contain",
  },
  text: {
    fontFamily: "Exo, sans-serif",
  },
};

const ErrorPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const errorConfig = {
    "/404": {
      title: "Hata 404",
      message: "SAYFA BULUNAMADI",
      description:
        "Aradığınız sayfa mevcut değil ya da bir hata meydana geldi. Ana sayfaya geri dönün.",
      imageUrl: image404,
      returnPath: "/dashboard",
    },
    "/403": {
      title: "Ücretli İçerik",
      message: "İÇERİĞE ERİŞİM HAKKINIZ YOKTUR",
      description:
        "Bu sayfadaki içeriği görüntüleyebilmek için ödeme yaparak üyeliğinizi aktif hale getirmelisiniz.",
      imageUrl: image403,
      returnPath: "/payment",
    },
  };

  const { title, message, description, imageUrl, returnPath } =
    errorConfig[pathname] || errorConfig["/404"];

  const handleBack = () => {
    navigate(returnPath);
  };

  return (
    <div style={styles.layout}>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid
          container
          spacing={0}
          sx={{
            height: "85vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{
              ...styles.gridItem,
              justifyContent: isXsScreen ? "center" : "flex-end",
            }}
          >
            <img src={imageUrl} alt={title} style={styles.image} />
          </Grid>
          <Grid
            item
            pb={3}
            xs={12}
            md={6}
            style={{
              ...styles.gridItem,
              justifyContent: isXsScreen ? "center" : "flex-start",
              textAlign: isXsScreen ? "center" : "flex-start",
            }}
          >
            <MDBox>
              <MDTypography
                color="black"
                opacity=".8"
                fontSize={isXsScreen ? "48px" : "72px"}
                fontWeight="bold"
                sx={styles.text}
              >
                {title}
              </MDTypography>
              <MDTypography
                mx={0.5}
                fontWeight="bold"
                variant="h6"
                sx={styles.text}
              >
                {message}
              </MDTypography>
              <MDTypography
                mt={3}
                mb={3}
                fontWeight="medium"
                variant="body2"
                sx={styles.text}
                mx={0.5}
              >
                {description}
              </MDTypography>
              <MDButton
                sx={{ marginX: 0.5 }}
                size="medium"
                color="teal"
                variant="gradient"
                onClick={handleBack}
              >
                {returnPath === "/payment"
                  ? "Ödeme Ekranına Git"
                  : "Ana sayfaya git"}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </DashboardLayout>
    </div>
  );
};

export default ErrorPage;
