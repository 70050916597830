import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";
import { useMediaQuery, useTheme } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import BasicLayoutLanding from "examples/LayoutContainers/BasicLayoutLanding";

// Images
import bgImage from "assets/images/welcome/welcome-slider-2.jpg";

// Authentication service
import AuthService from "services/auth-service";
import { login } from "slices/Auth/authSlice";

// Js cookie
import Cookies from "js-cookie";

function Login() {
  Cookies.set("mode", "light");
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [credentialsErros, setCredentialsError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    usernameError: false,
    passwordError: false,
  });
  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 3500);
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name + "Error"]: false,
    });

    if (credentialsErros) {
      setCredentialsError(null);
    }
  };

  const submitHandler = async (e) => {
    // check rememeber me?
    e.preventDefault();

    if (inputs.username.trim().length === 0) {
      setErrors({ ...errors, usernameError: true });
      return;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    setDisabled(true);

    const newUser = { username: inputs.username, password: inputs.password };

    const myData = {
      data: {
        type: "token",
        attributes: { ...newUser },
      },
    };

    try {
      const response = await AuthService.login(myData);
      const res = await dispatch(
        login(response.access_token, response.refresh_token)
      );
      if (response.access_token) {
        const expirationTime = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000
        );
        Cookies.set("tokenExpiration", expirationTime.toISOString(), {
          expires: 1,
        });
        Cookies.set("isAuthenticatedBefore", true, { expires: 1 }); // isAuthenticated çerezini 1 gün boyunca true yap
        openSB(
          "success",
          "check",
          "Başarılı",
          <MDTypography
            variant={isXsScreen ? "caption" : "button"}
            color="white"
          >
            Giriş yapılıyor, hoş geldiniz{" "}
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              {inputs.username}
            </span>
          </MDTypography>
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      }
    } catch (error) {
      openSB(
        "error",
        "warning",
        "Hata",
        <MDTypography variant={isXsScreen ? "caption" : "button"} color="white">
          {error?.hasOwnProperty("message")
            ? error?.message
            : "Sistemde bir sorun oluştu, lütfen daha sonra tekrar deneyiniz."}
        </MDTypography>
      );
    } finally {
      setDisabled(false);
    }

    return () => {
      setInputs({
        username: "",
        password: "",
      });

      setErrors({
        usernameError: false,
        passwordError: false,
      });
    };
  };

  return (
    <BasicLayoutLanding image={bgImage} flip={true}>
      <MDBox pt={!isXsScreen && 4}>
        <MDBox
          component="form"
          role="form"
          method="POST"
          onSubmit={submitHandler}
          style={{ maxHeight: "calc(100vh - 3.5rem)" }}
        >
          <MDBox py={isXsScreen ? 5 : 3} textAlign="center">
            <MDTypography
              variant={isXsScreen ? "h5" : "h4"}
              fontWeight="medium"
              sx={{ color: "#17171" }}
            >
              Dijital Derslik'e Hoş Geldiniz!
            </MDTypography>
            <MDTypography
              display="block"
              variant={isXsScreen ? "button" : "body2"}
              sx={{ color: "#17171" }}
              my={1}
            >
              Önceden belirlediğiniz kullanıcı adı ve parolanız ile giriş
              yapabilirsiniz.
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label={"Kullanıcı adı"}
              fullWidth
              value={inputs.username}
              name="username"
              onChange={changeHandler}
              error={errors.usernameError}
              focused
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label={"Parola"}
              fullWidth
              name="password"
              value={inputs.password}
              onChange={changeHandler}
              error={errors.passwordError}
              focused
            />
          </MDBox>
          {/* <MDBox display="flex" alignItems="center" ml={-1}>
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
              &nbsp;&nbsp;Beni hatırla
            </MDTypography>
          </MDBox> */}
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              type="submit"
              disabled={disabled}
              size={isXsScreen ? "small" : "medium"}
            >
              giris yap
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Parolanızı mı unuttunuz? Öğretmeniniz ile iletişime geçiniz.
            </MDTypography>
          </MDBox>
          <MDBox mt={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Hesabınız yok mu?{" "}
              <MDTypography
                component={Link}
                to="/auth/register"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Kayıt olun
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
        bgGreen
      />
    </BasicLayoutLanding>
  );
}

export default Login;
