// React imports
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExploreIcon from "@mui/icons-material/Explore";
import { Article } from "@mui/icons-material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Custom components
import Header from "components/Custom/Header";
import ProfileCard from "components/Custom/ProfileCard";
import AlertDialog from "components/Custom/Dialog";
import GeneralSituation from "components/Custom/GeneralSituation";
import TimeSpent from "components/Custom/TimeSpent";
import TestPerformance from "components/Custom/TestPerformance";
import QuizzesSituation from "components/Custom/QuizzesSituation";

// Redux actions - selectors
import { fetchUsers, deleteUser, updateIsActive } from "slices/Users/services";
import { fetchCompletedItems } from "slices/Statistics/services";
import { fetchVideos } from "slices/Videos/services";
import { fetchQuizGroups } from "slices/Quizzes/services";
import { resetPassword } from "slices/Users/services";

export default function UserDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [open, setOpen] = useState();
  const [dialogType, setDialogType] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });

  const users = useSelector((state) => state.users.users) || [];

  const selectedUser = useMemo(
    () => users.find((user) => user.id === userId),
    [users, userId]
  );

  const videos = useSelector((state) => state.videos.items);

  const lessonVideos = useMemo(
    () =>
      videos.filter(
        (video) => video.isAccessible && video.videoType === "lesson"
      ),
    [videos]
  );

  const guidanceVideos = useMemo(
    () =>
      videos.filter(
        (video) => video.isAccessible && video.videoType === "guidance"
      ),
    [videos]
  );

  const completedVideosObject = useSelector(
    (state) => state.statistics.completedVideos || []
  );

  const completedVideosIds = useMemo(
    () => completedVideosObject.map((video) => video.videoId),
    [completedVideosObject]
  );

  const quizGroups = useSelector((state) => state.quizzes.groups || []);

  const completedQuizzesObject = useSelector(
    (state) => state.statistics.completedTests || []
  );

  const isStudent = useMemo(
    () => !["admin", "superuser", "teacher"].includes(selectedUser?.role),
    [selectedUser]
  );

  const isNotSuperUser = selectedUser?.role !== "superuser";

  const handleOpen = useCallback(
    (type) => {
      setDialogType(type);
      if (selectedUser?.role !== "superuser") setOpen(true);
    },
    [selectedUser]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const closeSB = useCallback(() => setOpenSnackbar(false), []);
  const openSB = useCallback((color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });
    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 3500);
  }, []);

  const handleUpdate = useCallback(async () => {
    handleClose();
    openSB(
      "info",
      "notifications",
      "Bildirim",
      <MDTypography variant="button" color="white">
        Kullanıcı durumu düzenleniyor...
      </MDTypography>
    );
    try {
      const res = await dispatch(
        updateIsActive({
          id: userId,
          isActive: !selectedUser?.isActive,
        })
      );
      if (res.type === "users/updateIsActive/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Kullanıcı durumu başarıyla güncellendi
          </MDTypography>
        );
      } else if (res.type === "users/updateIsActive/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Kullanıcı durumunu güncelleme sırasında hata meydana geldi
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  }, [dispatch, userId, selectedUser, handleClose, openSB]);

  const handleDelete = useCallback(async () => {
    handleClose();
    try {
      const res = await dispatch(deleteUser(userId));
      if (res.type === "users/deleteUser/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Kullanıcı silme işlemi başarılı
          </MDTypography>
        );
        setTimeout(() => {
          navigate("/user-management");
        }, 1000);
      } else if (res.type === "users/deleteUser/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Kullanıcı silme işlemi sırasında hata meydana geldi
          </MDTypography>
        );
      }
    } catch {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  }, [dispatch, userId, navigate, handleClose, openSB]);

  const handleResetPasswd = useCallback(async () => {
    handleClose();
    openSB(
      "info",
      "notifications",
      "Bildirim",
      <MDTypography variant="button" color="white">
        Kullanıcı parolası sıfırlanıyor...
      </MDTypography>
    );
    try {
      const res = await dispatch(resetPassword(userId));
      if (res.type === "users/resetPassword/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Kullanıcı parolası başarıyla sıfırlandı
          </MDTypography>
        );
      } else if (res.type === "users/resetPassword/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Kullanıcı parolasını sıfırlama sırasında hata meydana geldi
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  }, [dispatch, userId, handleClose, openSB]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchCompletedItems(userId));
      dispatch(fetchVideos());
      dispatch(fetchQuizGroups());
      dispatch(fetchUsers());
    }
  }, [dispatch, userId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Kullanıcı Detay" />
      {selectedUser && (
        <MDBox display="flex" width="100%" justifyContent="center">
          <Grid
            container
            spacing={3}
            display="flex"
            height="100%"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <ProfileCard user={selectedUser} handleOpen={handleOpen}>
                {isNotSuperUser && (
                  <Grid container spacing={0} mt={1}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      display="flex"
                      justifyContent={{ xs: "center", md: "flex-end" }}
                    >
                      <MDButton
                        onClick={() => handleOpen("resetPasswd")}
                        size={isXsScreen ? "small" : "medium"}
                        sx={{
                          marginX: 1,
                          marginBottom: 3,
                          width: "100%",
                          maxWidth: "16rem",
                        }}
                        variant="gradient"
                        color="info"
                      >
                        Parolayı Sıfırla
                      </MDButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      display="flex"
                      justifyContent={{ xs: "center", md: "flex-start" }}
                    >
                      <MDButton
                        onClick={() => handleOpen("delete")}
                        size={isXsScreen ? "small" : "medium"}
                        sx={{
                          marginX: 1,
                          marginBottom: 3,
                          width: "100%",
                          maxWidth: "16rem",
                        }}
                        variant="gradient"
                        color="error"
                      >
                        Kullanıcıyı Sil
                      </MDButton>
                    </Grid>
                  </Grid>
                )}
              </ProfileCard>
            </Grid>
            {isStudent && (
              <>
                <Grid item xs={12} lg={6} xl={7.5} display="flex">
                  <GeneralSituation
                    icon={<BarChartIcon sx={{ marginBottom: 1 }} />}
                    title={"Öğrenci Ders Durumu"}
                    videos={lessonVideos}
                  />
                </Grid>
                <Grid item xs={12} lg={6} xl={4.5}>
                  <TimeSpent
                    title={"Konulara Ayrılan Zaman Dağılımı"}
                    videos={lessonVideos}
                    items={lessonVideos}
                    completedItemsIds={completedVideosIds}
                  />
                </Grid>
                <Grid item xs={12} lg={6} xl={7.5}>
                  <MDBox display="flex" height="100%">
                    <QuizzesSituation
                      icon={<Article sx={{ marginBottom: 1 }} />}
                      title={"Öğrenci Test Durumu"}
                      quizGroups={quizGroups}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} lg={6} xl={4.5}>
                  <TestPerformance
                    title={"Test Başarım Oranları"}
                    quizGroups={quizGroups}
                    completedTests={completedQuizzesObject}
                  />
                </Grid>
                <Grid item xs={12} lg={6} xl={7.5}>
                  <MDBox display="flex" height="100%">
                    <GeneralSituation
                      icon={<ExploreIcon sx={{ marginBottom: 1 }} />}
                      title={"Öğrenci Rehberlik Durumu"}
                      videos={guidanceVideos}
                    />
                  </MDBox>
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      )}
      {!selectedUser && (
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <MDTypography variant="body2" color="text" fontWeight="regular">
            Veri bulunamadı.
          </MDTypography>
        </MDBox>
      )}
      <AlertDialog
        width="sm"
        open={open}
        onClose={handleClose}
        onSubmit={
          dialogType === "delete"
            ? handleDelete
            : dialogType === "resetPasswd"
            ? handleResetPasswd
            : handleUpdate
        }
        title={
          dialogType === "delete"
            ? "Kullanıcıyı Sil"
            : dialogType === "resetPasswd"
            ? "Kullanıcı Parolasını Sıfırla"
            : `Kullanıcıyı ${selectedUser?.isActive ? "Pasife" : "Aktife"} Al`
        }
      >
        {dialogType === "delete" ? (
          <MDTypography color="text" variant="body2" mx={1}>
            <span style={{ fontWeight: "bold" }}>{selectedUser?.name}</span>{" "}
            adlı kullanıcıyı kalıcı olarak silmek istediğinizden emin misiniz?
          </MDTypography>
        ) : dialogType === "resetPasswd" ? (
          <MDTypography color="text" variant="body2" mx={1}>
            <span style={{ fontWeight: "bold" }}>{selectedUser?.name}</span>{" "}
            adlı kullanıcının parolasını sıfırlamak istediğinizden emin misiniz?
          </MDTypography>
        ) : (
          <MDTypography color="text" variant="body2" mx={1}>
            <span style={{ fontWeight: "bold" }}>{selectedUser?.name}</span>{" "}
            adlı kullanıcıyı {selectedUser?.isActive ? "pasife" : "aktife"}{" "}
            almak istediğinizden emin misiniz?
          </MDTypography>
        )}
      </AlertDialog>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
      />
    </DashboardLayout>
  );
}
