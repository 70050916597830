// FormField.js
import React, { useState } from "react";

import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import FormFieldTitle from "../FormFieldTitle";

import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const FormField = ({
  label,
  name,
  value,
  error,
  errorMessage,
  onChange,
  type = "text",
  placeholder = "",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const isPasswordField = type === "password";

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const getInputType = () => {
    if (isPasswordField) {
      return showPassword ? "text" : "password";
    }
    return type;
  };

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
    >
      <FormFieldTitle>{label}</FormFieldTitle>
      <MDBox mb={1} width="100%">
        <MDInput
          type={getInputType()}
          fullWidth
          name={name}
          value={value}
          onChange={onChange}
          error={error}
          placeholder={placeholder}
          InputProps={{
            endAdornment: isPasswordField && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {error && (
          <MDTypography variant="caption" color="error" fontWeight="light" mx={1}>
            {errorMessage}
          </MDTypography>
        )}
      </MDBox>
    </MDBox>
  );
};

export default FormField;
