import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Grid, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { selectLiveCastUrl, selectLiveCastStartTime } from "slices/AdminPanel";
import { Link } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import InfoIcon from "@mui/icons-material/Info";
import { findUserStatus } from "helpers";

export default function LiveStreamCard() {
  const liveCastUrl = useSelector(selectLiveCastUrl);
  const liveCastStartTime = useSelector(selectLiveCastStartTime);
  const [currentTime, setCurrentTime] = useState(new Date());

  const isActive = findUserStatus();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);

  const getTimeDifference = (startTime) => {
    const start = new Date(startTime); // This is in UTC
    const now = currentTime; // This is in your local time (GMT+03:00)

    const diffInMs = now.getTime() - start.getTime();

    const diffInMinutes = Math.floor(diffInMs / 60000);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInMinutes === 0) return "az önce"; // 1 dakikadan az bir süre geçtiyse
    if (diffInMinutes < 60) return `${diffInMinutes} dakika önce`;
    if (diffInHours < 24) return `${diffInHours} saat önce`;
    return `${diffInDays} gün önce`;
  };

  return (
    <Card
      sx={{
        padding: 2,
        overflow: "hidden",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} display="flex" alignItems="center">
          <MDBox
            sx={{
              flex: 1,
              paddingX: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="dark">
              Canlı Yayın
            </MDTypography>
            {liveCastUrl?.length > 1 && (
              <MDBox
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "red",
                  marginLeft: 1,
                  animation: "pulse 1.5s infinite",
                  "@keyframes pulse": {
                    "0%": { transform: "scale(0.9)" },
                    "50%": { transform: "scale(1.2)" },
                    "100%": { transform: "scale(0.9)" },
                  },
                }}
              />
            )}
          </MDBox>
        </Grid>
        {liveCastUrl?.length > 1 && liveCastStartTime && (
          <Grid item xs={12}>
            <MDBox display="flex" alignItems="center" px={2}>
              <MDTypography
                variant="button"
                color="text"
                lineHeight={1}
                sx={{ mt: 0.5, mr: 0.5 }}
              >
                <Icon>schedule</Icon>
              </MDTypography>
              <MDTypography
                variant="caption"
                color="text"
                fontWeight="light"
                ml={0.5}
              >
                {`Yayın ${getTimeDifference(liveCastStartTime)} başladı`}
              </MDTypography>
            </MDBox>
          </Grid>
        )}
        <Grid item xs={12} display="flex" alignItems="center">
          {liveCastUrl?.length > 1 ? (
            <MDTypography
              variant="button"
              fontWeight="bold"
              color="text"
              px={2}
            >
              <LinkIcon
                color="orange"
                fontSize="small"
                sx={{ marginRight: 0.5, marginBottom: 0.2 }}
              />
              {isActive ? (
                <Link
                  to={liveCastUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    background: "linear-gradient(90deg, #FF5F00, #ff8a00)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    textDecoration: "none",
                  }}
                >
                  Canlı yayına katılmak için tıklayın!
                </Link>
              ) : (
                <MDTypography
                  color="orange"
                  textGradient
                  variant="body2"
                  sx={{ fontSize: 14 }}
                  fontWeight="bold"
                >
                  Canlı yayına katılmak için aktif üye olmalısınız!
                </MDTypography>
              )}
            </MDTypography>
          ) : (
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="bold"
              px={2}
            >
              Şu anda gerçekleşen bir canlı yayın bulunmamaktadır.
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center" px={2} m={2}>
          <MDTypography variant="caption" color="text">
            <MDTypography
              variant="button"
              color="text"
              lineHeight={1}
              sx={{ mt: 0.15, mr: 0.5 }}
            >
              <InfoIcon />
            </MDTypography>
            Eğer canlı yayın ile ilgili bir hata ya da yanlışlık olduğunu
            düşünüyorsanız, lütfen eğitmeniniz ile iletişime geçiniz.
          </MDTypography>
        </Grid>
      </Grid>
    </Card>
  );
}
