import { createSlice } from "@reduxjs/toolkit";
import { fetchCompletedItems, videoComplete, testComplete } from "./services";

const statisticSlice = createSlice({
  name: "statistics",
  initialState: {
    completedVideos: [],
    completedTests: [],
    status: "idle",
    error: null,
  },
  reducers: {
    resetCompletedItems(state) {
      state.completedVideos = [];
      state.completedTests = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Video Complete
      .addCase(videoComplete.pending, (state) => {
        state.status = "loading";
      })
      .addCase(videoComplete.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(videoComplete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Test Complete
      .addCase(testComplete.pending, (state) => {
        state.status = "loading";
      })
      .addCase(testComplete.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(testComplete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch Completed Items (Videos and Tests)
      .addCase(fetchCompletedItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCompletedItems.fulfilled, (state, action) => {
        state.completedVideos = action.payload.completedVideos;
        state.completedTests = action.payload.completedTests;
        state.isLoading = false;
      })
      .addCase(fetchCompletedItems.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetCompletedItems } = statisticSlice.actions;

export default statisticSlice.reducer;
