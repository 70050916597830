import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormInput from "components/Custom/FormInput";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "components/Custom/Dialog";
import Header from "components/Header";

// Authentication layout components
import BasicLayoutLanding from "examples/LayoutContainers/BasicLayoutLanding";

// Assets
import bgImage from "assets/images/welcome/welcome-slider-3.jpg";
import typography from "assets/theme/base/typography";

// Authentication service
import AuthService from "services/auth-service";

// @mui material components
import {
  AppBar,
  Autocomplete,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Redux-actions
import { login } from "slices/Auth/authSlice";

// Helpers
import { mailFormat } from "helpers/index";
import { phoneFormat } from "helpers/index";

// Documents
import { TermsAndConditions } from "../../documents/termsAndConditions";
import Item from "components/Custom/Item";

const { size, fontWeightRegular } = typography;

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiTypography-root": {
    fontWeight: fontWeightRegular,
    color: theme.palette.text.main,
  },
}));

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [open, setOpen] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    username: "",
    email: "",
    phoneNumber: "",
    password: "",
    gender: "",
    agree: false,
  });

  const [errors, setErrors] = useState({
    nameError: false,
    usernameError: false,
    emailError: false,
    phoneNumberError: false,
    passwordError: false,
    genderError: false,
    agreeError: false,
    error: false,
    errorText: "",
  });

  const handleCitySelect = (event, newValue) => {
    setSelectedCity(newValue);
    setInputs({ ...inputs, city: newValue });
  };

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 3500);
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name + "Error"]: false,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (inputs.name.trim().length === 0) {
      setErrors({ ...errors, nameError: true });
      return;
    }

    if (inputs.username.trim().length === 0) {
      setErrors({ ...errors, usernameError: true });
      return;
    }

    if (inputs.password.trim().length < 8) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    if (!inputs.email.match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (!inputs.phoneNumber.trim().match(phoneFormat)) {
      setErrors({ ...errors, phoneNumberError: true });
      return;
    }

    if (!inputs.gender) {
      setErrors({ ...errors, genderError: true });
      return;
    }

    if (inputs.agree === false) {
      setErrors({ ...errors, agreeError: true });
      return;
    }

    setDisabled(true);

    // here will be the post action to add a user to the db
    const newUser = {
      name: inputs.name,
      username: inputs.username,
      email: inputs.email,
      phoneNumber: inputs.phoneNumber,
      password: inputs.password,
      gender: inputs.gender,
      isActive: false,
      role: "student",
      registrationDate: new Date().toISOString(), // Adds the current date and time in ISO format
      city: selectedCity,
    };

    const myData = {
      data: {
        type: "users",
        attributes: { ...newUser, password_confirmation: newUser.password },
        relationships: {
          roles: {
            data: [
              {
                type: "roles",
                id: "1",
              },
            ],
          },
        },
      },
    };

    try {
      const response = await AuthService.register(myData);
      dispatch(login(response.access_token, response.refresh_token));
      if (response.access_token) {
        openSB(
          "success",
          "check",
          "Başarılı",
          <MDTypography
            variant={isXsScreen ? "caption" : "button"}
            color="white"
          >
            Kayıt olma işlemi başarılı, hoş geldiniz{" "}
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              {inputs.name}
            </span>
          </MDTypography>
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      }
      setInputs({
        name: "",
        username: "",
        email: "",
        phoneNumber: "",
        password: "",
        agree: false,
      });
      setSelectedCity("");

      setErrors({
        nameError: false,
        usernameError: false,
        emailError: false,
        phoneNumberError: false,
        passwordError: false,
        agreeError: false,
        error: false,
        errorText: "",
      });
    } catch (err) {
      openSB(
        "error",
        "warning",
        "Hata",
        <MDTypography variant={isXsScreen ? "caption" : "button"} color="white">
          {err.hasOwnProperty("message")
            ? err.message
            : "Kayıt olma işlemi başarısız"}
        </MDTypography>
      );
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    fetch("https://turkiyeapi.dev/api/v1/provinces")
      .then((response) => response.json())
      .then((data) => setCities(data.data))
      .catch((error) => console.error("Error fetching cities:", error));
  }, []);

  return (
    <BasicLayoutLanding image={bgImage} flip={true}>
      <MDBox pt={!isXsScreen && 4}>
        <MDBox
          component="form"
          role="form"
          method="POST"
          onSubmit={submitHandler}
          style={{ maxHeight: "calc(100vh - 3.5rem)" }}
        >
          <MDBox pt={isXsScreen ? 5 : 3} textAlign="center">
            <MDTypography
              variant={isXsScreen ? "h5" : "h4"}
              fontWeight="medium"
              sx={{ color: "#17171" }}
            >
              Sen de aramıza katıl!
            </MDTypography>
            <MDTypography
              display="block"
              variant={isXsScreen ? "button" : "body2"}
              sx={{ color: "#17171" }}
              my={1}
            >
              Kayıt işleminizi tamamlamak için lütfen aşağıdaki formu doldurun.
            </MDTypography>
          </MDBox>
          <FormInput
            label="Ad - Soyad"
            name="name"
            type="text"
            value={inputs.name}
            onChange={changeHandler}
            error={errors.nameError}
            errorText="Ad - Soyad boş olamaz"
          />

          <FormInput
            label="Kullanıcı adı"
            name="username"
            type="text"
            value={inputs.username}
            onChange={changeHandler}
            error={errors.usernameError}
            errorText="Kullanıcı adı boş olamaz"
          />

          <FormInput
            label="Parola"
            name="password"
            type="password"
            value={inputs.password}
            onChange={changeHandler}
            error={errors.passwordError}
            errorText="Parolanız en az 8 karakterden oluşmalıdır"
            autoComplete="new-password"
          />

          <FormInput
            label="E-posta adresi"
            name="email"
            type="email"
            value={inputs.email}
            onChange={changeHandler}
            error={errors.emailError}
            errorText="Geçerli bir e-posta girin"
          />

          <FormInput
            label="Telefon numarası ( 05XX XXX XXXX )"
            name="phoneNumber"
            type="phone"
            value={inputs.phoneNumber}
            onChange={changeHandler}
            error={errors.phoneNumberError}
            errorText="Geçerli bir telefon numarası girin"
          />

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={cities.map((city) => city.name)}
            value={selectedCity}
            onChange={handleCitySelect}
            renderInput={(params) => (
              <TextField
                sx={{ marginBottom: 3 }}
                {...params}
                variant="standard"
                label="Şehir ( isteğe bağlı )"
              />
            )}
          />

          <MDBox display="flex" flexDirection="column">
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              alignSelf="flex-start"
            >
              Cinsiyet Bilgisi
            </MDTypography>
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={inputs.gender}
              onChange={changeHandler}
              row
            >
              <CustomFormControlLabel
                value="male"
                control={<Radio sx={{ marginTop: 0.5 }} />}
                label="Erkek"
              />
              <CustomFormControlLabel
                value="female"
                control={<Radio sx={{ marginTop: 0.5 }} />}
                label="Kadın"
              />
              <CustomFormControlLabel
                value="other"
                control={<Radio sx={{ marginTop: 0.5 }} />}
                label="Belirtmek İstemiyorum"
              />
            </RadioGroup>
            {errors.genderError && (
              <MDBox width="100%" display="flex">
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  Cinsiyet seçiniz
                </MDTypography>
              </MDBox>
            )}
          </MDBox>

          <MDBox display="flex" alignItems="center" ml={-1.5} mt={2}>
            <Checkbox
              name="agree"
              id="agree"
              onChange={(e) =>
                setInputs({ ...inputs, agree: e.target.checked })
              }
              color="secondary"
            />
            <MDTypography
              component={Link}
              variant="button"
              fontWeight="bold"
              color="info"
              mr={0.5}
            >
              <Item text={"Şartlar ve Koşulları"} isXsScreen={isXsScreen} dark>
                <TermsAndConditions />
              </Item>
            </MDTypography>
            <InputLabel
              variant="standard"
              fontWeight="regular"
              color="text"
              sx={{ lineHeight: "1.5", cursor: "pointer" }}
              htmlFor="agree"
            >
              kabul ediyorum
            </InputLabel>
          </MDBox>
          {errors.agreeError && (
            <MDBox width="100%" display="flex">
              <MDTypography variant="caption" color="error" fontWeight="light">
                Şartlar ve koşulları kabul etmelisiniz
              </MDTypography>
            </MDBox>
          )}
          {errors.error && (
            <MDTypography variant="caption" color="error" fontWeight="light">
              {errors.errorText}
            </MDTypography>
          )}
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              type="submit"
              disabled={disabled}
              size={isXsScreen ? "small" : "medium"}
            >
              ücretsiz kayıt ol
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Zaten bir hesabınız var mı?{" "}
              <MDTypography
                component={Link}
                to="/auth/login"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Giriş yap
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
        bgGreen
      />
      <AlertDialog
        width="lg"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        title="Şartlar ve Koşullar"
        hasNoAction
        isXsScreen={isXsScreen}
      >
        <MDBox display="flex" flexDirection="column" textAlign="justify" p={1}>
          <TermsAndConditions />
        </MDBox>
      </AlertDialog>
    </BasicLayoutLanding>
  );
}

export default Register;
