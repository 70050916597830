import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./slices/Auth/authSlice";
import usersReducer from "./slices/Users";
import videosReducer from "./slices/Videos/index";
import statisticsReducer from "./slices/Statistics/index";
import scheduleReducer from "./slices/Schedule/index";
import meReducer from "./slices/Me/index";
import quizzesReducer from "./slices/Quizzes/index";
import commonReducer from "./slices/Common/index";
import contactReducer from "./slices/Contact/index";
import adminPanelReducer from "./slices/AdminPanel/index";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    videos: videosReducer,
    statistics: statisticsReducer,
    schedule: scheduleReducer,
    me: meReducer,
    quizzes: quizzesReducer,
    common: commonReducer,
    contact: contactReducer,
    adminPanel: adminPanelReducer,
  },
});

export default store;
