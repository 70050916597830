import React from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import pattern from "assets/images/illustrations/pattern-tree.svg";
import visaLogo from "assets/images/logos/visa.png";
import masterCardLogo from "assets/images/logos/mastercard.png";

function MasterCard({ color, number, holder, expires, cardType }) {
  const maskedNumber = number
    .padEnd(16, "X")
    .replace(/(.{4})/g, "$1 ")
    .trim();

  return (
    <Card
      sx={({
        palette: { gradients },
        functions: { linearGradient },
        boxShadows: { xl },
      }) => ({
        background: gradients[color]
          ? linearGradient(gradients[color].main, gradients[color].state)
          : linearGradient(gradients.dark.main, gradients.dark.state),
        boxShadow: xl,
        position: "relative",
        height: "100%",
      })}
    >
      <MDBox
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        opacity={0.1}
        sx={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: "cover",
        }}
      />
      <MDBox position="relative" zIndex={2} p={2}>
        <MDBox color="white" p={1} lineHeight={0} display="inline-block">
          <Icon fontSize="default">wifi</Icon>
        </MDBox>
        <MDTypography
          variant="h5"
          color="white"
          fontWeight="medium"
          sx={{ mt: 3, mb: 5, pb: 1 }}
        >
          {maskedNumber}
        </MDTypography>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDBox display="flex" alignItems="center">
            <MDBox mr={3} lineHeight={1}>
              <MDTypography
                variant="button"
                color="white"
                fontWeight="regular"
                opacity={0.8}
              >
                KART SAHİBİ
              </MDTypography>
              <MDTypography
                variant="h6"
                color="white"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {holder}
              </MDTypography>
              {!holder && (
                <MDTypography
                  variant="h6"
                  color="white"
                  fontWeight="medium"
                  sx={{ visibility: "hidden" }}
                >
                  .
                </MDTypography>
              )}
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography
                variant="button"
                color="white"
                fontWeight="regular"
                opacity={0.8}
              >
                EXP DATE{" "}
              </MDTypography>
              <MDTypography variant="h6" color="white" fontWeight="medium">
                {expires}
              </MDTypography>
            </MDBox>
          </MDBox>
            <MDBox display="flex" justifyContent="flex-end" width="20%">
              <MDBox
                component="img"
                src={cardType === "mastercard" ? masterCardLogo : visaLogo}
                alt="master card"
                width="60%"
                mt={1}
              />
            </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of MasterCard
MasterCard.defaultProps = {
  color: "dark",
};

// Typechecking props for the MasterCard
MasterCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  number: PropTypes.string.isRequired,
  holder: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
};

export default MasterCard;
