import { createSlice } from "@reduxjs/toolkit";
import {
  uploadQuizzes,
  fetchQuizzes,
  deleteQuizzes,
  fetchQuizGroups,
} from "./services";

const quizzesSlice = createSlice({
  name: "quizzes",
  initialState: {
    items: [],
    groups: [],
    error: null,
    uploadQuizzes: {
      status: "idle",
      error: null,
    },
    fetchQuizzes: {
      status: "idle",
      error: null,
    },
    deleteQuiz: {
      status: "idle",
      error: null,
    },
    updateAccessibility: {
      status: "idle",
      error: null,
    },
    deleteQuizzes: {
      status: "idle",
      error: null,
    },
    fetchQuizGroups: {
      status: "idle",
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Upload Quiz
      .addCase(uploadQuizzes.pending, (state) => {
        state.uploadQuizzes.status = "loading";
      })
      .addCase(uploadQuizzes.fulfilled, (state, action) => {
        state.uploadQuizzes.status = "succeeded";
        state.items = action.payload.quizzes;
      })
      .addCase(uploadQuizzes.rejected, (state, action) => {
        state.uploadQuizzes.status = "failed";
        state.uploadQuizzes.error = action.payload;
      })

      // Fetch Quizzes
      .addCase(fetchQuizzes.pending, (state) => {
        state.fetchQuizzes.status = "loading";
      })
      .addCase(fetchQuizzes.fulfilled, (state, action) => {
        state.fetchQuizzes.status = "succeeded";
        state.items = action.payload.quizzes;
      })
      .addCase(fetchQuizzes.rejected, (state, action) => {
        state.fetchQuizzes.status = "failed";
        state.fetchQuizzes.error = action.payload;
      })

      // Fetch Quiz Groups
      .addCase(fetchQuizGroups.pending, (state) => {
        state.fetchQuizGroups.status = "loading";
      })
      .addCase(fetchQuizGroups.fulfilled, (state, action) => {
        state.fetchQuizGroups.status = "succeeded";
        state.groups = action.payload;
      })
      .addCase(fetchQuizGroups.rejected, (state, action) => {
        state.fetchQuizGroups.status = "failed";
        state.fetchQuizGroups.error = action.payload;
      })

      // Delete Quizzes
      .addCase(deleteQuizzes.pending, (state) => {
        state.deleteQuizzes.status = "loading";
      })
      .addCase(deleteQuizzes.fulfilled, (state, action) => {
        state.deleteQuizzes.status = "succeeded";
        state.items = state.items.filter(
          (quiz) => quiz.mainTopic !== action.payload
        );
      })
      .addCase(deleteQuizzes.rejected, (state, action) => {
        state.deleteQuizzes.status = "failed";
        state.deleteQuizzes.error = action.payload;
      });
  },
});

export default quizzesSlice.reducer;
