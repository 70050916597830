// React essentials
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Redux actions
import { fetchVideos } from "slices/Videos/services";
import { fetchCompletedItems } from "slices/Statistics/services";
import { resetCompletedItems } from "slices/Statistics";

// Helper functions
import { formatDuration } from "helpers/index";

function GeneralSituation({ videos, title, icon }) {
  const dispatch = useDispatch();
  const { userId: paramUserId } = useParams();
  const location = useLocation();

  const completedVideos = useSelector(
    (state) => state.statistics.completedVideos || []
  );

  const completionRates = videos.reduce((acc, video) => {
    const isCompleted = completedVideos.some(
      (item) => item.videoId === video._id
    );
    const topicKey = video.mainTopic || video.guidanceMainTopic;
    acc[topicKey] = acc[topicKey] || { completed: 0, total: 0 };
    acc[topicKey].total += 1;
    if (isCompleted) {
      acc[topicKey].completed += 1;
    }

    return acc;
  }, {});

  const topicCounts = videos.reduce((acc, video) => {
    const topicKey = video.mainTopic || video.guidanceMainTopic;
    acc[topicKey] = (acc[topicKey] || 0) + 1;
    return acc;
  }, {});

  const durations = videos.reduce((acc, video) => {
    const topicKey = video.mainTopic || video.guidanceMainTopic;
    acc[topicKey] = (acc[topicKey] || 0) + video.duration;
    return acc;
  }, {});

  const uniqueTopics = new Set(
    videos.map((video) => video.mainTopic || video.guidanceMainTopic)
  );

  const columns = videos.some((video) => video.videoType === "guidance")
    ? [
        {
          Header: "Rehberlik Videosu Adı",
          accessor: "subjects",
          width: "40%",
          align: "left",
        },
        {
          Header: "Rehberlik Videosu Süresi",
          accessor: "totalLessonHours",
          width: "30%",
          align: "left",
        },
        {
          Header: "Durum",
          accessor: "completion",
          width: "30%",
          align: "center",
        },
      ]
    : [
        {
          Header: "Konu Adı",
          accessor: "subjects",
          width: "20%",
          align: "left",
        },
        {
          Header: "Toplam Ders Sayısı",
          accessor: "totalLessonCount",
          width: "25%",
          align: "left",
        },
        {
          Header: "Tamamlanan Ders Sayısı",
          accessor: "completedVideosCount",
          width: "25%",
          align: "left",
        },
        {
          Header: "Toplam Ders Süresi",
          accessor: "totalLessonHours",
          width: "25%",
          align: "left",
        },
        {
          Header: "Tamamlanma Oranı",
          accessor: "completion",
          width: "30%",
          align: "center",
        },
      ];

  const rows = Array.from(uniqueTopics).map((topic) => {
    const { completed, total } = completionRates[topic];
    const completionRate = total > 0 ? (completed / total) * 100 : 0;

    const isGuidance = videos.some((video) => video.videoType === "guidance");

    return {
      subjects: topic,
      totalLessonCount: videos.some((video) => video.videoType === "guidance")
        ? undefined
        : topicCounts[topic],
      completedVideosCount: completionRates[topic].total,
      totalLessonHours: formatDuration(durations[topic]), // Dinamik olarak formatlanmış süre
      completion: isGuidance ? (
        <MDTypography color="text" variant="button" fontWeight="regular">
          {completionRate === 100 ? "Tamamlandı" : "Başlanmadı"}
        </MDTypography>
      ) : (
        <MDBox width="8rem" textAlign="left">
          <MDProgress
            value={completionRate?.toFixed(0)}
            color="info"
            variant="gradient"
            label={true}
          />
        </MDBox>
      ),
    };
  });

  useEffect(() => {
    let userId;
    if (location.pathname.startsWith("/user-management")) {
      userId = paramUserId;
    } else {
      const token = Cookies.get("token");
      const decodedToken = jwtDecode(token);
      userId = decodedToken?.id;
    }
    if (userId) {
      dispatch(resetCompletedItems());
      dispatch(fetchCompletedItems(userId));
      dispatch(fetchVideos());
    }
  }, [dispatch, location.pathname, paramUserId]);

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox display="flex" alignItems="center">
          {icon}
          <MDTypography variant="h6" gutterBottom ml={1}>
            {title}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mb={3}>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default GeneralSituation;
