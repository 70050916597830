import { styled } from "@mui/material";

export const HoverOverlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  transition: "opacity 0.3s ease",
  borderRadius: ".5rem",
  "&:hover": {
    opacity: 1,
    cursor: "pointer",
  },
}));

