import React from "react";
import { CircularProgress, Box } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function TrophyProgress({ completed, total, isXsScreen }) {
  const normalise = (value) => ((value - 0) * 100) / (total - 0); // Normalizes the value to be within 0 to 100

  return (
    <MDBox position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={completed > 0 ? normalise(completed) : 100} // completed 0 ise de 100 olarak göster
        size={isXsScreen ? 33 : 44}
        thickness={completed > 0 ? 3.5 : 3}
        sx={{
          color: completed > 0 ? "#9DADCD" : "#495057", // completed 0 ise daha açık renk
          position: "absolute",
          top: isXsScreen ? -18 : -26,
          left: isXsScreen ? -17 : -23,
          zIndex: 1,
        }}
      />
      <MDBox
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ color: "secondary.main" }}
      >
        <EmojiEventsIcon
          fontSize={isXsScreen ? "small" : "medium"}
          color={normalise(completed) === 100 ? "teal" : "secondary"}
          sx={{ marginBottom: isXsScreen ? 0.5 : 1 }}
        />
      </MDBox>
    </MDBox>
  );
}
