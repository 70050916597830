// React essantials
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Material UI components
import {
  Card,
  CardMedia,
  InputAdornment,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Material UI icons
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import LayersIcon from "@mui/icons-material/Layers";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import SearchIcon from "@mui/icons-material/Search";

// Custom components
import Header from "components/Custom/Header";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AdviceCard from "components/Custom/AdviceCard";
import { HoverOverlay } from "components/Custom/HoverOverlay";
import { CustomTextField } from "components/Custom/TextField";

// Redux slices
import { fetchVideos } from "slices/Videos/services";
import { fetchCompletedItems } from "slices/Statistics/services";
import { fetchQuizGroups } from "slices/Quizzes/services";
import { resetCompletedItems } from "slices/Statistics";

// Helper functions
import { formatDuration } from "helpers/index";
import { findUserId } from "helpers";
import LoadingPage from "../loading";

const AspectRatioBox = styled("div")({
  width: "100%",
  paddingBottom: "56.25%",
  position: "relative",
});

export default function Index() {
  const dispatch = useDispatch();
  const userId = findUserId();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);

  const videos = useSelector((state) => state.videos.items);
  const quizGroups = useSelector((state) => state.quizzes.groups || []);

  const completedVideos = useSelector(
    (state) => state.statistics.completedVideos || []
  );
  const completedTests = useSelector(
    (state) => state.statistics.completedTests || []
  );
  const [searchValue, setSearchValue] = useState("");

  const organizedVideos = useMemo(() => {
    const groups = {};
    videos.forEach((video) => {
      if (video.isAccessible && video.videoType === "lesson") {
        const topic = video.mainTopic;
        if (!groups[topic]) {
          groups[topic] = {
            videos: [],
            thumbnailUrl: "",
            completedCount: 0,
            totalCount: 0,
            totalDuration: 0,
            quizzes: [],
            completedQuizzesCount: 0,
          };
        }
        groups[topic].videos.push(video);
        groups[topic].totalCount += 1;
        groups[topic].totalDuration += video.duration;

        if (
          completedVideos.some(
            (completedVideo) => completedVideo.videoId === video._id
          )
        ) {
          groups[topic].completedCount += 1;
        }

        if (!groups[topic].thumbnailUrl && video.thumbnailUrl) {
          groups[topic].thumbnailUrl = video.thumbnailUrl;
        }

        if (video.isAccessible) {
          groups[topic].anyAccessible = true;
        }
      }
    });

    quizGroups.forEach((quizGroup) => {
      const topic = quizGroup.mainTopic.split(" - ")[0];
      if (groups[topic]) {
        groups[topic].quizzes.push(quizGroup);
        groups[topic].totalCount += 1;

        if (
          completedTests.some(
            (completedTest) => completedTest.testId === quizGroup._id
          )
        ) {
          groups[topic].completedQuizzesCount += 1;
        }
      }
    });

    Object.values(groups).forEach((group) => {
      const completedItemsCount =
        group.completedCount + group.completedQuizzesCount;
      const totalItemsCount = group.totalCount;

      group.completionPercentage =
        totalItemsCount > 0 ? (completedItemsCount / totalItemsCount) * 100 : 0;
    });

    return Object.fromEntries(
      Object.entries(groups).filter(([, group]) => group.anyAccessible)
    );
  }, [videos, completedVideos, quizGroups, completedTests]);

  const filteredVideos = useMemo(() => {
    if (!searchValue.trim()) return organizedVideos;

    const lowercasedSearchValue = searchValue.toLowerCase();

    const filteredEntries = Object.entries(organizedVideos).filter(
      ([mainTopic, data]) =>
        mainTopic.toLowerCase().includes(lowercasedSearchValue)
    );

    return filteredEntries.reduce((acc, [mainTopic, data]) => {
      acc[mainTopic] = data;
      return acc;
    }, {});
  }, [organizedVideos, searchValue]);

  const handleChange = (value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (!userId) {
      return <LoadingPage />;
    }
    dispatch(resetCompletedItems());
    dispatch(fetchCompletedItems(userId));
    dispatch(fetchQuizGroups());
    dispatch(fetchVideos())
      .then((response) => {
        if (response.type === "videos/fetchVideos/fulfilled") {
          setTimeout(() => setLoading(false), 500); 
        } else if (response.type === "videos/fetchVideos/rejected") {
          console.error("Error fetching videos:", response.error.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Unexpected error:", error);
        setLoading(false);
      });
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Konu Anlatımları" />
      <MDBox display="flex" justifyContent="center" mt={3}>
        <MDBox width="100%">
          <Card sx={{ padding: 3 }}>
            <MDBox
              width="100%"
              display="flex"
              justifyContent={isXsScreen ? "center" : "flex-end"}
            >
              <CustomTextField
                label="Derslerimde ara"
                variant="outlined"
                onChange={(e) => handleChange(e.target.value)}
                sx={{ marginRight: !isXsScreen && 3 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon fontSize="medium" />
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            {Object.entries(filteredVideos).length === 0 ? (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={1}
                mt={1}
                mb={3}
              >
                <MDTypography variant="body2" color="text" fontWeight="regular">
                  İçerik Bulunamadı
                </MDTypography>
              </MDBox>
            ) : (
              <MDBox
                display="grid"
                gridTemplateColumns={{
                  xs: "1fr", // 100% width for extra small devices
                  sm: "repeat(2, 1fr)", // 2 columns for small devices
                  md: "repeat(3, 1fr)", // 3 columns for medium devices
                  lg: "repeat(4, 1fr)", // 4 columns for large devices
                }}
                columnGap={0} // Sets the horizontal gap between columns
                rowGap={1} // Sets the vertical gap between rows
                width="100%"
                sx={{
                  maxWidth: 1200,
                  margin: "0 auto", // Centers the grid container
                  padding: "36px 36px", // Optional: padding around the grid to avoid touching the edges
                }}
              >
                {Object.entries(filteredVideos).map(([mainTopic, data]) => (
                  <MDBox
                    key={mainTopic}
                    sx={{
                      flexBasis: "calc(25% - 16px)",
                      flexGrow: 1,
                      maxWidth: "250px",
                      margin: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      boxSizing: "border-box", // Ensure padding and borders are included in the width calculation
                    }}
                  >
                    <Card sx={{ width: "100%" }}>
                      <Link
                        to={`/lessons/${mainTopic}`}
                        style={{ textDecoration: "none", flexGrow: 1 }}
                      >
                        <Card
                          sx={{
                            height: 140,
                            width: "100%",
                          }}
                        >
                          <AspectRatioBox>
                            <CardMedia
                              sx={{
                                height: 140,
                                margin: "auto",
                                margin: 0,
                                borderRadius: ".5rem",
                                objectFit: "cover",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                width: "100%",
                              }}
                              component="img"
                              image={data.thumbnailUrl}
                              alt={`Thumbnail for ${mainTopic}`}
                            />
                          </AspectRatioBox>
                          <HoverOverlay>
                            <PlayCircleIcon fontSize="large" color="white" />
                          </HoverOverlay>
                        </Card>
                      </Link>
                    </Card>

                    <MDBox
                      m={1}
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        flex: "1 0 auto",
                        width: "100%",
                      }}
                    >
                      <MDTypography gutterBottom variant="h6" mb={-1}>
                        {mainTopic}
                      </MDTypography>
                      <MDBox
                        mt={2}
                        width="100%"
                        textAlign="left"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <MDBox display="flex" alignItems="center">
                          <LayersIcon color="secondary" />
                          <MDTypography variant="caption" color="text" ml={0.2}>
                            {data?.totalCount - data?.quizzes?.length} Ders{" "}
                            {data?.quizzes?.length !== 0 && ", "}
                            {data?.quizzes?.length !== 0
                              ? `${data?.quizzes?.length} Konu Testi`
                              : ""}
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          <AccessTimeFilledIcon color="secondary" />
                          <MDTypography variant="caption" color="text" ml={0.2}>
                            {formatDuration(data?.totalDuration)}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox mt={1} width="100%" textAlign="left">
                        <MDProgress
                          value={data.completionPercentage}
                          color="orange"
                          variant="gradient"
                          label={false}
                        />
                        <MDTypography
                          gutterBottom
                          variant="caption"
                          fontWeight="bold"
                          mt={1}
                        >
                          %{data.completionPercentage?.toFixed(0)} tamamlandı
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                ))}
                {Object.keys(filteredVideos).length % 2 === 1 && (
                  <MDBox
                    sx={{
                      visibility: "hidden",
                      flexBasis: "calc(25% - 16px)",
                      flexGrow: 1,
                      maxWidth: "250px",
                      margin: "8px",
                    }}
                  />
                )}
              </MDBox>
            )}
          </Card>
        </MDBox>
      </MDBox>
      <AdviceCard
        title={"Öğrenim Zamanınızı Planlayın"}
        description={
          "Her gün belirli zamanlarınızı öğrenmeye ayırarak başarıya gidenyolda emin adımlar atın. Araştırmalar, düzenli çalışma programlarınasahip öğrencilerin hedeflerine daha çabuk ulaştığını gösteriyor. Enverimli öğrenme rutinlerinizi belirleyin ve bu alışkanlıklarıpekiştirin."
        }
        icon={<AccessAlarmIcon sx={{ fontSize: 30, color: "#495057" }} />}
      />
    </DashboardLayout>
  );
}
