// FormInput.js
import React from "react";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const FormInput = ({
  label,
  name,
  type,
  value,
  onChange,
  error,
  errorText,
  autoComplete,
}) => {
  return (
    <MDBox mb={2}>
      <MDInput
        type={type}
        label={label}
        variant="standard"
        fullWidth
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        inputProps={{
          autoComplete: autoComplete,
        }}
      />
      {error && (
        <MDBox display="flex" justifyContent="start" mt={1}>
          <MDTypography variant="caption" color="error" fontWeight="light">
            {errorText}
          </MDTypography>
        </MDBox>
      )}
    </MDBox>
  );
};

export default FormInput;
