import { createSlice } from "@reduxjs/toolkit";
import {
  deleteVideo,
  fetchVideos,
  uploadVideos,
  updateAccessibility,
  saveVideoOrder,
  uploadGuidanceVideos
} from "./services";

const videosSlice = createSlice({
  name: "videos",
  urls: [],
  initialState: {
    items: [],
    error: null,
    uploadVideos: {
      status: "idle",
      error: null,
    },
    fetchVideos: {
      status: "idle",
      error: null,
    },
    deleteVideo: {
      status: "idle",
      error: null,
    },
    updateAccessibility: {
      status: "idle",
      error: null,
    },
    saveOrder: {
      status: "idle",
      error: null,
    },
    uploadGuidance: {
      status: "idle",
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Upload Video
      .addCase(uploadVideos.pending, (state) => {
        state.uploadVideos.status = "loading";
      })
      .addCase(uploadVideos.fulfilled, (state, action) => {
        state.uploadVideos.status = "succeeded";
        state.items = action.payload.videos;
      })
      .addCase(uploadVideos.rejected, (state, action) => {
        state.uploadVideos.status = "failed";
        state.uploadVideos.error = action.payload;
      })

      // Fetch Video
      .addCase(fetchVideos.pending, (state) => {
        state.fetchVideos.status = "loading";
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.fetchVideos.status = "succeeded";
        state.items = action.payload.videos;
      })
      .addCase(fetchVideos.rejected, (state, action) => {
        state.fetchVideos.status = "failed";
        state.fetchVideos.error = action.payload;
      })

      // Delete Video
      .addCase(deleteVideo.pending, (state) => {
        state.deleteVideo.status = "loading";
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.deleteVideo.status = "succeeded";
        state.items = action.payload.videos;
      })
      .addCase(deleteVideo.rejected, (state, action) => {
        state.deleteVideo.status = "failed";
        state.deleteVideo.error = action.payload;
      })

      // Update Video Accesibility
      .addCase(updateAccessibility.pending, (state) => {
        state.updateAccessibility.status = "loading";
      })
      .addCase(updateAccessibility.fulfilled, (state, action) => {
        state.updateAccessibility.status = "succeeded";
        state.items = action.payload.videos;
      })
      .addCase(updateAccessibility.rejected, (state, action) => {
        state.updateAccessibility.status = "failed";
        state.updateAccessibility.error = action.payload;
      })

      // Update Video Order
      .addCase(saveVideoOrder.pending, (state) => {
        state.saveOrder.status = "loading";
      })
      .addCase(saveVideoOrder.fulfilled, (state, action) => {
        state.saveOrder.status = "succeeded";
      })
      .addCase(saveVideoOrder.rejected, (state, action) => {
        state.saveOrder.status = "failed";
        state.saveOrder.error = action.payload;
      })

      // Upload Guidance Video
      .addCase(uploadGuidanceVideos.pending, (state) => {
        state.uploadGuidance.status = "loading";
      })
      .addCase(uploadGuidanceVideos.fulfilled, (state, action) => {
        state.uploadGuidance.status = "succeeded";
        state.items = action.payload.videos;
      })
      .addCase(uploadGuidanceVideos.rejected, (state, action) => {
        state.uploadGuidance.status = "failed";
        state.uploadGuidance.error = action.payload;
      });
  },
});

export default videosSlice.reducer;
