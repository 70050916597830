import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  ListItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Item({
  icon,
  text,
  target,
  children,
  isXsScreen,
  dark,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState();

  return (
    <MDBox>
      <ListItem disablePadding>
        <MDBox
          display="flex"
          alignItems="center"
          sx={{
            margin: !dark && 2,
            marginBottom: !dark && 2,
            paddingBottom: !dark && 1,

            cursor: "pointer",
            "&:hover": {
              opacity: 0.6, // Hafif opaklık
            },
          }}
          onClick={() => {
            if (target) {
              navigate(target);
            } else {
              setOpen(true);
            }
          }}
        >
          {!dark && icon}
          <MDTypography
            variant={isXsScreen ? "caption" : "button"}
            color={dark ? "info" : "white"}
            fontWeight={dark && "bold"}
            mx={!dark && 1}
          >
            {text}
          </MDTypography>
        </MDBox>
      </ListItem>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="lg"
        fullWidth
        fullScreen={isXsScreen}
        sx={{ zIndex: 9999 }}
      >
        <MDBox p={3}>
          <DialogTitle
            sx={{
              margin: 2,
              marginBottom: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            id="alert-dialog-title"
          >
            <MDBox>{text}</MDBox>
            <MDBox>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon color="dark" />
              </IconButton>
            </MDBox>
          </DialogTitle>
          <DialogContent sx={{ textAlign: "justify" }}>
            <MDBox
              display="flex"
              flexDirection="column"
              textAlign="justify"
              p={1}
            >
              {children}
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={() => {
                setOpen(false);
              }}
              variant="outlined"
              color="secondary"
            >
              Kapat
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </MDBox>
  );
}
