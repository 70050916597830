import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ redirectPath = "/auth/login", children }) => {
  const navigate = useNavigate();
  const isAuthenticated = Cookies.get("token");
  if (!isAuthenticated) {
    navigate(redirectPath);
  }

  return children;
};

export default ProtectedRoute;
