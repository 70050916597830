// React essentials
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Material UI components
import {
  Card,
  CardMedia,
  InputAdornment,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Material UI icons
import ExploreIcon from "@mui/icons-material/Explore";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import SearchIcon from "@mui/icons-material/Search";

// Custom components
import Header from "components/Custom/Header";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import AdviceCard from "components/Custom/AdviceCard";
import { CustomTextField } from "components/Custom/TextField";

// Example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Redux slices
import { fetchVideos } from "slices/Videos/services";
import { fetchCompletedItems } from "slices/Statistics/services";

// External libraries
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Helper functions
import { formatDuration, findUserRole } from "helpers/index";

// Loading page
import LoadingPage from "../loading";

const HoverOverlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  transition: "opacity 0.3s ease",
  borderRadius: ".5rem",
  "&:hover": {
    opacity: 1,
    cursor: "pointer",
  },
}));

const AspectRatioBox = styled("div")({
  width: "100%",
  paddingBottom: "56.25%",
  position: "relative",
});

export default function Index() {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const videos = useSelector((state) => state.videos.items);
  const completedVideos = useSelector(
    (state) => state.statistics.completedVideos || []
  );

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const role = findUserRole();
  const isStudent = role === "student";

  const organizedVideos = useMemo(() => {
    const groups = {};
    videos.forEach((video) => {
      if (video.isAccessible && video.videoType === "guidance") {
        const topic = video.guidanceMainTopic;
        if (!groups[topic]) {
          groups[topic] = {
            videos: [],
            thumbnailUrl: "",
            completedCount: 0,
            totalCount: 0,
            totalDuration: 0,
          };
        }
        groups[topic].videos.push(video);
        groups[topic].totalCount += 1;
        groups[topic].totalDuration += video.duration;

        if (
          completedVideos.some(
            (completedVideo) => completedVideo.videoId === video._id
          )
        ) {
          groups[topic].completedCount += 1;
        }

        if (!groups[topic].thumbnailUrl && video.thumbnailUrl) {
          groups[topic].thumbnailUrl = video.thumbnailUrl;
        }

        if (video.isAccessible) {
          groups[topic].anyAccessible = true;
        }
      }
    });

    Object.values(groups).forEach((group) => {
      group.completionPercentage =
        group.totalCount > 0
          ? (group.completedCount / group.totalCount) * 100
          : 0;
    });

    return Object.fromEntries(
      Object.entries(groups).filter(([, group]) => group.anyAccessible)
    );
  }, [videos, completedVideos]);

  const filteredVideos = useMemo(() => {
    if (!searchValue.trim()) return organizedVideos;

    const lowercasedSearchValue = searchValue.toLowerCase();

    const filteredEntries = Object.entries(organizedVideos).filter(
      ([guidanceMainTopic, data]) =>
        guidanceMainTopic.toLowerCase().includes(lowercasedSearchValue)
    );

    return filteredEntries.reduce((acc, [guidanceMainTopic, data]) => {
      acc[guidanceMainTopic] = data;
      return acc;
    }, {});
  }, [organizedVideos, searchValue]);

  const handleChange = (value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    const decodedToken = jwtDecode(token);
    dispatch(fetchCompletedItems(decodedToken?.id));

    dispatch(fetchVideos())
      .then((response) => {
        if (response.type === "videos/fetchVideos/fulfilled") {
          setTimeout(() => setLoading(false), 500); // Veriler yüklendiğinde 2 saniye bekleyin
        } else if (response.type === "videos/fetchVideos/rejected") {
          console.error("Error fetching videos:", response.error.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Unexpected error:", error);
        setLoading(false);
      });
  }, [dispatch]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Rehberlik Videoları" />
      <MDBox display="flex" justifyContent="center" mt={3}>
        <MDBox width="100%">
          <Card sx={{ padding: 3 }}>
            <MDBox
              width="100%"
              display="flex"
              justifyContent={isXsScreen ? "center" : "flex-end"}
            >
              <CustomTextField
                label="Ara"
                variant="outlined"
                onChange={(e) => handleChange(e.target.value)}
                sx={{ marginRight: !isXsScreen && 3 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon fontSize="medium" />
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            {Object.entries(filteredVideos).length === 0 ? (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={1}
                mt={1}
                mb={3}
              >
                <MDTypography variant="body2" color="text" fontWeight="regular">
                  İçerik Bulunamadı
                </MDTypography>
              </MDBox>
            ) : (
              <MDBox
                display="grid"
                gridTemplateColumns={{
                  xs: "1fr", // 100% width for extra small devices
                  sm: "repeat(2, 1fr)", // 2 columns for small devices
                  md: "repeat(3, 1fr)", // 3 columns for medium devices
                  lg: "repeat(4, 1fr)", // 4 columns for large devices
                }}
                columnGap={0} // Sets the horizontal gap between columns
                rowGap={1} // Sets the vertical gap between rows
                width="100%"
                sx={{
                  maxWidth: 1200,
                  margin: "0 auto", // Centers the grid container
                  padding: "32px 32px", // Optional: padding around the grid to avoid touching the edges
                }}
              >
                {Object.entries(filteredVideos).map(
                  ([guidanceMainTopic, data]) => (
                    <MDBox
                      sx={{
                        flexBasis: "calc(25% - 16px)",
                        flexGrow: 1,
                        maxWidth: "250px",
                        margin: "8px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        boxSizing: "border-box", // Ensure padding and borders are included in the width calculation
                      }}
                    >
                      <Card sx={{ width: "100%" }}>
                        <Link
                          to={`/guidance-videos/${guidanceMainTopic}`}
                          style={{ textDecoration: "none", flexGrow: 1 }}
                        >
                          <Card
                            sx={{
                              height: 140,
                              width: "100%",
                            }}
                          >
                            <AspectRatioBox>
                              <CardMedia
                                sx={{
                                  height: 140,
                                  margin: "auto",
                                  margin: 0,
                                  borderRadius: ".5rem",
                                  objectFit: "cover",
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  left: 0,
                                  width: "100%",
                                }}
                                component="img"
                                image={data.thumbnailUrl}
                                alt={`Thumbnail for ${guidanceMainTopic}`}
                              />
                            </AspectRatioBox>
                            <HoverOverlay>
                              <PlayCircleIcon fontSize="large" color="white" />
                            </HoverOverlay>
                          </Card>
                        </Link>
                      </Card>

                      <MDBox
                        m={1}
                        sx={{
                          textAlign: "left",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 0 auto",
                          width: "100%",
                        }}
                      >
                        <MDTypography gutterBottom variant="h6" mb={-1}>
                          {guidanceMainTopic}
                        </MDTypography>
                        <MDBox
                          mt={2}
                          width="100%"
                          textAlign="left"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <MDBox display="flex" alignItems="center">
                            <AccessTimeFilledIcon color="secondary" />
                            <MDTypography
                              variant="caption"
                              color="text"
                              ml={0.2}
                            >
                              {formatDuration(data?.totalDuration)}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDBox mt={1} width="100%" textAlign="left">
                          <MDProgress
                            value={data.completionPercentage}
                            color="teal"
                            variant="gradient"
                            label={false}
                          />
                          <MDTypography
                            gutterBottom
                            variant="caption"
                            fontWeight="bold"
                            mt={1}
                          >
                            Durum:
                          </MDTypography>
                          <MDTypography
                            gutterBottom
                            variant="caption"
                            fontWeight="regular"
                            mt={1}
                            ml={1}
                          >
                            {data.completionPercentage === 100
                              ? "Tamamlandı"
                              : "Başlanmadı"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  )
                )}
                {Object.keys(filteredVideos).length % 2 === 1 && (
                  <MDBox
                    sx={{
                      visibility: "hidden",
                      flexBasis: "calc(25% - 16px)",
                      flexGrow: 1,
                      maxWidth: "250px",
                      margin: "8px",
                    }}
                  />
                )}
              </MDBox>
            )}
          </Card>
        </MDBox>
      </MDBox>
      <AdviceCard
        title={"Yolculuğunuzda Rehberlik"}
        description={
          "Her öğrencinin öğrenme yolculuğu benzersizdir ve bazen bu yolculukta doğru rehberlik ve motivasyon en büyük farkı yaratır. Rehberlik videolarımız, sadece akademik başarınızı artırmakla kalmaz, aynı zamanda kişisel gelişiminize de katkıda bulunur. Bu videolar, sınav stratejilerinden zaman yönetimine, stresle başa çıkma tekniklerinden kariyer planlamasına kadar geniş bir yelpazede konuları kapsar. Her adımda yanınızda olan uzmanlarımızın tavsiyeleriyle hedeflerinize daha emin adımlarla ilerleyin."
        }
        icon={<ExploreIcon sx={{ fontSize: 30, color: "#495057" }} />}
      />
    </DashboardLayout>
  );
}
