import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info"; // Info iconu içe aktarın

import MDBadge from "components/MDBadge";

export const getColumns = (handleOpen, navigate) => {
  return [
    { Header: "İsim", accessor: "user", width: "45%", align: "left" },
    { Header: "Kullanıcı Adı", accessor: "username", align: "center" },
    { Header: "E-posta", accessor: "email", align: "center" },
    { Header: "Rol", accessor: "role", align: "center" },
    {
      Header: "A/P",
      accessor: "isActive",
      align: "center",
      Cell: ({ row }) => {
        return (
          <MDBadge
            size="lg"
            style={{ cursor: "pointer", userSelect: "none" }}
            badgeContent={row.original.isActive ? "Aktif" : "Pasif"}
            color={row.original.isActive ? "success" : "secondary"}
            onClick={() => {
              row.original.role === "Super user"
                ? handleOpen({
                    id: row.original.id,
                    message: "Super user durumu değiştirilemez",
                  })
                : handleOpen({ id: row.original.id });
            }}
          />
        );
      },
    },
    {
      Header: "Detay",
      accessor: "info",
      Cell: ({ row }) => {
        return (
          <IconButton
            onClick={() =>
              navigate(`/user-management/user-detail/${row.original.id}`)
            }
          >
            <InfoIcon fontSize="medium" sx={{ color: "#49a3f1" }} />
          </IconButton>
        );
      },
    },
  ];
};
