import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function AdviceCard({ icon, title, description, isXsScreen }) {
  return (
    <MDBox
      bgColor="transparent"
      borderRadius="xxl"
      border="1px solid #adb5bd"
      p={2}
      mt={3}
    >
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item>{icon}</Grid>
        <Grid item xs={10} mt={0.7} display="flex" flexDirection="column">
          <MDTypography
            variant={isXsScreen ? "button" : "h6"}
            fontWeight="medium"
            color="dark"
          >
            {title}
          </MDTypography>
          <MDTypography
            variant={isXsScreen ? "caption" : "button"}
            sx={{ lineHeight: isXsScreen && 1.75 }}
            color="text"
            mt={1}
          >
            {description}
          </MDTypography>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AdviceCard;
