import { createSlice } from "@reduxjs/toolkit";
import {
  deleteUser,
  fetchUsers,
  updateIsActive,
  resetPassword,
} from "./services";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    updateIsActive: {
      status: "idle",
      error: null,
    },
    fetchUsers: {
      status: "idle",
      error: null,
    },
    deleteUser: {
      status: "idle",
      error: null,
    },
    resetPassword: {
      status: "idle",
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get All Users
      .addCase(fetchUsers.pending, (state) => {
        state.fetchUsers.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.fetchUsers.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.fetchUsers.status = "failed";
        state.fetchUsers.error = action.payload;
      })

      // Update User Is Active
      .addCase(updateIsActive.pending, (state) => {
        state.updateIsActive.status = "loading";
      })
      .addCase(updateIsActive.fulfilled, (state, action) => {
        state.updateIsActive.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(updateIsActive.rejected, (state, action) => {
        state.updateIsActive.status = "failed";
        state.updateIsActive.error = action.payload;
      })

      // Delete User
      .addCase(deleteUser.pending, (state) => {
        state.deleteUser.status = "loading";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteUser.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUser.status = "failed";
        state.deleteUser.error = action.payload;
      })

      // Reset Password
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPassword.status = "succeeded";
        state.users = state.users.map((user) =>
          user.id === action.payload.user.id
            ? { ...user, password: action.payload.user.password }
            : user
        );
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPassword.status = "failed";
        state.resetPassword.error = action.payload;
      });
  },
});

export default usersSlice.reducer;
