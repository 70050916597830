// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom styles for the MDSnackbar
import MDSnackbarIconRoot from "components/MDSnackbar/MDSnackbarIconRoot";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import Cookies from "js-cookie";
import { useMediaQuery, useTheme } from "@mui/material";

function MDSnackbar({
  color,
  icon,
  title,
  dateTime,
  content,
  close,
  bgWhite,
  ...rest
}) {
  const [controller] = useMaterialUIController();

  const darkMode = Cookies.get("mode") === "dark";
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let titleColor;
  let dateTimeColor;
  let dividerColor;

  if (bgWhite) {
    titleColor = color;
    dateTimeColor = "dark";
    dividerColor = false;
  } else if (color === "light") {
    titleColor = darkMode ? "inherit" : "dark";
    dateTimeColor = darkMode ? "inherit" : "text";
    dividerColor = false;
  } else {
    titleColor = "white";
    dateTimeColor = "white";
    dividerColor = true;
  }

  return (
    <Snackbar
      TransitionComponent={Fade}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: isXsScreen ? "center" : "right",
      }}
      {...rest}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={close}
        >
          <Icon fontSize="small">close</Icon>
        </IconButton>
      }
    >
      <MDBox
        variant={bgWhite ? "contained" : "gradient"}
        bgColor={bgWhite ? "white" : color}
        minWidth={isXsScreen ? "100vw" : "24rem"}
        minHeight={isXsScreen ? "3rem" : "8rem"}
        maxWidth="100%"
        shadow="md"
        borderRadius={isXsScreen ? 0 : "xl"} // Küçük ekranda border-radius yok
        p={1}
        sx={{
          backgroundColor: ({ palette }) =>
            darkMode
              ? palette.background.card
              : palette[color] || palette.white.main,
          marginBottom: isXsScreen && -1, // Küçük ekranda margin yok
        }}
      >
        {!isXsScreen && (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="dark"
            p={1.5}
          >
            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <MDSnackbarIconRoot
                fontSize="small"
                ownerState={{ color, bgWhite }}
              >
                {icon}
              </MDSnackbarIconRoot>
              <MDTypography
                variant={"body2"}
                fontWeight="medium"
                color={titleColor}
                textGradient={bgWhite}
              >
                {title}
              </MDTypography>
            </MDBox>

            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <MDTypography variant="caption" color={dateTimeColor}>
                {dateTime}
              </MDTypography>
              <Icon
                sx={{
                  color: ({ palette: { dark, white } }) =>
                    (bgWhite && !darkMode) || color === "light"
                      ? dark.main
                      : white.main,
                  fontWeight: ({ typography: { fontWeightBold } }) =>
                    fontWeightBold,
                  cursor: "pointer",
                  marginLeft: 2,
                  transform: "translateY(-1px)",
                }}
                onClick={close}
              >
                close
              </Icon>
            </MDBox>
          </MDBox>
        )}

        {!isXsScreen && <Divider sx={{ margin: 0 }} light={dividerColor} />}

        <MDBox
          display="flex"
          justifyContent="flex-start"
          p={1.5}
          sx={{
            fontSize: ({ typography: { size } }) => size.sm,
            color: ({ palette: { white, text } }) => {
              let colorValue =
                bgWhite || color === "light" ? text.main : white.main;

              if (darkMode) {
                colorValue = color === "light" ? "inherit" : white.main;
              }

              return colorValue;
            },
          }}
        >
          {isXsScreen ? (
            <MDBox
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <MDBox display="flex" alignItems="center" lineHeight={0}>
                <MDSnackbarIconRoot
                  fontSize="small"
                  ownerState={{ color, bgWhite }}
                >
                  {icon}
                </MDSnackbarIconRoot>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color={titleColor}
                  textGradient={bgWhite}
                >
                  {title}: &nbsp;
                </MDTypography>
              </MDBox>
              <MDTypography
                color="white"
                variant={"caption"}
                mt={.2}
              >
                {content}
              </MDTypography>
            </MDBox>
          ) : (
            content
          )}
        </MDBox>
      </MDBox>
    </Snackbar>
  );
}

// Setting default values for the props of MDSnackbar
MDSnackbar.defaultProps = {
  bgWhite: false,
  color: "info",
};

// Typechecking props for MDSnackbar
MDSnackbar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  bgWhite: PropTypes.bool,
};

export default MDSnackbar;
