import React from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import pxToRem from "assets/theme/functions/pxToRem";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function OverlayCard({image}) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        position: "relative",
        overflow: "hidden",
        color: "#fff",
        display: "flex",
        height: "100%",
        justifyContent:"center",
        padding:0,
      }}
    >
      <MDBox
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0),
              rgba(gradients.dark.state, 0)
            )}, url(${image})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          height: "100%",
          minHeight: "16rem", // Minimum yükseklik
          maxHeight: "20rem", // Maksimum yüksekliği sınırladık
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: pxToRem(12),
        }}
      >
        {/* <MDBox sx={{ padding: 3 }}>
          <MDTypography variant="h5" color="white" mb={3}>
            Öğrenimde İlk Adım
          </MDTypography>
          <MDTypography variant="body2" color="white" fontWeight="bold" mb={3}>
            Potansiyelinizin kilidini açın ve başarıya giden yolculuğa çıkın.
          </MDTypography>
          <MDButton
            onClick={() => navigate("/lessons")}
            variant="outlined"
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <ArrowForwardIcon />
          </MDButton>
        </MDBox> */}
      </MDBox>
    </Card>
  );
}

export default OverlayCard;
