import { useState } from "react";

import { CardContent, Icon, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import ArrowForward from "@mui/icons-material/ArrowForward";

function PaymentInfoCard({ isSmallScreen }) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText("TR65 0004 6000 4488 8000 2469 33")
      .then(() => {
        openSB(
          "success",
          "check",
          "Başarılı",
          <MDTypography variant="caption" color="white">
            IBAN bilgisi panoya kopyalandı!
          </MDTypography>
        );
        setTimeout(() => closeSB(), 1500); // 1,5 saniye sonra mesajı temizle
      });
  };

  const variant = isSmallScreen ? "button" : "body2";

  return (
    <MDBox
      bgColor="transparent"
      borderRadius="xxl"
      border="1px solid #adb5bd"
      p={isSmallScreen ? 0 : 2}
      mt={1}
    >
      <CardContent
        sx={{
          textAlign: isSmallScreen ? "center" : "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          textAlign="center"
          mb={2}
        >
          <InfoIcon />
          <MDTypography variant="h6" gutterBottom mx={0.5} mt={1}>
            Ödeme Seçeneği
          </MDTypography>
        </MDBox>
        <MDTypography variant={variant} fontWeight="regular" gutterBottom>
          Değerli Müşterimiz,
        </MDTypography>
        <MDTypography variant={variant} gutterBottom>
          Yakın bir zamanda kredi kartı ile ödeme seçeneğini de hizmetinize
          sunacağız. Şu anda yalnızca IBAN aracılığıyla ödeme kabul etmekteyiz.
          Taksitli ödemeler, toplam ücretin %10 fazlası ile her ay düzenli
          olarak yapılmalıdır. Peşin ödemelerde ise herhangi bir ek ücret
          uygulanmamaktadır.
        </MDTypography>
        <MDTypography variant={variant} gutterBottom>
          Ödeme işlemlerinizde güvenliğiniz ön planda tutulmaktadır. Lütfen
          açıklama kısmında paket seçiminizi belirtmeyi unutmayınız. Herhangi
          bir sorunuz olması durumunda, bizimle iletişime geçmekten
          çekinmeyiniz. Müşteri memnuniyeti ekibimiz sizlere her zaman yardımcı
          olmaktan mutluluk duyacaktır.
        </MDTypography>
        {/* <MDBox display="flex" flexDirection="column" mt={3}>
          <MDTypography fontWeight="regular" variant="button">
            <span style={{ fontWeight: "bold" }}>Paket 1:</span> Ders Videoları
            + Testler + Canlı Dersler{" "}
            <span
              style={{
                textDecoration: "line-through",
                marginRight: "5px",
                fontWeight: "bold",
              }}
            >
              19.999₺
            </span>{" "}
            14.999₺
          </MDTypography>
          <MDTypography
            fontWeight="regular"
            variant="button"
            display="flex"
            alignItems="center"
            mt={2}
          >
            <span style={{ fontWeight: "bold" }}>Paket 2:</span> &nbsp; Ders
            Videoları + Testler <ArrowForward />
            <span
              style={{
                textDecoration: "line-through",
                marginRight: "5px",
                fontWeight: "bold",
              }}
            >
              5.999₺
            </span>{" "}
            yerine &nbsp;
            <span style={{ fontWeight: "bold" }}>3.999₺</span>
          </MDTypography>
        </MDBox> */}
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          mt={2}
        >
          <MDTypography variant={variant} fontWeight="bold">
            IBAN: TR65 0004 6000 4488 8000 2469 33
            <Tooltip title="Kopyala">
              <IconButton onClick={handleCopy}>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </MDTypography>
        </MDBox>
        <MDTypography variant={variant} fontWeight="bold" mt={2} mb={3}>
          ALICI İSMİ: Şaban Efekan Karaburun
        </MDTypography>
        <MDTypography variant={variant} gutterBottom mt={1}>
          Güvenli ve sorunsuz bir ödeme deneyimi için teşekkür ederiz.
        </MDTypography>
        <MDTypography variant={variant} fontWeight="regular" gutterBottom>
          Saygılarımızla,
        </MDTypography>
        <MDTypography variant={variant} fontWeight="regular" gutterBottom>
          Dijital Derslik
        </MDTypography>
      </CardContent>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
      />
    </MDBox>
  );
}
export default PaymentInfoCard;
