import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "examples/Tables/DataTable";

import { fetchQuizzes, deleteQuizzes } from "slices/Quizzes/services"; // Make sure deleteQuizzes is imported

import AlertDialog from "components/Custom/Dialog";
import MDTypography from "components/MDTypography";

import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function QuizzesDataTable({ openSB, closeSB }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState();
  const [selectedQuiz, setSelectedQuiz] = useState();

  const quizzes = useSelector((state) => state.quizzes.items);

  const handleOpen = (quiz) => {
    setOpen(true);
    setSelectedQuiz(quiz);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    handleClose();
    openSB(
      "info",
      "notifications",
      "Bildirim",
      <MDTypography variant="button" color="white">
        Test Siliniyor...
      </MDTypography>
    );
    try {
      const res = await dispatch(deleteQuizzes(selectedQuiz?.mainTopic));
      if (res.type === "quizzes/deleteQuizzes/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Test Başarıyla Kaldırıldı
          </MDTypography>
        );
      } else if (res.type === "quizzes/deleteQuizzes/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Test Silinirken Hata Oluştu
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  };

  useEffect(() => {
    dispatch(fetchQuizzes());
  }, [dispatch]);

  // Aggregate quizzes by main topic
  const aggregatedQuizzes = quizzes.reduce((acc, quiz) => {
    const { mainTopic } = quiz;
    if (!acc[mainTopic]) {
      acc[mainTopic] = {
        mainTopic,
        questions: [],
        difficulty: quiz.difficulty,
        learningOutcome: quiz.learningOutcome,
        description: quiz.description,
        uploadDate: quiz.uploadDate,
      };
    }
    acc[mainTopic].questions.push({
      correctAnswer: quiz.correctAnswer,
      learningOutcome: quiz.learningOutcome,
    });
    return acc;
  }, {});

  const rows = Object.values(aggregatedQuizzes).map((quiz) => ({
    mainTopic: quiz.mainTopic,
    difficulty: quiz.difficulty,
    learningOutcome: quiz.learningOutcome,
    description: quiz.description,
    count: quiz.questions.length,
    correctAnswers: quiz.questions.map((q) => q.correctAnswer).join(", "),
    uploadDate: (
      <span>
        {new Intl.DateTimeFormat("tr-TR", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(quiz.uploadDate))}
      </span>
    ),
    delete: (
      <IconButton
        onClick={() => handleOpen(quiz)}
        sx={{
          "&:hover": {
            transform: "scale(1.2)",
            transition: "transform 0.3s ease-in-out",
          },
        }}
      >
        <DeleteIcon fontSize="medium" color="error" />
      </IconButton>
    ),
  }));

  const columns = [
    { Header: "Konu", accessor: "mainTopic" },
    { Header: "Zorluk", accessor: "difficulty" },
    { Header: "Kazanım", accessor: "learningOutcome" },
    { Header: "Açıklama", accessor: "description" },
    { Header: "Soru Sayısı", accessor: "count" }, // Notice the plural - answers
    { Header: "Doğru Cevaplar", accessor: "correctAnswers" }, // Notice the plural - answers
    { Header: "Yükleme Tarihi", accessor: "uploadDate" }, // Notice the plural - answers
    { Header: "Sil", accessor: "delete", align: "center" },
  ];

  return (
    <>
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={true}
        showTotalEntries={true}
        noEndBorder
        canSearch
      />
      <AlertDialog
        width="sm"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title="Testi Sil"
      >
        <MDTypography color="text" variant="body2" mx={1}>
          <span style={{ fontWeight: "bold" }}>{selectedQuiz?.mainTopic}</span>{" "}
          konulu test kalıcı olarak kaldırılacak. Emin misiniz ?
        </MDTypography>
      </AlertDialog>
    </>
  );
}
