import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// RTL plugins
import { CacheProvider } from "@emotion/react";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import ProtectedRoute from "examples/ProtectedRoute";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import globals from "assets/theme/base/globals";
import container from "assets/theme/components/container";

// Images
import logo from "assets/images/logos/brand-white.png";

// Authentication and User Management
import { AuthContext } from "context";
import { useSelector } from "react-redux";
import { setupAxiosInterceptors } from "./services/interceptor";

// Authentication pages
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";
import Login from "auth/login";
import Register from "auth/register";

// Additional pages
import UserProfile from "layouts/pages/user-profile";
import UserManagement from "layouts/pages/user-management";
import WelcomePage from "layouts/pages/welcome";
import AboutUs from "layouts/pages/about-us";
import ContactUs from "layouts/pages/contact-us";
import Help from "layouts/pages/help";
import Blog from "layouts/pages/blog";

// Helmet for setting up the metadata
import { Helmet } from "react-helmet";

// Vercel
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import Cookies from "js-cookie";
import { login } from "slices/Auth/authSlice";
import { useDispatch } from "react-redux";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMediaQuery, useTheme } from "@mui/material";
import { findUserName } from "helpers";

export default function App() {
  const muiTheme = useTheme();
  const isXsScreen = useMediaQuery(muiTheme.breakpoints.down("lg"));

  const authContext = useContext(AuthContext);

  const dispatchAction = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [hasRun, setHasRun] = useState(false);
  const { pathname } = useLocation();

  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
    setTimeout(() => {
      closeSB();
    }, 1500);
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    // dispatch(logout());
    Cookies.remove("token");
    navigate("/auth/login");
  });

  useEffect(() => {
    const token = Cookies.get("token");
    const isAuthenticatedBefore = Cookies.get("isAuthenticatedBefore");
    const validPaths =
      ["/", "/about-us", "/contact-us", "/help", "/blog"].includes(pathname) ||
      pathname.startsWith("/auth");

    if (token && isAuthenticatedBefore && !isAuthenticated && validPaths) {
      openSB(
        "success",
        "check",
        "Başarılı",
        <MDTypography variant={isXsScreen ? "caption" : "button"} color="white">
          Giriş yapılıyor, hoş geldiniz{" "}
          <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
            {findUserName()}
          </span>
        </MDTypography>
      );
      setTimeout(() => {
        navigate("/dashboard");
      }, 1500);
    }
  }, [hasRun, navigate, dispatch]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Determine the background color based on the URL
  const isQuizPage = /^\/quiz(\/|$)/.test(pathname);
  const currentTheme = createTheme({
    ...theme,
    components: {
      ...theme.components,
      MuiCssBaseline: {
        styleOverrides: {
          ...globals,
          ...container,
          body: {
            backgroundColor: isQuizPage ? "#fff" : colors.background.default, // URL'ye göre arka plan rengini ayarlayın
            margin: 0,
            padding: 0,
            boxSizing: "border-box",
          },
        },
      },
    },
  });

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  return (
    <>
      <SpeedInsights />
      <Analytics />

      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        {layout === "dashboard" &&
          !pathname.startsWith("/lessons/") &&
          !pathname.startsWith("/guidance-videos/") &&
          !pathname.startsWith("/auth") &&
          !pathname.match(/^\/quiz(\/|$)/) &&
          !["/", "/about-us", "/contact-us", "/help", "/blog"].includes(
            pathname
          ) && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? logo
                    : logo
                }
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {/* {configsButton} */}
            </>
          )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/help" element={<Help />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="user-profile"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <UserProfile />
              </ProtectedRoute>
            }
            key="user-profile"
          />
          <Route
            exact
            path="user-management"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <UserManagement />
              </ProtectedRoute>
            }
            key="user-management"
          />
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
        <MDSnackbar
          {...snackbarContent}
          open={openSnackbar}
          onClose={closeSB}
          close={closeSB}
          bgGreen
        />
      </ThemeProvider>
    </>
  );
}
