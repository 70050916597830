import User from "components/Custom/User";
import profilePicture from "assets/images/other/default-pp.png";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Import avatars
import boy1 from "assets/images/avatars/boys/004-boy-1.svg";
import boy2 from "assets/images/avatars/boys/007-boy-2.svg";
import boy3 from "assets/images/avatars/boys/008-boy-3.svg";
import boy4 from "assets/images/avatars/boys/009-boy-4.svg";
import boy5 from "assets/images/avatars/boys/011-boy-5.svg";
import boy6 from "assets/images/avatars/boys/015-boy-6.svg";
import boy7 from "assets/images/avatars/boys/016-boy-7.svg";
import boy8 from "assets/images/avatars/boys/021-boy-8.svg";
import boy9 from "assets/images/avatars/boys/024-boy-9.svg";
import boy10 from "assets/images/avatars/boys/026-boy-10.svg";

import girl1 from "assets/images/avatars/girls/003-girl-1.svg";
import girl2 from "assets/images/avatars/girls/005-girl-2.svg";
import girl3 from "assets/images/avatars/girls/006-girl-3.svg";
import girl4 from "assets/images/avatars/girls/010-girl-4.svg";
import girl5 from "assets/images/avatars/girls/012-girl-5.svg";
import girl6 from "assets/images/avatars/girls/013-girl-6.svg";
import girl7 from "assets/images/avatars/girls/014-girl-7.svg";
import girl8 from "assets/images/avatars/girls/017-girl-8.svg";
import girl9 from "assets/images/avatars/girls/018-girl-9.svg";
import girl10 from "assets/images/avatars/girls/019-girl-10.svg";
import { useEffect, useState } from "react";

const avatars = {
  boys: [boy1, boy2, boy3, boy4, boy5, boy6, boy7, boy8, boy9, boy10],
  girls: [
    girl1,
    girl2,
    girl3,
    girl4,
    girl5,
    girl6,
    girl7,
    girl8,
    girl9,
    girl10,
  ],
};

export const getRandomAvatar = (gender) => {
  if (gender === "male") {
    const randomIndex = Math.floor(Math.random() * avatars.boys.length);
    return avatars.boys[randomIndex];
  } else if (gender === "female") {
    const randomIndex = Math.floor(Math.random() * avatars.girls.length);
    return avatars.girls[randomIndex];
  }
  // Fallback to default profile picture if gender is unknown
  return profilePicture;
};

/**
 * Converts a date string into a readable date and time format.
 * @param {String} dateString - The date string to be formatted.
 * @returns {Object} An object containing formatted date and time strings.
 * @example
 * // returns { readableDate: "May 13, 2024", readableTime: "08:00 PM" }
 * formatDateTime("2024-05-13T20:00:00Z");
 */
export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const optionsDate = { year: "numeric", month: "long", day: "numeric" };
  const optionsTime = { hour: "2-digit", minute: "2-digit" };

  const readableDate = date.toLocaleDateString("tr-TR", optionsDate);
  const readableTime = date.toLocaleTimeString("tr-TR", optionsTime);

  return { readableDate, readableTime };
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("tr-TR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

/**
 * A regular expression pattern to validate email addresses.
 * This pattern checks if an email:
 * - Starts with characters that are not whitespaces or '@'
 * - Follows by a '@' symbol
 * - Continues with characters that are not whitespaces or '@'
 * - Ends with a dot followed by at least two characters that are not whitespaces or '@'
 * Examples of valid emails: 'example@example.com', 'user.name@domain.co'
 * Examples of invalid emails: 'example@.com', 'user@name@domain.com'
 */
export const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

/**
 * A regular expression pattern to validate Turkish phone numbers.
 * This pattern ensures the phone number:
 * - Starts with '05'
 * - Follows by two more digits (total of four initial digits)
 * - Continues with optional spaces and then three digits
 * - Concludes with optional spaces followed by either two groups of two digits or a single group of four digits
 * Examples of valid phone numbers: '0555 123 45 67', '05551234567'
 * Examples of invalid phone numbers: '05551 234567', '1234 567 89 00'
 */
export const phoneFormat =
  /^05\d{2}(\s?\d{3}\s?\d{2}\s?\d{2}|\s?\d{3}\s?\d{4})$/;

export const roleMap = {
  superuser: "Super user",
  student: "Öğrenci",
  admin: "Yönetici",
  teacher: "Öğretmen",
};

export const mapUserDataToTable = (users) => {
  const [avatars, setAvatars] = useState({});

  useEffect(() => {
    // Only set avatars for users that don't already have them
    const newAvatars = { ...avatars };
    let hasChanges = false; // Track if any changes were made

    users.forEach((user) => {
      if (!avatars[user.id]) {
        const avatar = getRandomAvatar(user.gender);
        newAvatars[user.id] = avatar;
        hasChanges = true;
      }
    });

    // Only update state if there are changes to avoid unnecessary re-renders
    if (hasChanges) {
      setAvatars(newAvatars);
    }
  }, [users, avatars]);

  return users.map((user) => {
    const defaultAvatar = user?.profile_image || profilePicture;
    const userAvatar = avatars[user.id] || defaultAvatar;

    return {
      id: user.id,
      user: (
        <User
          image={userAvatar} // Avatar or default image
          name={user.name}
        />
      ),
      username: user.username || "",
      email: user.email || "",
      isActive: user.isActive,
      role: roleMap[user.role] || "Unknown Role",
    };
  });
};

export const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = "";
  if (hours > 0) {
    result += `${Math.floor(hours)} saat `;
  }
  if (minutes > 0) {
    result += `${Math.floor(minutes)} dakika `;
  }
  if (remainingSeconds > 0 || result === "") {
    result += `${Math.floor(remainingSeconds)} saniye`;
  }
  return result.trim();
};

export const excelDateToJSDate = (serial) => {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  return new Date(utc_value * 1000);
};

export const findUserName = () => {
  const token = Cookies.get("token");
  const decodedToken = jwtDecode(token);
  return decodedToken?.username;
};

export const findUserId = () => {
  const token = Cookies.get("token");
  const decodedToken = jwtDecode(token);
  return decodedToken?.id;
};

export const findUserRole = () => {
  const token = Cookies.get("token");
  const decodedToken = jwtDecode(token);
  return decodedToken?.role;
};

export const findUserStatus = () => {
  const token = Cookies.get("token");
  const decodedToken = jwtDecode(token);
  return decodedToken?.isActive;
};

export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Check if the number starts with a country code (e.g., 90 for Turkey)
  const startsWithCountryCode =
    cleaned.length === 12 && cleaned.startsWith("90");
  const startsWithZero = cleaned.length === 11 && cleaned.startsWith("0");

  // If it starts with the country code, remove it
  let numberToFormat = startsWithCountryCode ? cleaned.slice(2) : cleaned;

  // If it starts with a zero, remove it
  if (startsWithZero) {
    numberToFormat = cleaned.slice(1);
  }

  // If the number has 10 digits, format it accordingly
  if (numberToFormat.length === 10) {
    const part1 = numberToFormat.slice(0, 3); // 507
    const part2 = numberToFormat.slice(3, 6); // 032
    const part3 = numberToFormat.slice(6, 8); // 94
    const part4 = numberToFormat.slice(8, 10); // 87

    return `(${part1}) ${part2} ${part3} ${part4}`;
  }

  // If the number is not in the expected format, return it as is
  return phoneNumber;
};
