// React essentials
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Cookies management
import Cookies from "js-cookie";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Services
import authService from "services/auth-service";

// Redux slices
import { logout } from "slices/Auth/authSlice";

const TokenCountdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [secondsLeft, setSecondsLeft] = useState();

  const handleLogOut = () => {
    authService.logout();
    dispatch(logout());
    Cookies.remove("tokenExpiration");
    Cookies.remove("isAuthenticatedBefore");
    Cookies.remove("varaAnimationPlayed");
    navigate("/auth/login");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const expirationTime = Cookies.get("tokenExpiration");
      if (!expirationTime) {
        clearInterval(intervalId);
        return;
      }

      const currentTime = new Date();
      const expirationDate = new Date(expirationTime);
      const remainingTime = expirationDate - currentTime;

      if (remainingTime <= 1000) {
        clearInterval(intervalId);
        setSecondsLeft(0);
        handleLogOut();
      } else {
        setSecondsLeft(Math.floor(remainingTime / 1000));
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <MDBox>
      {secondsLeft && (
        <MDTypography color="text" variant="body2">
          Oturumun sonlanmasına{" "}
          {secondsLeft > 0 ? (
            <MDTypography variant="body2" fontWeight="bold" my={0.5}>
              {Math.floor(secondsLeft / 3600)} saat &nbsp;
              {Math.floor((secondsLeft % 3600) / 60)} dakika {secondsLeft % 60}{" "}
              saniye{" "}
            </MDTypography>
          ) : (
            ""
          )}
          kaldı
        </MDTypography>
      )}
    </MDBox>
  );
};

export default TokenCountdown;
