// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchAnnouncements } from "slices/AdminPanel/services";

function AnnouncementCard() {
  const dispatch = useDispatch();
  const announcements = useSelector((state) => state.adminPanel.items);

  const lastUpdated = useMemo(() => {
    if (!announcements.length) return null;

    const latestAnnouncement = announcements.reduce((latest, current) => {
      const currentDate = new Date(current.createdAt);
      const latestDate = new Date(latest.createdAt);
      return currentDate > latestDate ? current : latest;
    });

    return latestAnnouncement.createdAt;
  }, [announcements]);

  // Duyuruları getirme
  useEffect(() => {
    dispatch(fetchAnnouncements());
  }, [dispatch]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Duyurular
        </MDTypography>
        <MDBox display="flex" alignItems="center">
          <MDTypography
            variant="button"
            color="text"
            lineHeight={1}
            sx={{ mt: 0.3, mr: 0.5 }}
          >
            <Icon>schedule</Icon>
          </MDTypography>
          <MDTypography variant="overline" color="text" fontWeight="light">
            Son güncellenme:{" "}
            {lastUpdated && new Date(lastUpdated).toLocaleString()}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {announcements.map((announcement) => {
          return (
            <TimelineItem
              color={announcement.color}
              icon={"campaign"}
              title={announcement.title}
              description={announcement.description}
            />
          );
        })}
      </MDBox>
    </Card>
  );
}

export default AnnouncementCard;
