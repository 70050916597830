import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const uploadQuizzes = createAsyncThunk(
  "quizzes/upload",
  async (formData, { rejectWithValue }) => {
    try {
      await axios.post("/quizzes", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const response = await axios.get("/quizzes");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuizzes = createAsyncThunk(
  "quizzes/fetchQuizzes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/quizzes");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchQuizGroups = createAsyncThunk(
  "quizzes/fetchQuizGroups",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/quizzes/groups");
      return response.data.quizGroups
      ;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteQuizzes = createAsyncThunk(
  "quizzes/deleteQuizzes",
  async (mainTopic, { rejectWithValue }) => {
    try {
      await axios.delete(`/quizzes/${mainTopic}`);
      return mainTopic;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
