import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// @mui material components
import { Card } from "@mui/material";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AlertDialog from "components/Custom/Dialog";

// Redux-actions
import { fetchUsers } from "slices/Users/services";
import { updateIsActive } from "slices/Users/services";

// Helpers
import { getColumns } from "./columns";
import { mapUserDataToTable } from "helpers/index";

export default function ActiveUsersTable({ openSB, closeSB }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector((state) => state.users.users) || [];
  const activeUsers = users.filter((user) => user.isActive);
  const activeTableRows = mapUserDataToTable(activeUsers);

  const [open, setOpen] = useState();
  const [selectedUserId, setSelectedUserId] = useState(null);

  const selectedUser = users?.find((user) => user.id === selectedUserId);

  const handleOpen = ({ id, message }) => {
    if (!message) {
      setOpen(true);
      setSelectedUserId(id);
    }

    if (message) {
      openSB(
        "error",
        "warning",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {message}
        </MDTypography>
      );
    }
  };

  const columns = useMemo(() => getColumns(handleOpen, navigate), [navigate]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleClose();
    handleAccessibility();
  };

  const handleAccessibility = async () => {
    openSB(
      "info",
      "notifications",
      "Bildirim",
      <MDTypography variant="button" color="white">
        Kullanıcı durumu düzenleniyor...
      </MDTypography>
    );
    try {
      const res = await dispatch(
        updateIsActive({
          id: selectedUserId,
          isActive: false,
        })
      );
      if (res.type === "users/updateIsActive/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Kullanıcı durumu başarıyla güncellendi
          </MDTypography>
        );
      } else if (res.type === "users/updateIsActive/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Kullanıcı durumunu güncelleme sırasında hata meydana geldi
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <>
      <Card sx={{ width: "100%" }}>
        <MDBox width="100%" display="flex" justifyContent="flex-end">
          <MDTypography color="text" variant="h6" mx={3} my={1.5}>
            Aktif Kullanıcılar
          </MDTypography>
        </MDBox>
        <DataTable
          table={{ columns, rows: activeTableRows }}
          isSorted={false}
          entriesPerPage={true}
          showTotalEntries={true}
          noEndBorder
          canSearch
        />
      </Card>
      <AlertDialog
        width="sm"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title={"Kullanıcıyı Pasife Al"}
      >
        <MDTypography color="text" variant="body2" mx={1}>
          <span style={{ fontWeight: "bold" }}>{selectedUser?.name}</span> adlı
          kullanıcıyı pasif hale getirmek istediğinizden emin misiniz?
        </MDTypography>
      </AlertDialog>
    </>
  );
}
