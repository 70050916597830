// React
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI components
import {
  Card,
  IconButton,
  Link,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

// Redux-store
import { fetchVideos, deleteVideo } from "slices/Videos/services";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import AlertDialog from "components/Custom/Dialog";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";
import MDBadge from "components/MDBadge";
import { updateAccessibility } from "slices/Videos/services";
import MDProgress from "components/MDProgress";

const VideosDataTable = ({ openSB, closeSB, videoType }) => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const videos = useSelector((state) => state.videos.items);
  const status = useSelector((state) => state.videos.fetchVideos.status);

  const filteredVideos = videos.filter(
    (video) => video.videoType === videoType
  );

  const [open, setOpen] = useState();
  const [dialogType, setDialogType] = useState("");
  const [selectedVideoId, setSelectedVideoId] = useState();
  const [selectedVimeoId, setSelectedVimeoId] = useState();

  const selectedVideo = filteredVideos?.find(
    (video) => video._id === selectedVideoId
  );

  const accessibleVideos = filteredVideos.filter((video) => video.isAccessible);
  const inaccessibleVideos = filteredVideos.filter(
    (video) => !video.isAccessible
  );

  const handleOpen = (id, vimeoId, type) => {
    setDialogType(type);
    setSelectedVideoId(id);
    setSelectedVimeoId(vimeoId);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleClose();
    if (dialogType === "delete") {
      handleDelete(selectedVideoId, selectedVimeoId);
    } else {
      handleAccessibility();
    }
  };

  const columns = [
    {
      Header: "Kapak Fotoğrafı",
      accessor: "thumbnail",
      align: "center",
      Cell: ({ row }) => {
        const thumbnailUrl = row?.original?.thumbnailUrl;
        const progress = row?.original?.progress;
        return progress === 100 ? (
          <img
            src={thumbnailUrl}
            alt="Video Kapak"
            style={{
              width: isXsScreen ? "10rem" : "16rem",
              height: isXsScreen ? "6rem" : "10rem",
              borderRadius: ".5rem",
            }} // Boyut ve stil ayarları
          />
        ) : (
          <span>Belirleniyor...</span>
        );
      },
    },
    {
      Header: "Dosya Adı",
      accessor: "filename",
      align: "left",
      Cell: ({ row }) => {
        const filename = row?.original?.filename;
        const displayText =
          filename?.length > 35 ? `${filename.substring(0, 32)}...` : filename;
        return (
          <Tooltip filename={filename} placement="bottom" arrow>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      Header: "Ana Konu",
      accessor: "mainTopic",
      align: "left",

      Cell: ({ row }) => {
        const mainTopic = row?.original?.mainTopic || row?.original?.guidanceMainTopic;
        const displayText =
          mainTopic?.length > 30
            ? `${mainTopic.substring(0, 27)}...`
            : mainTopic;
        return (
          <Tooltip title={mainTopic} placement="bottom" arrow>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      Header: "Alt Konu",
      accessor: "subTopic",
      align: "left",
      Cell: ({ row }) => {
        const subTopic = row?.original?.subTopic;
        const displayText =
          subTopic?.length > 30 ? `${subTopic.substring(0, 27)}...` : subTopic;
        return (
          <Tooltip title={subTopic} placement="bottom" arrow>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      Header: "Video Süresi",
      accessor: "duration",
      align: "left",
      Cell: ({ value, row }) => {
        const progress = row?.original?.progress;
        if (progress !== 100) {
          return <span>Hesaplanıyor...</span>;
        }

        // Süreyi saat, dakika ve saniyeye dönüştür
        const hours = Math.floor(value / 3600); // Toplam saniyeyi 3600'e bölerek saat değerini bul
        const minutes = Math.floor((value % 3600) / 60); // Kalan saniyeyi 60'a bölerek dakika değerini bul
        const seconds = Math.floor(value % 60); // Kalanı saniye olarak al

        // Formatlı string oluştur
        let formattedTime = "";
        if (hours > 0) {
          formattedTime += `${hours} sa `;
        }
        if (minutes > 0) {
          formattedTime += `${minutes} dk `;
        }
        formattedTime += `${seconds} sn`;

        return formattedTime;
      },
    },
    {
      Header: "Erişilebilir mi?",
      accessor: "isAccessible",
      align: "center",
      Cell: ({ row }) => (
        <Tooltip
          title={
            row.original.progress !== 100
              ? "Video hala yükleniyor, erişilebilirlik durumu değiştirilemez."
              : ""
          }
          disableHoverListener={row.original.progress === 100}
        >
          <span>
            <MDBadge
              size="lg"
              style={{
                cursor: row.original.progress === 100 ? "pointer" : "default",
              }}
              onClick={() =>
                row.original.progress === 100 &&
                handleOpen(row.original._id, row.original.vimeoId, "access")
              }
              badgeContent={
                row.original.isAccessible ? "Erişilebilir" : "Erişilemez"
              }
              color={row.original.isAccessible ? "success" : "error"}
            />
          </span>
        </Tooltip>
      ),
    },
    {
      Header: "Yükleme Tarihi",
      accessor: "uploadDate",
      align: "left",
      Cell: ({ row }) => (
        <span>
          {new Intl.DateTimeFormat("tr-TR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(row?.original?.uploadDate))}
        </span>
      ),
    },
    {
      Header: "Yüklenme Durumu",
      accessor: "progress",
      align: "left",
      Cell: ({ row }) => (
        <MDBox width="8rem" textAlign="left" mb={2.5}>
          <MDProgress
            value={row.original.progress?.toFixed(0)}
            color="dark"
            variant="gradient"
            label={true}
          />
        </MDBox>
      ),
    },
    {
      Header: "Vimeo Linki",
      accessor: "url",
      align: "left",
      Cell: ({ row }) => (
        <Tooltip
          title={
            row.original.status === "uploading"
              ? "Video hala yükleniyor, link yüklendikten sonra aktif olacaktır"
              : ""
          }
          disableHoverListener={row.original.status !== "uploading"}
        >
          <span>
            <Link href={row.original.link} target="_blank">
              Vimeo Url
            </Link>
          </span>
        </Tooltip>
      ),
    },
    {
      Header: "Sil",
      accessor: "delete",
      align: "center",
      Cell: ({ row }) => (
        <Tooltip
          title={
            row.original.status === "uploading"
              ? "Video hala yükleniyor, silinemez"
              : ""
          }
          disableHoverListener={row.original.status !== "uploading"}
        >
          <span>
            <IconButton
              disabled={row.original.status === "uploading"}
              onClick={() =>
                handleOpen(row.original._id, row.original.vimeoId, "delete")
              }
              sx={{
                "&:hover": {
                  transform: "scale(1.2)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            >
              <DeleteIcon fontSize="medium" color="error" />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ];

  if (videoType === "guidance") {
    columns.splice(3, 1);
  }

  const handleDelete = async (videoId, vimeoId) => {
    openSB(
      "info",
      "notifications",
      "Bildirim",
      <MDTypography variant="button" color="white">
        Video Siliniyor...
      </MDTypography>
    );
    try {
      const res = await dispatch(deleteVideo({ videoId, vimeoId }));
      if (res.type === "videos/deleteVideo/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Video Başarıyla Kaldırıldı
          </MDTypography>
        );
      } else if (res.type === "videos/deleteVideo/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Video Silinirken Hata Oluştu
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  };

  const handleAccessibility = async () => {
    openSB(
      "info",
      "notifications",
      "Bildirim",
      <MDTypography variant="button" color="white">
        Video Düzenleniyor...
      </MDTypography>
    );
    try {
      const res = await dispatch(
        updateAccessibility({
          id: selectedVideoId,
          isAccessible: !selectedVideo?.isAccessible,
        })
      );
      if (res.type === "videos/updateAccessibility/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Video Başarıyla Düzenlendi
          </MDTypography>
        );
      } else if (res.type === "videos/updateAccessibility/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Video Düzenlenirken Hata Oluştu
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchVideos());
    }
  }, [status, dispatch]);

  return (
    <>
      <MDBox mb={4}>
        <Card sx={{ width: "100%" }}>
          <MDBox width="100%" display="flex" justifyContent="flex-end">
            <MDTypography color="text" variant="h6" mx={3} my={1.5}>
              Erişilebilir Videolar
            </MDTypography>
          </MDBox>
          <DataTable
            table={{ columns, rows: accessibleVideos }}
            isSorted={false}
            entriesPerPage={true}
            showTotalEntries={true}
            noEndBorder
            canSearch
          />
        </Card>
      </MDBox>
      <Card sx={{ width: "100%" }}>
        <MDBox width="100%" display="flex" justifyContent="flex-end">
          <MDTypography color="text" variant="h6" mx={3} my={1.5}>
            Erişilemez Videolar
          </MDTypography>
        </MDBox>
        <DataTable
          table={{ columns, rows: inaccessibleVideos }}
          isSorted={false}
          entriesPerPage={true}
          showTotalEntries={true}
          noEndBorder
          canSearch
        />
      </Card>

      <AlertDialog
        width="sm"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title={
          dialogType === "delete"
            ? "Video Sil"
            : "Video Erişilebilirlik Düzenleme"
        }
      >
        {dialogType === "delete" ? (
          <MDTypography color="text" variant="body2" mx={1}>
            <span style={{ fontWeight: "bold" }}>{selectedVideo?.title}</span>{" "}
            adlı video kalıcı olarak kaldırılacak. Emin misiniz ?
          </MDTypography>
        ) : (
          <MDTypography color="text" variant="body2" mx={1}>
            <span style={{ fontWeight: "bold" }}>{selectedVideo?.title}</span>{" "}
            Bu videoyu{" "}
            {selectedVideo?.isAccessible ? "erişilemez" : "erişilebilir"} yapmak
            istediğinizden emin misiniz?
          </MDTypography>
        )}
      </AlertDialog>
    </>
  );
};

export default VideosDataTable;
