import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid"; // Grid bileşeni eklendi
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

function ProfileInfoCard({ title, info, shadow, handleOpen }) {
  const labels = [];
  const values = [];
  const location = useLocation();

  Object.keys(info).forEach((key) => {
    const newKey = key.replace(/([A-Z])/g, " $1").trim(); // Add space before capital letters
    labels.push(newKey.charAt(0).toUpperCase() + newKey.slice(1)); // Capitalize first letter
    values.push(info[key]);
  });

  const renderItem = (label, value, isActive) => (
    <MDBox display="flex" alignItems="center" py={1.2}>
      <MDTypography variant="button" fontWeight="bold" minWidth="60px">
        {label}:
      </MDTypography>
      {isActive ? (
        <MDBadge
          badgeContent={value}
          color={value === "Aktif" ? "success" : "secondary"}
          style={{
            cursor:
              location.pathname !== "/user-profile" ? "pointer" : "default",
          }}
          onClick={() => {
            if (location.pathname !== "/user-profile") {
              handleOpen("update");
            }
          }}
        />
      ) : (
        <MDTypography variant="caption" fontWeight="regular" ml={2}>
          {value}
        </MDTypography>
      )}
    </MDBox>
  );

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </MDTypography>
      </MDBox>
      <Divider />
      <MDBox p={3}>
        <Grid
          container
          spacing={{ xs: 0, md: 6 }}
          pl={{ xs: "15%", sm: "20%", md: 0 }}
        >
          <Grid item xs={12} md={6}>
            {labels
              .slice(0, Math.ceil(labels.length / 2))
              .map((label, index) => renderItem(label, values[index]))}
          </Grid>
          <Grid item xs={12} md={6}>
            {labels
              .slice(Math.ceil(labels.length / 2))
              .map((label, index) =>
                renderItem(
                  label,
                  values[Math.ceil(labels.length / 2) + index],
                  label.toLowerCase() === "aktif / pasif"
                )
              )}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

ProfileInfoCard.defaultProps = {
  shadow: true,
};

ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }),
  shadow: PropTypes.bool,
  handleOpen: PropTypes.func,
};

export default ProfileInfoCard;
